/* eslint max-lines: ["error", 245] */
/* test file with a lot of mocking */
import { fireEvent, render, screen } from '@testing-library/react';
import { getSelectedModel } from 'redux/Models';

import { FinishesConfigurator } from 'pages/Models/FinishesConfigurator';
import React from 'react';
import { selectDropdown } from 'testing/testSelectHelpers';
import { useConfiguratorContext } from 'context/ConfiguratorContext';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

const getSelectedModelMock = (getSelectedModel as unknown) as jest.Mock;

jest.mock('redux/useSelector', () => ({
  useSelector: jest.fn((fn) => fn()),
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => mockCMSContent[id],
}));

jest.mock('redux/Models', () => ({
  getSelectedModel: jest.fn().mockImplementation(() => ({
    id: '123',
    sketchfabModelId: 'abc',
    name: 'House 1',
    features: {
      Door: {
        value: 'Door',
        variantValue: 'door_handle',
      },
    },
    materials: [
      {
        value: 'Alu ',
        label: 'Alu',
        associatedFeatures: [
          {
            value: 'window',
          },
        ],
      },
      {
        value: 'Aluminium_Doors',
        label: 'Aluminium Doors',
        associatedFeatures: [
          {
            value: 'window',
          },
          {
            value: 'door',
          },
        ],
        availableTextures: [
          {
            textureId: 'texture-123',
            channel: 'AlbedoPBR',
          },
          {
            textureId: 'texture-456',
            channel: 'AlbedoPBR',
          },
        ],
      },
      {
        value: 'Concrete',
        label: 'Concrete',
        availableTextures: [],
        associatedFeatures: [
          {
            value: 'window',
          },
        ],
        associatedVariants: [{ value: 'driveway' }],
      },
    ],
  })),
  setSelectedModelId: jest.fn(),
}));

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: () => jest.fn(),
}));

jest.mock('redux/Textures', () => ({
  getAllTextures: () => [
    {
      id: 'texture-123',
      label: 'Texture 1',
      url: 'texture.jpeg',
      canChangeColor: true,
      urlThumbnail: 'texturethumbnail.jpeg',
    },
    {
      id: 'texture-456',
      label: 'Texture 2',
      url: 'textur2.jpeg',
      canChangeColor: true,
      urlThumbnail: 'texturethumbnail2.jpeg',
    },
  ],
}));

jest.mock('redux/Colors', () => ({
  getAllColors: () => [],
}));

jest.mock('redux/CustomerModelConfig');

jest.mock('context/ConfiguratorContext', () => ({
  useConfiguratorContext: jest.fn(() => ({
    selectedVariants: [],
    setSelectedMaterialName: jest.fn(),
    selectedTextures: {},
    selectedFeatureName: 'window',
    setSelectedTextures: jest.fn(),
  })),
}));

const commonProps = {
  sketchfabApiRef: { current: null },
};

describe('Right Side Bar Configurator Component', () => {
  it('should not have a materials dropdown if a feature is selected with only one material', () => {
    getSelectedModelMock.mockImplementationOnce(() => ({
      id: '123',
      sketchfabModelId: 'abc',
      name: 'House 1',
      features: {
        Door: {
          value: 'Door',
          variantValue: 'door_handle',
        },
      },
      materials: [
        {
          value: 'Alu ',
          label: 'Alu',
          associatedFeatures: [
            {
              value: 'window',
            },
          ],
        },
      ],
    }));

    render(<FinishesConfigurator {...commonProps} />);
    expect(screen.queryByText('Select Material')).toBeFalsy();
    expect(screen.queryByText('Please select a feature to see the finishes')).toBeFalsy();
  });
  it('should display a placeholder text if there is no feature selected', () => {
    // @ts-expect-error: mockImplementationOnce
    useConfiguratorContext.mockImplementationOnce(() => ({
      selectedFeatureName: null,
      selectedVariants: [],
      setSelectedMaterialName: jest.fn(),
      setSelectedTextures: jest.fn(),
    }));
    render(<FinishesConfigurator {...commonProps} />);
    expect(screen.queryByText('Select Material')).toBeFalsy();
    expect(screen.queryByText('Please select a feature to see the finishes')).toBeTruthy();
  });
  it('should display a placeholder text if there is a feature selected but no available materials', () => {
    // @ts-expect-error: mockImplementationOnce
    useConfiguratorContext.mockImplementationOnce(() => ({
      selectedFeatureName: 'blanket',
      selectedVariants: [],
      setSelectedMaterialName: jest.fn(),
      setSelectedTextures: jest.fn(),
    }));
    render(<FinishesConfigurator {...commonProps} />);
    expect(screen.queryByText('Select Material')).toBeFalsy();
    expect(screen.queryByText('Currently there are no finishes for this feature')).toBeTruthy();
  });
  it('should have a materials dropdown if a feature is selected', () => {
    render(<FinishesConfigurator {...commonProps} />);
    expect(screen.queryByText('Select Material')).toBeTruthy();
    expect(screen.queryByText('Please select a feature to see the finishes')).toBeFalsy();
  });
  it('should have 2 options in the dropdown when opened', () => {
    render(<FinishesConfigurator {...commonProps} />);
    const dropdown = screen.getByText('Select Material');
    expect(screen.queryByText('Alu')).toBeFalsy();
    expect(screen.queryByText('Aluminium Doors')).toBeFalsy();
    selectDropdown(dropdown);
    expect(screen.queryByText('Alu')).toBeTruthy();
    expect(screen.queryByText('Aluminium Doors')).toBeTruthy();
  });
  it('should not have a Concrete option if the driveway variant is not chosen', () => {
    render(<FinishesConfigurator {...commonProps} />);
    const dropdown = screen.getByText('Select Material');
    expect(screen.queryByText('Concrete')).toBeFalsy();
    selectDropdown(dropdown);
    expect(screen.queryByText('Concrete')).toBeFalsy();
  });
  it('should have a Concrete option if the driveway variant is chosen', () => {
    // @ts-expect-error: mockImplementationOnce
    useConfiguratorContext.mockImplementationOnce(() => ({
      selectedFeatureName: 'window',
      selectedVariants: ['door1', 'driveway', 'window2'],
      setSelectedMaterialName: jest.fn(),
      setSelectedTextures: jest.fn(),
    }));
    render(<FinishesConfigurator {...commonProps} />);
    const dropdown = screen.getByText('Select Material');
    expect(screen.queryByText('Concrete')).toBeFalsy();
    selectDropdown(dropdown);
    expect(screen.queryByText('Concrete')).toBeTruthy();
  });
  it('should not have materials and colour picker when Alu is chosen', () => {
    render(<FinishesConfigurator {...commonProps} />);
    const dropdown = screen.getByText('Select Material');
    selectDropdown(dropdown);
    fireEvent.click(screen.getByText('Alu'));
    expect(screen.queryByText('Colour')).toBeFalsy();
    expect(screen.queryByText('Materials')).toBeFalsy();
  });
});
