import { getSpacing } from 'stylesheet';
import styled from 'styled-components';
import { SubTitle } from 'styling/Typography.style';

export const Description = styled(SubTitle)`
  margin-bottom: ${getSpacing(0)};
`;
export const ConstructionOptionContainer = styled.div`
  margin-left: ${getSpacing(3)};
  margin-right: ${getSpacing(3)};
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: ${getSpacing(6)};
  margin-bottom: ${getSpacing(1)};
  width: ${getSpacing(30)};
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (max-width: 1350px) {
    margin-left: ${getSpacing(5)};
  }
`;
