/* eslint max-lines: ["error", 150] */
export const COUNTIES = [
  'Aberdeen',
  'Aberdeenshire',
  'Anglesey/Sir Fon',
  'Angus',
  'Argyll and Bute',
  'Ayrshire',
  'Banffshire',
  'Bedfordshire',
  'Berkshire',
  'Blaenau Gwent',
  'Bridgend',
  'Bristol',
  'Buckinghamshire',
  'Caerphilly',
  'Caithness',
  'Cambridgeshire',
  'Cardiff',
  'Carmarthenshire',
  'Ceredigion',
  'Cheshire',
  'Clackmannanshire',
  'Conwy',
  'Cornwall',
  'County Antrim',
  'County Armagh',
  'County Down',
  'County Fermanagh',
  'County Londonderry',
  'County Tyrone',
  'Cromartyshire',
  'Cumbria',
  'Denbighshire',
  'Derbyshire',
  'Devon',
  'Dorset',
  'Dumfries and Galloway',
  'Dunbartonshire',
  'Dundee',
  'Durham',
  'East Ayrshire',
  'East Dunbartonshire',
  'East Lothian',
  'East Renfrewshire',
  'East Riding of Yorkshire',
  'East Sussex',
  'East Yorkshire',
  'Edinburgh',
  'Essex',
  'Falkirk',
  'Fife',
  'Flintshire',
  'Glamorgan',
  'Glasgow',
  'Gloucestershire',
  'Greater Manchester',
  'Gwynedd',
  'Hampshire',
  'Hereford and Worcester',
  'Hertfordshire',
  'Highland',
  'Inverclyde',
  'Inverness-shire',
  'Isle of Man',
  'Isle of Wight',
  'Kent',
  'Kincardineshire',
  'Kinross-shire',
  'Lanarkshire',
  'Lancashire',
  'Leicestershire',
  'Lincolnshire',
  'London',
  'Merseyside',
  'Merthyr Tydfil',
  'Middlesex',
  'Midlothian',
  'Monmouthshire',
  'Moray',
  'Nairnshire',
  'Neath Port Talbot',
  'Newport',
  'Newport City',
  'Norfolk',
  'Northamptonshire',
  'North Ayrshire',
  'North Lanarkshire',
  'Northumberland',
  'North Yorkshire',
  'Nottinghamshire',
  'Orkney',
  'Oxfordshire',
  'Peeblesshire',
  'Pembrokeshire',
  'Perth and Kinross',
  'Perthshire',
  'Powys',
  'Renfrewshire',
  'Rhondda Cynon Taff',
  'Ross and Cromarty',
  'Rutland',
  'Scottish Borders',
  'Shetland Isles',
  'Shropshire',
  'Somerset',
  'South Ayrshire',
  'South Lanarkshire',
  'South Yorkshire',
  'Staffordshire',
  'Stirlingshire',
  'Suffolk',
  'Surrey',
  'Sutherland',
  'Swansea',
  'Torfaen',
  'Tyne and Wear',
  'Warwickshire',
  'West Dunbartonshire',
  'Western Isles',
  'West Lothian',
  'West Midlands',
  'West Sussex',
  'West Yorkshire',
  'Wiltshire',
  'Worcestershire',
  'Wrexham',
];
