import {
  ICustomerConstructionConfig,
  ICustomerFeatureConfig,
  ICustomerMaterialConfig,
  ICustomerModelConfig,
} from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type CustomerModelConfigState = ICustomerModelConfig;

export const initialState: CustomerModelConfigState = {
  modelId: null,
  features: {},
  materials: {},
  construction: null,
};

const CustomerModelConfig = createSlice({
  name: 'CustomerModelConfig',
  initialState,
  reducers: {
    setSavedCustomerFeatures: (state, action: PayloadAction<ICustomerFeatureConfig>) => {
      state.features[action.payload.value] = action.payload;
    },
    setSavedCustomerMaterials: (state, action: PayloadAction<ICustomerMaterialConfig>) => {
      state.materials[action.payload.value] = action.payload;
    },
    setDefaultCustomerModelConfig: (state, action: PayloadAction<ICustomerModelConfig>) => {
      state.modelId = action.payload.modelId;
      state.features = action.payload.features;
      state.materials = {};
      state.construction = action.payload.construction;
    },
    setCustomerModelConfig: (state, action: PayloadAction<ICustomerModelConfig>) => {
      state.modelId = action.payload.modelId;
      state.features = action.payload.features;
      state.materials = action.payload.materials;
    },
    setCustomerConstructionConfig: (state, action: PayloadAction<ICustomerConstructionConfig>) => {
      state.construction = action.payload.construction;
    },
  },
});

export const {
  setSavedCustomerFeatures,
  setSavedCustomerMaterials,
  setDefaultCustomerModelConfig,
  setCustomerModelConfig,
  setCustomerConstructionConfig,
} = CustomerModelConfig.actions;
export const { reducer } = CustomerModelConfig;
