import { IModel } from 'redux/Models/types';

export enum FloorPlanModalModeTypes {
  GROUND = 'ground',
  FIRST = 'first',
}

type GetFloorPlanProps = {
  floorPlanModalMode: FloorPlanModalModeTypes;
  selectedModel: IModel | undefined;
};
export const getFloorPlanUrl = ({
  floorPlanModalMode,
  selectedModel,
}: GetFloorPlanProps): string | undefined => {
  return floorPlanModalMode === FloorPlanModalModeTypes.GROUND
    ? selectedModel?.groundFloorPlanImgUrl
    : selectedModel?.firstFloorPlanImgUrl;
};
