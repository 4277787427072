import { IColorConfig } from './types';
import { RootState } from 'redux/types';
import { ColorsState } from './slice';
import { createSelector } from '@reduxjs/toolkit';
import { denormaliseItems } from 'redux/mappers';

export const getColors = (store: RootState): ColorsState => store.colors;

export const getAllColors = createSelector(getColors, (colors): IColorConfig[] =>
  denormaliseItems({ byIds: colors.byIds, allIds: colors.allIds }),
);
