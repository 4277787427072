import { colorUsage, getSpacing } from 'stylesheet';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colorUsage.secondaryBackground};
  height: 100%;
`;
PageContainer.displayName = 'PageContainer';

export const PageHeaderWrapper = styled.div`
  padding-top: ${getSpacing(10)};
  width: 100%;
`;
