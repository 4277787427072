import { colorUsage, fontSize, getSpacing } from 'stylesheet';
import styled from 'styled-components';
import { ActionText } from 'styling/Typography.style';
import { SecondaryButton } from 'components/Button/Button';
import { DeviceSize } from './useResponsive';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex: 1;
`;

export const ConfiguratorContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1100px) {
    flex-direction: column-reverse;
  }

  flex: 1;
  width: 100%;
`;

export const ModelContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModelContainerButtons = styled.div<{ deviceSize: DeviceSize }>`
  margin-left: auto;
  width: fit-content;
  margin-top: ${getSpacing(2)};
`;

export const ActionButtonsContainer = styled.div<{ deviceSize: DeviceSize }>`
  display: flex;
  width: 100%;
  margin-top: ${getSpacing(3)};
  flex-wrap: ${(props) => (props.deviceSize === DeviceSize.Phone ? 'wrap' : 'nowrap')};
  padding-bottom: ${getSpacing(2)};
`;

export const ActionButton = styled(SecondaryButton)<{ deviceSize?: DeviceSize }>`
  margin: 0;
  margin-right: ${getSpacing(3)};
  margin-top: ${(pr) => (pr.deviceSize === DeviceSize.Phone ? getSpacing(2) : 0)};
  width: ${(pr) => (pr.deviceSize === DeviceSize.Phone ? '100%' : 'auto')};
`;

export const FeaturesAndFinishesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${getSpacing(0)} ${getSpacing(5)};
`;

export const LeftPartitionContainer = styled.div<{ deviceSize: DeviceSize }>`
  display: flex;
  flex-direction: ${(props) => (props.deviceSize === DeviceSize.Phone ? 'column' : 'row')};
`;

export const HotspotContainer = styled.div`
  width: 100%;
`;

export const ConfiguratorPartitionContainer = styled.div`
  margin-right: ${getSpacing(4)};
`;

export const ResetCameraText = styled(ActionText)`
  font-size: ${fontSize.XSmall};
  color: ${colorUsage.primaryTextColor};
`;
