import { IFeatureConfig, IModel, IVariant } from 'redux/Models/types';

import { ICustomerFeatureConfig } from 'redux/CustomerModelConfig/types';

export const getDefaultVariants = (
  selectedModel: IModel,
): Record<string, ICustomerFeatureConfig> | undefined =>
  selectedModel?.features?.reduce(
    (acc: Record<string, ICustomerFeatureConfig>, feature: IFeatureConfig) => {
      if (feature.variants) {
        const defaultVariant: IVariant =
          feature.variants.find((variant) => variant.default) || feature.variants[0];
        if (defaultVariant) {
          return {
            ...acc,
            [feature.value]: { value: feature.value, variantValue: defaultVariant.value },
          };
        }
      }
      return acc;
    },
    {},
  );
