import { ConstructionType } from 'redux/Models/types';

const CONSTRUCTION_TITLES = {
  oneStarOpen: '1* - Open',
  twoStarOpen: '2* - Open',
  twoStarClosed: '2* - Closed',
  threeStarOpen: '3* - Open',
  threeStarClosed: '3* - Closed',
  fourStarClosed: '4* - Closed',
  fiveStarClosed: '5* - Closed',
};

export const getConstructionOptionDesc = (
  option: ConstructionType,
  constructionDescs: Record<ConstructionType, string>,
): string => {
  return constructionDescs[option];
};

export const getConstructionOptionTitle = (option: ConstructionType): string => {
  return CONSTRUCTION_TITLES[option];
};
