import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NormalisedRedux } from 'redux/types';
import { IModel } from './types';
import { normaliseArray } from 'redux/mappers';

export type ModelsState = NormalisedRedux<IModel> &
  Readonly<{ selectedModelId: string | undefined }>;

export const initialState: ModelsState = {
  loading: false,
  byIds: {},
  allIds: [],
  selectedModelId: undefined,
};

const Models = createSlice({
  name: 'Models',
  initialState,
  reducers: {
    setModel: (state, action: PayloadAction<IModel>) => {
      state.allIds = [action.payload.id];
      state.byIds = {
        [action.payload.id]: action.payload,
      };
    },
    setModels: (state, action: PayloadAction<IModel[]>) => {
      const { byIds, allIds } = normaliseArray(action.payload);
      state.byIds = byIds;
      state.allIds = allIds;
    },
    setSelectedModelId: (state, action: PayloadAction<string>) => {
      state.selectedModelId = action.payload;
    },
  },
});

export const { setModels, setSelectedModelId, setModel } = Models.actions;
export const { reducer } = Models;
