import React from 'react';
import { Image, ImageContainer, SquarePickerContainer, TextContainer } from './SquarePicker.style';
import { Text } from 'styling/Typography.style';

type MinOptions = {
  label: string;
  urlThumbnail: string;
};
interface IProps<T extends MinOptions | undefined> {
  options: T[];
  onOptionChange: (option: T) => void;
  isOptionSelected: (option: T) => boolean;
  width: number;
  height: number;
}

export const SquarePicker = <T extends MinOptions | undefined>(props: IProps<T>): JSX.Element => {
  return (
    <SquarePickerContainer>
      {props.options.map(
        (option, index) =>
          option && (
            <ImageContainer
              key={option.label}
              onClick={() => props.onOptionChange(option)}
              width={props.width}
              lastElement={index === props.options.length}
            >
              <Image
                alt={option.label}
                src={option.urlThumbnail}
                selected={props.isOptionSelected(option)}
                width={props.width}
                height={props.height}
              />
              <TextContainer width={props.width}>
                <Text>{option.label}</Text>
              </TextContainer>
            </ImageContainer>
          ),
      )}
    </SquarePickerContainer>
  );
};
