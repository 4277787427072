import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICMSField } from './types';
import { NormalisedRedux } from 'redux/types';
import { normaliseArray } from 'redux/mappers';

export type CMSState = NormalisedRedux<ICMSField>;

export const initialState: CMSState = {
  loading: false,
  byIds: {},
  allIds: [],
};

const CMSFields = createSlice({
  name: 'CMSFields',
  initialState,
  reducers: {
    setCMSFields: (state, action: PayloadAction<ICMSField[]>) => {
      const { byIds, allIds } = normaliseArray(action.payload);
      state.byIds = byIds;
      state.allIds = allIds;
    },
  },
});

export const { setCMSFields } = CMSFields.actions;
export const { reducer } = CMSFields;
