import { getAllModels, getModels, getSelectedModel } from 'redux/Models';

import { state } from '__fixtures__/state';

describe('Model selectors', () => {
  describe('getModels', () => {
    it('should return the model state', () => {
      expect(getModels(state)).toEqual(state.models);
    });
  });
  describe('getAllModels', () => {
    it('Should return all the denormalised models', () => {
      expect(getAllModels(state)).toEqual([
        {
          id: '123',
          sketchfabModelId: 'abc',
          backgroundImageId: 'mockBackgroundImageId',
          name: 'House 1',
          materials: [],
          hotspots: [],
          imgUrl: 'house.jpeg',
          description: '3 Bedroom | 1½ storey',
          estimatedCost: {
            oneStarOpen: 60000,
            twoStarOpen: 60000,
            twoStarClosed: 60000,
            threeStarOpen: 60000,
            threeStarClosed: 60000,
            fourStarClosed: 60000,
            fiveStarClosed: 60000,
          },
        },
        {
          id: '456',
          sketchfabModelId: 'def',
          name: 'House 2',
          materials: [],
          hotspots: [],
          imgUrl: 'house2.jpeg',
          description: '3 Bedroom | 1½ storey',
          estimatedCost: {
            oneStarOpen: 60000,
            twoStarOpen: 60000,
            twoStarClosed: 60000,
            threeStarOpen: 60000,
            threeStarClosed: 60000,
            fourStarClosed: 60000,
            fiveStarClosed: 60000,
          },
        },
      ]);
    });
  });
  describe('getSelectedModel', () => {
    it('should return the selected model', () => {
      expect(
        getSelectedModel({ ...state, models: { ...state.models, selectedModelId: '123' } }),
      ).toEqual(state.models.byIds['123']);
    });
    it("should return undefined if the selected model doesn't exist", () => {
      expect(
        getSelectedModel({ ...state, models: { ...state.models, selectedModelId: '000' } }),
      ).toEqual(undefined);
    });
    it('should return undefined if no selected model', () => {
      expect(getSelectedModel(state)).toEqual(undefined);
    });
  });
});
