import React from 'react';
import { render, screen } from '@testing-library/react';
import ShortLink from 'pages/ShortLink';
import { useConvertShortLink } from 'redux/hooks/useConvertShortLink';

jest.mock('react-router-dom', () => ({
  useParams: jest.fn(() => ({ shortLink: '1234567890' })),
}));

const mockConvertShortLink = jest.fn();
jest.mock('redux/hooks/useConvertShortLink', () => ({
  useConvertShortLink: jest.fn(() => [false, mockConvertShortLink]),
}));

describe('ShortLink Page', () => {
  it('should have a main title and subtitle', () => {
    render(<ShortLink />);
    expect(screen.queryByText('Scotframe')).toBeTruthy();
    expect(screen.queryByText('Redirecting you')).toBeTruthy();
  });
  it('should call the useConvertShortLink hook on load', () => {
    render(<ShortLink />);
    expect(mockConvertShortLink).toHaveBeenCalledWith({ shortLink: '1234567890' });
  });
  it('should shows an error message if the call fails', () => {
    // @ts-expect-error: mockImplementationOnce
    useConvertShortLink.mockImplementationOnce(() => [
      { value: { error: 'convert error' } },
      jest.fn(),
    ]);
    render(<ShortLink />);
    expect(screen.queryByText('Redirecting you')).toBeFalsy();
    expect(screen.queryByText('convert error')).toBeTruthy();
  });
});
