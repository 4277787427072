import { getSpacing } from 'stylesheet';

import styled from 'styled-components';

export const ColorConfiguratorContainer = styled.div`
  margin: ${getSpacing(2)} 0;
`;

export const ColorPickerContainer = styled.div`
  position: relative;
`;

export const ColorPickerHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TypographyContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;
