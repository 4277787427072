import React from 'react';
import { Field } from 'formik';
import { InputRow } from 'components/InputRow';

interface IProps {
  fields: Array<{
    name: string;
    label: string;
    placeholder?: string;
  }>;
  touched: { [key: string]: boolean | undefined };
  errors: { [key: string]: string | undefined };
}

export const TextFields: React.FunctionComponent<IProps> = (props) => (
  <>
    {props.fields.map((field) => (
      <Field
        key={field.name}
        type="text"
        name={field.name}
        label={field.label}
        placeholder={field.placeholder || field.label}
        component={InputRow}
        error={props.touched[field.name] && props.errors[field.name]}
      />
    ))}
  </>
);
