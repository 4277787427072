import { useDispatch } from 'react-redux';
import { AsyncFnReturn } from 'react-use/lib/useAsync';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { client } from 'services/networking/client';
import { setCMSFields } from './slice';
import { ICMSField } from './types';

export const useFetchCMSFields = (): AsyncFnReturn<(...args: unknown[]) => Promise<void>> => {
  const dispatch = useDispatch();
  return useTypedAsyncFn<unknown>(async () => {
    const fields: ICMSField[] = await client.get('/api/getcmsfields');
    dispatch(setCMSFields(fields));
  }, []);
};
