import * as React from 'react';
import { ModelViewer } from 'components/ModelViewer';
import { IHotspotConfig } from 'redux/Models/types';

interface IProps {
  sketchfabModelId: string;
  sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI>;
  initialHotspot: IHotspotConfig | undefined;
  cameraConstraints: ICameraConstraints | undefined;
  setCameraPosition: React.Dispatch<
    React.SetStateAction<
      | { eyePosition: [number, number, number]; targetPosition: [number, number, number] }
      | undefined
    >
  >;
  backgroundImageId: string | undefined;
}

export const ModelConfigurator: React.FunctionComponent<IProps> = (props) => {
  const [, setModelLoaded] = React.useState<string | null>(null);
  return (
    <ModelViewer
      apiRef={props.sketchfabApiRef}
      sketchfabModelId={props.sketchfabModelId}
      setModelLoaded={setModelLoaded}
      initialHotspot={props.initialHotspot}
      cameraConstraints={props.cameraConstraints}
      setCameraPosition={props.setCameraPosition}
      backgroundImageId={props.backgroundImageId}
    />
  );
};
