/* eslint max-lines: ["error", 103] */
import { Layout } from 'pages/Models/Models.style';
import { Prompt, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { getSelectedModel, setSelectedModelId } from 'redux/Models';
import { ConfiguratorContextProvider } from 'context/ConfiguratorContext';

import { PageContainer } from 'styling/Containers.style';
import { PageHeaderText, Title } from 'styling/Typography.style';
import { useDispatch } from 'react-redux';
import { useFetchColors } from 'redux/Colors/hooks';
import { useFetchCustomerModelConfig } from 'redux/CustomerModelConfig/hooks';
import { useFetchModel } from 'redux/Models/hooks';
import { useFetchTextures } from 'redux/Textures/hooks';
import { useSelector } from 'redux/useSelector';
import { useFetchCMSFields } from 'redux/CMS/hooks';
import { ConstructionType } from 'redux/Models/types';
import { Configurator } from './Configurator';
import { ModelStepper } from './ModelStepper';
import brandData from 'Brand/configs.json';
import { Spacer } from 'components/Spacer';
import { useResponsive } from './useResponsive';
import { getSpacing } from 'stylesheet';

type ParamTypes = {
  modelId: string;
  customerModelConfigId?: string;
  construction: ConstructionType;
};
const Models: React.FunctionComponent = () => {
  const params = useParams<ParamTypes>();
  const [, doFetchModel] = useFetchModel(params.modelId);
  const [, doFetchTextures] = useFetchTextures();
  const [, doFetchColors] = useFetchColors();
  const [, doFetchCMSFields] = useFetchCMSFields();
  const [, doFetchCustomerModelConfig] = useFetchCustomerModelConfig();
  const dispatch = useDispatch();
  const selectedModel = useSelector(getSelectedModel);

  useEffect(() => {
    window.onbeforeunload = () => true;
    doFetchModel();
    doFetchTextures();
    doFetchColors();
    doFetchCMSFields();
    if (params.customerModelConfigId) {
      doFetchCustomerModelConfig({
        modelId: params.modelId,
        customerModelConfigId: params.customerModelConfigId,
      });
    } else {
      dispatch(setSelectedModelId(params.modelId));
    }
  }, [
    dispatch,
    params.modelId,
    params.customerModelConfigId,
    doFetchTextures,
    doFetchCustomerModelConfig,
    params,
    doFetchColors,
    doFetchCMSFields,
    doFetchModel,
  ]);

  const { deviceSize } = useResponsive();
  const showStepper = selectedModel?.showStepper ?? true;

  return (
    <ConfiguratorContextProvider>
      <PageContainer>
        <Prompt message="You may have unsaved changes. Are you sure you want to leave?" />
        {!selectedModel ? (
          <Title>Model not found</Title>
        ) : (
          <>
            <Layout>
              {!brandData.hideStepper && showStepper ? (
                <ModelStepper />
              ) : (
                <Spacer.Column numberOfSpaces={7} />
              )}
              <PageHeaderText
                style={{
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  marginTop: getSpacing(2),
                  marginBottom: getSpacing(7),
                }}
                deviceSize={deviceSize}
              >
                {selectedModel.name}
              </PageHeaderText>
              <Configurator selectedModel={selectedModel} modelId={params.modelId} />
            </Layout>
          </>
        )}
      </PageContainer>
    </ConfiguratorContextProvider>
  );
};
export default Models;
