import React from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { getCMSFieldById } from 'redux/CMS';
import { HTMLText } from 'components/HTMLText';

const AsteriskText: React.FunctionComponent = () => {
  const pricesText = useSelector(getCMSFieldById('PricesText'));

  return (
    <div
      onClick={() => {
        ReactGA.event({
          category: 'User Action',
          action: 'Viewed Specification Detail',
        });
      }}
    >
      <HTMLText textToSet={pricesText} heightUnset={false} />
    </div>
  );
};
export default AsteriskText;
