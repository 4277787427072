import { colorUsage, fontFamily, fontSize, fontWeight, getSpacing, lineHeight } from 'stylesheet';

import styled from 'styled-components';
import { DeviceSize } from 'pages/Models/useResponsive';

export const PageHeaderText = styled.h1<{ deviceSize?: DeviceSize }>`
  font-family: ${fontFamily.header};
  align-self: center;
  /*stylelint-disable */
  font-size: ${(props) =>
    props.deviceSize === DeviceSize.Phone ? fontSize.XLarge : fontSize.XXLarge};
  line-height: ${lineHeight.medium};
  /*stylelint-enable */
  color: ${colorUsage.primaryTextColor};
`;
PageHeaderText.displayName = 'PageHeaderText';

export const PageSubHeaderText = styled.h2`
  text-align: center;
  font-family: ${fontFamily.header};
  align-self: center;
  font-size: ${fontSize.XLarge};
  line-height: ${lineHeight.medium};
  color: ${colorUsage.primaryTextColor};
`;
PageSubHeaderText.displayName = 'PageSubHeaderText';

export const Title = styled.h1`
  align-self: center;
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.large};
  line-height: ${lineHeight.medium};
  margin-bottom: ${getSpacing(6)};
  color: ${colorUsage.primaryTextColor};
`;
Title.displayName = 'Title';

export const SubTitle = styled.h2`
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.medium};
  margin-bottom: ${getSpacing(3)};
  color: ${colorUsage.primaryTextColor};
`;
SubTitle.displayName = 'SubTitle';

export const InputSubTitle = styled(SubTitle)`
  text-align: start;
  margin-bottom: 0;
`;
SubTitle.displayName = 'InputSubTitle';

export const Text = styled.p`
  font-weight: ${fontWeight.normal};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.XSmall};
  line-height: ${lineHeight.small};
  height: ${lineHeight.small};
  color: ${colorUsage.primaryTextColor};
  text-align: center;
`;
Text.displayName = 'Text';

export const Paragraph = styled(Text)`
  height: unset;
  font-size: ${fontSize.small};
  line-height: ${lineHeight.medium};
`;

export const Label = styled.span`
  font-weight: ${fontWeight.normal};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.small};
  line-height: ${lineHeight.medium};
  height: ${lineHeight.small};
  color: ${colorUsage.primaryTextColor};
  margin-left: ${getSpacing(2)};
`;

export const ActionText = styled(Text)`
  color: ${colorUsage.actionTextColor};
  text-decoration: underline;
  cursor: pointer;
`;

export const ErrorText = styled(SubTitle)`
  color: ${colorUsage.error};
`;
ErrorText.displayName = 'ErrorText';

export const LinkText = styled(SubTitle)`
  color: ${colorUsage.primaryDark};
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-bottom: ${getSpacing(1)};
`;
