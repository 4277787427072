import { ModelGroup } from './types';
import { RootState } from 'redux/types';
import { ModelGroupState } from './slice';
import { createSelector } from '@reduxjs/toolkit';
import { denormaliseItems } from 'redux/mappers';

export const getModelGroups = (store: RootState): ModelGroupState => store.modelGroups;

export const getAllModelGroups = createSelector(getModelGroups, (colors): ModelGroup[] =>
  denormaliseItems({ byIds: colors.byIds, allIds: colors.allIds }),
);
