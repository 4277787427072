import { AsyncFnReturn } from 'react-use/lib/useAsync';
import { IColorConfig } from './types';
import { client } from 'services/networking/client';
import { setColors } from 'redux/Colors';
import { useDispatch } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';

export const useFetchColors = (): AsyncFnReturn<(...args: unknown[]) => Promise<void>> => {
  const dispatch = useDispatch();
  return useTypedAsyncFn<unknown>(async () => {
    const colors: IColorConfig[] = await client.get('/api/getcolors');
    dispatch(setColors(colors));
  }, []);
};
