/* eslint max-lines: ["error", 180] */
import { fireEvent, render, screen } from '@testing-library/react';
import { FeatureConfigurator } from 'pages/Models/FeatureConfigurator';
import React from 'react';
import { apiRefMock } from '__mocks__/sketchfabApi';
import { selectDropdown } from 'testing/testSelectHelpers';
import { ConfiguratorContext } from 'context/ConfiguratorContext';
import { IFeatureConfig } from 'redux/Models/types';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

const commonProps = {
  sketchfabApiRef: apiRefMock,
};

jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => mockCMSContent[id],
}));

jest.mock('redux/Models', () => ({
  getSelectedModel: () => ({
    id: '123',
    sketchfabModelId: 'abc',
    name: 'House 1',
    features: [],
  }),
}));

jest.mock('services/sketchfab/variant');

jest.mock('redux/CustomerModelConfig');

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest.fn((fn) => fn()),
}));

const ProvidedFeatureConfigurator = () => {
  const [selectedFeature, setSelectedFeature] = React.useState<null | IFeatureConfig>(null);
  const providerValue = {
    replaceSelectedVariant: jest.fn(),
    selectedFeature,
    setSelectedFeature,
    selectedFeatureName: '',
    selectedStep: {
      viewType: 'design',
      associatedFeatures: [
        {
          value: 'door',
          label: 'Door',
          variants: [
            {
              label: 'Door 1',
              value: 'door1',
              urlThumbnail: 'door1.jpeg',
            },
            {
              label: 'Door 2',
              value: 'door2',
              urlThumbnail: 'door2.jpeg',
            },
          ],
        },
        {
          value: 'window',
          label: 'Window',
          variants: [
            {
              label: 'Window 1',
              value: 'window1',
              urlThumbnail: 'window1.jpeg',
            },
          ],
        },
      ],
    },
  };
  return (
    // @ts-expect-error: don't need full context
    <ConfiguratorContext.Provider value={providerValue}>
      <FeatureConfigurator {...commonProps} />
    </ConfiguratorContext.Provider>
  );
};

describe('FeatureConfigurator Component UI', () => {
  it('should have a Features title', () => {
    render(<ProvidedFeatureConfigurator />);
    expect(screen.queryByText('Features')).toBeTruthy();
  });
  it('should have a features dropdown', () => {
    render(<ProvidedFeatureConfigurator />);
    expect(screen.queryAllByText('Select Feature')[1]).toBeTruthy();
  });
  it('should have a placeholder text in place of features dropdown if no features', () => {
    render(<FeatureConfigurator {...commonProps} />);
    expect(screen.queryAllByText('Select Feature')[1]).toBeFalsy();
    expect(
      screen.queryByText('Currently there are no features to change on this model'),
    ).toBeTruthy();
  });
  it('should have a 2 options in the dropdown when opened', () => {
    render(<ProvidedFeatureConfigurator />);
    const dropdown = screen.getAllByText('Select Feature')[1];
    expect(screen.queryByText('Window')).toBeFalsy();
    expect(screen.queryByText('Door')).toBeFalsy();
    selectDropdown(dropdown);
    expect(screen.queryByText('Window')).toBeTruthy();
    expect(screen.queryByText('Door')).toBeTruthy();
  });

  it('should have 2 squares if the feature is Door', () => {
    render(<ProvidedFeatureConfigurator />);
    const dropdown = screen.getAllByText('Select Feature')[1];
    selectDropdown(dropdown);
    fireEvent.click(screen.getByText('Door'));

    expect(screen.queryByAltText('Door 1')).toBeTruthy();
    expect(screen.queryByAltText('Door 2')).toBeTruthy();
  });
  it('should have 1 square if I switch to Window from Door', () => {
    render(<ProvidedFeatureConfigurator />);
    let dropdown = screen.getAllByText('Select Feature')[1];
    selectDropdown(dropdown);
    fireEvent.click(screen.getByText('Door'));
    expect(screen.queryByAltText('Door 1')).toBeTruthy();
    expect(screen.queryByAltText('Door 2')).toBeTruthy();
    expect(screen.queryByAltText('Window 1')).toBeFalsy();

    dropdown = screen.getByText('Door');
    selectDropdown(dropdown);
    fireEvent.click(screen.getByText('Window'));
    expect(screen.queryByAltText('Door 1')).toBeFalsy();
    expect(screen.queryByAltText('Door 2')).toBeFalsy();
    expect(screen.queryByAltText('Window 1')).toBeTruthy();
  });
  it('should keep a selected variant after I change feature', () => {
    render(<ProvidedFeatureConfigurator />);
    let dropdown = screen.getAllByText('Select Feature')[1];
    selectDropdown(dropdown);
    fireEvent.click(screen.getByText('Door'));
    fireEvent.click(screen.getByAltText('Door 2'));
    const door2Style = window.getComputedStyle(screen.getByAltText('Door 2'));
    expect(door2Style.border).toBe('3px solid #0158a6');

    dropdown = screen.getByText('Door');
    selectDropdown(dropdown);
    fireEvent.click(screen.getByText('Window'));
    fireEvent.click(screen.getByAltText('Window 1'));
    const window1Style = window.getComputedStyle(screen.getByAltText('Window 1'));
    expect(window1Style.border).toBe('3px solid #0158a6');

    dropdown = screen.getByText('Window');
    selectDropdown(dropdown);
    fireEvent.click(screen.getByText('Door'));
    const door2StyleUpdated = window.getComputedStyle(screen.getByAltText('Door 2'));
    expect(door2StyleUpdated.border).toBe('3px solid #0158a6');
  });
});
