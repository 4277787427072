import React from 'react';
import styled from 'styled-components';
import { Field, FieldProps } from 'formik';
import { ErrorText, Text } from 'styling/Typography.style';

const CheckAndTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  name: string;
  message: string;
}
export const CheckboxField: React.FC<IProps> = ({ name, message }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <>
            <CheckAndTextContainer>
              <input
                type="checkbox"
                data-testid={name}
                id={name}
                checked={field.value}
                {...field}
              />
              <Text>{message}</Text>
            </CheckAndTextContainer>
            {form.errors[name] && form.touched[name] && <ErrorText>{form.errors[name]}</ErrorText>}
          </>
        );
      }}
    </Field>
  );
};
