import React from 'react';
import { ConfiguratorContextProvider, useConfiguratorContext } from 'context/ConfiguratorContext';
import { render, screen, fireEvent } from '@testing-library/react';

const DummyConsumer = () => {
  const { selectedVariants, replaceSelectedVariant } = useConfiguratorContext();
  return (
    <>
      {selectedVariants.map((variant) => (
        <div key={variant}>{variant}</div>
      ))}
      <button onClick={() => replaceSelectedVariant('new variant', [])}>Add New Variant</button>
      <button onClick={() => replaceSelectedVariant('second variant', [])}>
        Add Second New Variant
      </button>
      <button
        onClick={() =>
          replaceSelectedVariant(undefined, [
            { value: 'new variant', label: 'new variant', urlThumbnail: 'pic.jpeg' },
          ])
        }
      >
        Remove New Variant
      </button>
      <button
        onClick={() =>
          replaceSelectedVariant('third variant', [
            { value: 'new variant', label: 'new variant', urlThumbnail: 'pic.jpeg' },
            { value: 'second variant', label: 'second variant', urlThumbnail: 'pic.jpeg' },
          ])
        }
      >
        Replace Variants
      </button>
    </>
  );
};

describe('ConfiguratorContext', () => {
  it('should allow you to add to selected variants', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    expect(screen.queryByText('new variant')).toBeFalsy();
    fireEvent.click(screen.getByText('Add New Variant'));
    expect(screen.queryByText('new variant')).toBeTruthy();
  });
  it('should allow you to remove a selected variants', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    expect(screen.queryByText('new variant')).toBeFalsy();
    fireEvent.click(screen.getByText('Add New Variant'));
    expect(screen.queryByText('new variant')).toBeTruthy();
    fireEvent.click(screen.getByText('Remove New Variant'));
    expect(screen.queryByText('new variant')).toBeFalsy();
  });
  it('should not allow you to add duplicate variants', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    fireEvent.click(screen.getByText('Add New Variant'));
    expect(screen.getAllByText('new variant').length).toBe(1);
    fireEvent.click(screen.getByText('Add New Variant'));
    expect(screen.getAllByText('new variant').length).toBe(1);
  });
  it('should allow you to replacevariants', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    fireEvent.click(screen.getByText('Add New Variant'));
    fireEvent.click(screen.getByText('Add Second New Variant'));
    expect(screen.queryByText('new variant')).toBeTruthy();
    expect(screen.queryByText('second variant')).toBeTruthy();
    expect(screen.queryByText('third variant')).toBeFalsy();
    fireEvent.click(screen.getByText('Replace Variants'));
    expect(screen.queryByText('new variant')).toBeFalsy();
    expect(screen.queryByText('second variant')).toBeFalsy();
    expect(screen.queryByText('third variant')).toBeTruthy();
  });
});
