import * as React from 'react';

import { PageContainer } from 'styling/Containers.style';
import { Title, Text, ErrorText } from 'styling/Typography.style';
import { useParams } from 'react-router-dom';
import { useConvertShortLink } from 'redux/hooks/useConvertShortLink';

type ParamTypes = {
  shortLink: string;
};

const ShortLink: React.FunctionComponent = () => {
  const params = useParams<ParamTypes>();

  const [convertShortLinkState, doConvertShortLink] = useConvertShortLink();
  React.useEffect(() => {
    doConvertShortLink({ shortLink: params.shortLink });
  }, [doConvertShortLink, params.shortLink]);

  const error = convertShortLinkState?.value?.error;

  return (
    <PageContainer>
      <Title>Scotframe</Title>
      {error ? <ErrorText>{error}</ErrorText> : <Text>Redirecting you</Text>}
    </PageContainer>
  );
};

export default ShortLink;
