import { IModel } from 'redux/Models/types';
import { getDefaultVariants } from './getDefaultVariants';

describe('getDefaultVariants', () => {
  it('Should set the first variant as default if no default is specified', () => {
    const selectedModel: IModel = {
      name: 'retool house 2',
      imgUrl: 'https://publicsketchfabstaging.blob.core.windows.net/models/gaelic-glenbrittle.jpg',
      id: 'b5801f0e-0bd0-404b-92db-77391d772176',
      description: '2 bed',
      sketchfabModelId: 'f75d0121588b41038721c72cbd472f31',
      backgroundImageId: 'mockBackgroundImageId',
      materials: [],
      hotspots: [],
      features: [
        {
          variants: [
            {
              value: 'door_01',
              label: 'door_01',
              urlThumbnail:
                'https://publicsketchfabstaging.blob.core.windows.net/models/gaelic-glenbrittle.jpg',
            },
            {
              value: 'door_02',
              label: 'door_02',
              urlThumbnail:
                'https://publicsketchfabstaging.blob.core.windows.net/models/gaelic-glenbrittle.jpg',
            },
          ],
          value: 'Door',
          label: 'Door',
        },
      ],
      estimatedCost: {
        oneStarOpen: 60000,
        twoStarOpen: 60000,
        twoStarClosed: 60000,
        threeStarOpen: 60000,
        threeStarClosed: 60000,
        fourStarClosed: 60000,
        fiveStarClosed: 60000,
      },
    };
    const defaultVariants = getDefaultVariants(selectedModel);
    const expectedVariants = {
      Door: {
        value: 'Door',
        variantValue: 'door_01',
      },
    };
    expect(defaultVariants).toEqual(expectedVariants);
  });
  it('Should set the default variant as default', () => {
    const selectedModel: IModel = {
      name: 'retool house 2',
      imgUrl: 'https://publicsketchfabstaging.blob.core.windows.net/models/gaelic-glenbrittle.jpg',
      id: 'b5801f0e-0bd0-404b-92db-77391d772176',
      description: '2 bed',
      sketchfabModelId: 'f75d0121588b41038721c72cbd472f31',
      backgroundImageId: 'mockBackgroundImageId',
      materials: [],
      hotspots: [],
      features: [
        {
          variants: [
            {
              value: 'door_01',
              label: 'door_01',
              urlThumbnail:
                'https://publicsketchfabstaging.blob.core.windows.net/models/gaelic-glenbrittle.jpg',
            },
            {
              default: true,
              value: 'door_02',
              label: 'door_02',
              urlThumbnail:
                'https://publicsketchfabstaging.blob.core.windows.net/models/gaelic-glenbrittle.jpg',
            },
          ],
          value: 'Door',
          label: 'Door',
        },
      ],
      estimatedCost: {
        oneStarOpen: 60000,
        twoStarOpen: 60000,
        twoStarClosed: 60000,
        threeStarOpen: 60000,
        threeStarClosed: 60000,
        fourStarClosed: 60000,
        fiveStarClosed: 60000,
      },
    };
    const defaultVariants = getDefaultVariants(selectedModel);
    const expectedVariants = {
      Door: {
        value: 'Door',
        variantValue: 'door_02',
      },
    };
    expect(defaultVariants).toEqual(expectedVariants);
  });
});
