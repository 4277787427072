import { fireEvent, render, screen } from '@testing-library/react';

import React from 'react';
import { RequestAQuoteButtonAndModal } from 'pages/Models/RequestAQuoteButtonAndModal';
import { useCreateCustomerModelConfig } from 'redux/CustomerModelConfig/hooks';

jest.mock('redux/useSelector', () => ({
  useSelector: jest.fn((fn) => fn()),
}));

jest.mock('redux/CustomerModelConfig', () => ({
  getCustomerModelConfig: () => ({ modelId: '123', features: {}, materials: {} }),
}));

jest.mock('redux/Models', () => ({
  getSelectedModel: () => ({
    id: '123',
    estimatedCost: {
      threeStarClosed: 60000,
    },
  }),
}));

const mockCreateModelConfig = jest.fn();
const mockSendCustomerQuoteEmail = jest.fn();
jest.mock('redux/CustomerModelConfig/hooks', () => ({
  useCreateCustomerModelConfig: jest.fn(() => [
    { value: { id: '456', shortLink: '/short/def' } },
    mockCreateModelConfig,
  ]),
  useSendCustomerQuoteEmail: jest.fn(() => [
    {
      modelId: '123',
      features: {},
      materials: {},
    },
    mockSendCustomerQuoteEmail,
  ]),
}));

const commonProps = {
  modelId: '123',
};

jest.mock('redux/CMS', () => ({
  getCMSFieldById: () => () => {
    return 'mock';
  },
  getConstructionCMSFields: () => ({
    oneStarOpen: '3* - Closed',
    twoStarOpen: '3* - Closed',
    twoStarClosed: '3* - Closed',
    threeStarOpen: '3* - Closed',
    threeStarClosed: '3* - Closed',
    fourStarClosed: '3* - Closed',
    fiveStarClosed: '3* - Closed',
  }),
}));

describe('Request Quote Button and Modal Component', () => {
  it('should have a Request Quote Button', () => {
    render(<RequestAQuoteButtonAndModal {...commonProps} />);
    expect(screen.queryByText('Request Quote')).toBeTruthy();
  });
  it('should call the Create Customer Model Config hook when I click save', () => {
    render(<RequestAQuoteButtonAndModal {...commonProps} />);
    fireEvent.click(screen.getByText('Request Quote'));
    expect(mockCreateModelConfig).toHaveBeenCalledWith({
      modelId: '123',
      features: {},
      materials: {},
    });
  });
  it('should show an error message if the create call fails', () => {
    // @ts-expect-error: mockImplementationOnce
    useCreateCustomerModelConfig.mockImplementationOnce(() => [
      { value: { error: 'test error', id: null } },
      mockCreateModelConfig,
    ]);
    //@ts-expect-error: mockImplementationOnce
    useCreateCustomerModelConfig.mockImplementationOnce(() => [
      { value: { error: 'test error', id: null } },
      mockCreateModelConfig,
    ]);
    render(<RequestAQuoteButtonAndModal {...commonProps} />);
    fireEvent.click(screen.getByText('Request Quote'));
    expect(screen.queryByText('test error')).toBeTruthy();
    fireEvent.click(screen.getByText('Close'));
    expect(screen.queryByText('test error')).toBeFalsy();
  });
  it('should see a closeable modal when I click Request Quote', () => {
    render(<RequestAQuoteButtonAndModal {...commonProps} />);
    fireEvent.click(screen.getByText('Request Quote'));
    expect(screen.queryByText('Close')).toBeTruthy();
    fireEvent.click(screen.getByText('Close'));
    expect(screen.queryByText('Close')).toBeFalsy();
  });
  it('should have 3 fields in the modal', () => {
    render(<RequestAQuoteButtonAndModal {...commonProps} />);
    expect(screen.queryByLabelText('First Name*')).toBeFalsy();
    expect(screen.queryByLabelText('Last Name*')).toBeFalsy();
    expect(screen.queryByLabelText('Email*')).toBeFalsy();
    expect(screen.queryByLabelText('Contact Number*')).toBeFalsy();
    fireEvent.click(screen.getByText('Request Quote'));
    expect(screen.queryByLabelText('First Name*')).toBeTruthy();
    expect(screen.queryByLabelText('Last Name*')).toBeTruthy();
    expect(screen.queryByLabelText('Email*')).toBeTruthy();
    expect(screen.queryByLabelText('Contact Number*')).toBeTruthy();
  });
});
