import { IMaterialConfig, IModel } from 'redux/Models/types';

type getPlaceholderTextProps = {
  materialOptions: IMaterialConfig[] | undefined;
  selectedFeatureName: string | null;
  selectedModel: IModel | undefined;
  noFinishesForFeatureText: string;
  noFinishesForModelText: string;
  selectFeatureText: string;
};

export const getPlaceholderText = ({
  materialOptions,
  selectedFeatureName,
  selectedModel,
  noFinishesForFeatureText,
  noFinishesForModelText,
  selectFeatureText,
}: getPlaceholderTextProps): string => {
  if (materialOptions && materialOptions.length === 0 && selectedFeatureName) {
    return noFinishesForFeatureText;
  }
  if (!selectedModel?.features) {
    return noFinishesForModelText;
  } else {
    return selectFeatureText;
  }
};
