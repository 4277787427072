import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ITextureConfig } from './types';
import { NormalisedRedux } from 'redux/types';
import { normaliseArray } from 'redux/mappers';

export type TexturesState = NormalisedRedux<ITextureConfig>;

export const initialState: TexturesState = {
  loading: false,
  byIds: {},
  allIds: [],
};

const Textures = createSlice({
  name: 'Textures',
  initialState,
  reducers: {
    setTextures: (state, action: PayloadAction<ITextureConfig[]>) => {
      const { byIds, allIds } = normaliseArray(action.payload);
      state.byIds = byIds;
      state.allIds = allIds;
    },
  },
});

export const { setTextures } = Textures.actions;
export const { reducer } = Textures;
