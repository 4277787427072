import { HTMLText } from 'components/HTMLText';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { ConstructionType } from 'redux/Models/types';
import { getSpacing } from 'stylesheet';
import { Paragraph } from 'styling/Typography.style';
import { ConstructionOptionContainer, Description } from './Construction.style';

interface IProps {
  title: string;
  description: string;
  value: ConstructionType;
  price: number;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ConstructionOption: React.FunctionComponent<IProps> = (props) => {
  return (
    <ConstructionOptionContainer>
      <Description>{props.title}</Description>
      <HTMLText textToSet={props.description} heightUnset={false} />
      <Paragraph>
        Kit Cost*:{' '}
        <FormattedNumber
          value={props.price}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency={'GBP'}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      </Paragraph>
      <input
        type="radio"
        value={props.value}
        data-testid={'radio'}
        style={{
          width: 20,
          height: 20,
          marginTop: getSpacing(2),
          marginBottom: getSpacing(6),
        }}
        checked={props.checked}
        onChange={props.onChange}
      />
    </ConstructionOptionContainer>
  );
};
