/* eslint-disable max-lines */
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getSelectedModel } from 'redux/Models';
import { setAllDefaultColors } from 'services/sketchfab/color';
import { useSelector } from 'redux/useSelector';
import { setAllDefaultVariants, setAllCustomerVariants } from 'services/sketchfab/variant';
import { getAllTextures } from 'redux/Textures';
import { getCustomerModelConfig } from 'redux/CustomerModelConfig';
import { setAllCustomerTextures } from 'services/sketchfab/texture';
import { IHotspotConfig } from 'redux/Models/types';

const IframeContainer = styled.div`
  height: 80%;
  width: 100%;
  min-height: 40vh;
  flex-grow: 1;
`;
const SketchfabIframe = styled.iframe`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const useSketchfabViewer = (
  sketchfabModelId: string,
  setModelLoaded: Dispatch<SetStateAction<string | null>>,
  initialHotspot: IHotspotConfig | undefined,
  cameraConstraints: ICameraConstraints | undefined,
  setCameraPosition: React.Dispatch<
    React.SetStateAction<
      | { eyePosition: [number, number, number]; targetPosition: [number, number, number] }
      | undefined
    >
  >,
  backgroundImageId: string | undefined,
): [JSX.Element, null | ISketchfabAPI] => {
  const viewerIframeRef = useRef(null);
  const [api, setApi] = useState<null | ISketchfabAPI>(null);
  const ViewerIframe = (
    <IframeContainer>
      <SketchfabIframe
        ref={viewerIframeRef}
        title="sketchfab-viewer"
        sandbox="allow-scripts allow-same-origin"
      />
    </IframeContainer>
  );
  const selectedModel = useSelector(getSelectedModel);
  const customerModelConfig = useSelector(getCustomerModelConfig);
  const textures = useSelector(getAllTextures);

  useEffect(() => {
    const client = new window.Sketchfab(viewerIframeRef.current);
    client.init(sketchfabModelId, {
      success: (api) => {
        setApi(api);
        api.addEventListener('viewerready', () => {
          if (cameraConstraints) {
            api.setCameraConstraints(cameraConstraints);
            api.setEnableCameraConstraints(true);
          }
          if (backgroundImageId) {
            api.setBackground({ uid: backgroundImageId });
          }
          setModelLoaded(sketchfabModelId);
          setAllDefaultColors(selectedModel, textures, api);
          setAllDefaultVariants(selectedModel, api);
          setAllCustomerVariants(selectedModel, customerModelConfig, api);
          setAllCustomerTextures(api, customerModelConfig, textures);
          if (initialHotspot) {
            api.setCameraLookAt(initialHotspot.eyePosition, initialHotspot.targetPosition, 3);
          }
        });
        api.addEventListener('camerastop', () => {
          api.getCameraLookAt((_, camera) => {
            setCameraPosition({
              eyePosition: camera.position,
              targetPosition: camera.target,
            });
          });
        });
      },
      error: () => {
        console.log('Viewer error');
      },
      autostart: 1,
      ui_help: 1,
      ui_settings: 0,
      ui_inspector: 0,
      ui_ar: 0,
      ui_vr: 0,
      ui_fullscreen: 1,
      ui_infos: 0,
      ui_watermark: 0,
      ui_stop: 0,
    });
    // adding customerModelConfig to this causes the model to reload on each customer change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sketchfabModelId, setModelLoaded, selectedModel, textures]);

  return [ViewerIframe, api];
};

interface IProps {
  apiRef: React.MutableRefObject<null | ISketchfabAPI>;
  sketchfabModelId: string;
  setModelLoaded: Dispatch<SetStateAction<string | null>>;
  initialHotspot: IHotspotConfig | undefined;
  cameraConstraints: ICameraConstraints | undefined;
  setCameraPosition: React.Dispatch<
    React.SetStateAction<
      | { eyePosition: [number, number, number]; targetPosition: [number, number, number] }
      | undefined
    >
  >;
  backgroundImageId: string | undefined;
}

export const ModelViewer: React.FunctionComponent<IProps> = (props) => {
  const [ViewerIframe, api] = useSketchfabViewer(
    props.sketchfabModelId,
    props.setModelLoaded,
    props.initialHotspot,
    props.cameraConstraints,
    props.setCameraPosition,
    props.backgroundImageId,
  );

  props.apiRef.current = api;

  return ViewerIframe;
};
