import styled from 'styled-components';
import { getSpacing, colorUsage, fontWeight, fontSize } from 'stylesheet';
import { Title } from 'styling/Typography.style';

interface IProps {
  expanded: boolean;
}

export const ConfiguratorContainer = styled.div<IProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${getSpacing(1)};
  overflow: hidden;
  max-width: ${(props) => (props.expanded ? getSpacing(58) : getSpacing(10))};
  transition: max-width 0.2s ease-out;
`;

export const TitleContainer = styled.div<IProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${getSpacing(2)} ${getSpacing(4)};
  background-color: ${colorUsage.navigationBackground};
  cursor: pointer;
`;

export const Icon = styled.img`
  width: ${getSpacing(6)};
  height: ${getSpacing(6)};
  margin-top: -${getSpacing(0.5)};
  margin-bottom: -${getSpacing(1)};
  margin-right: -${getSpacing(2.2)};
`;

export const ConfiguratorTitle = styled(Title)<IProps>`
  font-weight: ${fontWeight.bold};
  color: ${colorUsage.navigationText};
  text-decoration: none;
  margin-bottom: 0;
  margin-left: ${(props) => (props.expanded ? getSpacing(0) : getSpacing(-30))};
  transition: margin-left 0.2s ease-out;
  font-size: ${fontSize.medium};
`;
