import { colorUsage, fontSize, getSpacing } from 'stylesheet';
import styled from 'styled-components';
import { ActionText } from 'styling/Typography.style';
import { DeviceSize } from 'pages/Models/useResponsive';

export const PageHeaderContainer = styled.div<{ deviceSize: DeviceSize }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding-top: ${(props) => (props.deviceSize === DeviceSize.Phone ? getSpacing(5) : 0)};
  padding-bottom: ${getSpacing(4)};
  background-color: ${colorUsage.secondaryBackground};
  align-items: center;
`;

export const HelpContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-right: ${getSpacing(4)};
`;

export const HelpText = styled(ActionText)`
  font-size: ${fontSize.medium};
`;

export const SubHeaderTextContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
`;
