import { render, screen } from '@testing-library/react';

import { FeatureConfigurator } from 'pages/Models/FeatureConfigurator';
import React from 'react';
import { apiRefMock } from '__mocks__/sketchfabApi';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
}));

jest.mock('redux/useSelector', () => ({
  useSelector: jest.fn((fn) => fn()),
}));
jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => mockCMSContent[id],
}));
jest.mock('redux/Models', () => ({
  getSelectedModel: () => [
    {
      id: '123',
      label: 'WhatIsIncludedText',
      value: 'blah',
    },
  ],
}));

jest.mock('context/ConfiguratorContext', () => ({
  useConfiguratorContext: () => ({
    selectedStep: { label: 'design' },
    setSelectedFeature: jest.fn(),
  }),
}));

const commonProps = { sketchfabApiRef: apiRefMock };

describe('Models Page', () => {
  it('should have a Features title', () => {
    render(<FeatureConfigurator {...commonProps} />);
    expect(screen.queryByText('Features')).toBeTruthy();
  });
});
