import { fireEvent, render, screen, waitFor } from '@testing-library/react';

import React from 'react';
import { SaveModelForm } from 'pages/Models/SaveModelForm';
import { useSendCustomerModelEmail } from 'redux/CustomerModelConfig/hooks';

const commonProps = {
  modelId: '123',
  getCustomerLink: () => 'hello',
};

jest.mock('redux/useSelector', () => ({
  useSelector: jest.fn((fn) => fn()),
}));

jest.mock('redux/CustomerModelConfig', () => ({
  getCustomerModelConfig: () => ({
    modelId: '123',
    features: {},
    materials: {},
    construction: 'threeStarClosed',
  }),
}));

jest.mock('redux/Models', () => ({
  getSelectedModel: () => ({
    id: '123',
    estimatedCost: {
      threeStarClosed: 60000,
    },
  }),
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: () => () => {
    return 'optIn';
  },
  getConstructionCMSFields: () => ({
    oneStarOpen: '3* - Closed',
    twoStarOpen: '3* - Closed',
    twoStarClosed: '3* - Closed',
    threeStarOpen: '3* - Closed',
    threeStarClosed: '3* - Closed',
    fourStarClosed: '3* - Closed',
    fiveStarClosed: '3* - Closed',
  }),
}));

const customerModelConfig = {
  modelId: '123',
  features: {},
  materials: {},
  construction: 'threeStarClosed',
};

const mockSendCustomerModelEmail = jest.fn();
jest.mock('redux/CustomerModelConfig/hooks', () => ({
  useSendCustomerModelEmail: jest.fn(() => [{}, mockSendCustomerModelEmail]),
}));

describe('SaveModelForm', () => {
  it('should have a title, subtitle and button', () => {
    render(<SaveModelForm {...commonProps} />);
    expect(
      screen.queryByText('Enter your email below to receive a copy of your specifications'),
    ).toBeTruthy();
    expect(screen.queryByText('Email Me')).toBeTruthy();
  });
  it('should have 1 field and a checkbox', () => {
    render(<SaveModelForm {...commonProps} />);
    expect(screen.queryByLabelText('Email')).toBeTruthy();
    expect(screen.queryByTestId('optInContact')).toBeTruthy();
  });
  it('should have no errors if all fields are valid', async () => {
    render(<SaveModelForm {...commonProps} />);
    fireEvent.change(screen.getByLabelText('Email'), { target: { value: 'rob@email.com' } });
    fireEvent.click(screen.getByTestId('optInContact'));
    await waitFor(() => {
      expect(screen.queryByText('Please enter a valid email')).toBeFalsy();
    });
  });
  it('should have 1 required field show an error', async () => {
    render(<SaveModelForm {...commonProps} />);
    fireEvent.blur(screen.getByLabelText('Email'));
    await waitFor(() => {
      expect(screen.queryByText('Please enter a valid email')).toBeTruthy();
    });
  });
  it('should have an email field that requires a valid email', async () => {
    render(<SaveModelForm {...commonProps} />);
    const emailInput = screen.getByLabelText('Email');
    fireEvent.blur(emailInput);
    fireEvent.change(emailInput, { target: { value: 'rob' } });
    await waitFor(() => {
      expect(screen.queryByText('Please enter a valid email')).toBeTruthy();
    });
    fireEvent.change(emailInput, { target: { value: 'rob@email.com' } });
    await waitFor(() => {
      expect(screen.queryByText('Please enter a vald email')).toBeFalsy();
    });
  });
  it('should have a disabled button until the fields are valid', async () => {
    render(<SaveModelForm {...commonProps} />);
    expect(screen.getByText('Email Me').getAttribute('disabled')).not.toBe(null);
    fireEvent.change(screen.getByLabelText('Email'), { target: { value: 'rob@email.com' } });
    fireEvent.click(screen.getByTestId('optInContact'));
    await waitFor(() => {
      expect(screen.getByText('Email Me').getAttribute('disabled')).toBe(null);
    });
  });
  it('should call the submit function with the values when submitted', async () => {
    render(<SaveModelForm {...commonProps} />);
    fireEvent.change(screen.getByLabelText('Email'), { target: { value: 'rob@email.com' } });
    fireEvent.click(screen.getByTestId('optInContact'));
    fireEvent.click(screen.getByText('Email Me'));
    await waitFor(() => {
      expect(mockSendCustomerModelEmail).toHaveBeenCalledWith({
        customerModelConfig,
        email: 'rob@email.com',
        modelId: '123',
        modelLink: 'hello',
        optIn: true,
        constructionLabel: '3* - Closed',
        constructionPrice: '£60,000',
      });
    });
  });
  it('should return a success message when the hook is successful', async () => {
    // @ts-expect-error mockImplementation
    useSendCustomerModelEmail.mockImplementationOnce(() => [
      { value: { success: 'Success: Email sent' } },
      mockSendCustomerModelEmail,
    ]);
    render(<SaveModelForm {...commonProps} />);
    expect(screen.queryByText('Success: Email sent')).toBeTruthy();
  });
  it('should return an error message when the hook is unsuccessful', async () => {
    // @ts-expect-error mockImplementation
    useSendCustomerModelEmail.mockImplementationOnce(() => [
      { value: { error: 'Error: Email failed' } },
      mockSendCustomerModelEmail,
    ]);
    render(<SaveModelForm {...commonProps} />);
    expect(screen.queryByText('Error: Email failed')).toBeTruthy();
  });
});
