import * as React from 'react';
import {
  CenteredContainer,
  CenteredAsterixTextContainer,
  ModelsContainer,
  ModelGroupTitle,
  ModelGroupSubtitle,
} from './Home.style';
import { ModelInfo } from './ModelInfo';
import { PageContainer, PageHeaderWrapper } from 'styling/Containers.style';
import { PageHeader } from 'components/PageHeader';
import { getAllModels } from 'redux/Models';
import { useFetchModels } from 'redux/Models/hooks';
import { useFetchTextures } from 'redux/Textures/hooks';
import { useSelector } from 'redux/useSelector';
import { useFetchCMSFields } from 'redux/CMS/hooks';
import { getCMSFieldById } from 'redux/CMS';
import AsteriskText from 'components/AsteriskText';
import { Spacer } from 'components/Spacer';
import { useFetchModelGroups } from 'redux/ModelGroups/hooks';
import { getAllModelGroups } from 'redux/ModelGroups';

const Home: React.FunctionComponent = () => {
  const models = useSelector(getAllModels);
  const headerText = useSelector(getCMSFieldById('HeaderText'));
  const subHeaderText = useSelector(getCMSFieldById('SubHeaderHomeText'));
  const helpText = useSelector(getCMSFieldById('HeaderHelpText'));
  const modelGroups = useSelector(getAllModelGroups).sort((a, b) => a.index - b.index);
  const [, doFetchModels] = useFetchModels();
  const [, doFetchModelGroups] = useFetchModelGroups();
  const [, doFetchTextures] = useFetchTextures();
  const [, doFetchCMSFields] = useFetchCMSFields();
  React.useEffect(() => {
    doFetchModels();
    doFetchModelGroups();
    doFetchTextures();
    doFetchCMSFields();
  }, [doFetchModels, doFetchTextures, doFetchCMSFields, doFetchModelGroups]);

  return (
    <PageContainer>
      <PageHeaderWrapper>
        <PageHeader headerText={headerText} subHeaderText={subHeaderText} helpText={helpText} />
      </PageHeaderWrapper>
      {modelGroups.length > 0 ? (
        modelGroups.map((group) => {
          const filteredModels = models.filter((model) => group.modelIds.includes(model.id));
          return (
            <CenteredContainer key={group.id}>
              <ModelGroupTitle>{group.title}</ModelGroupTitle>
              <ModelGroupSubtitle>{group.subtitle}</ModelGroupSubtitle>
              <ModelsContainer>
                {filteredModels.map((model) => (
                  <ModelInfo key={model.id} model={model} />
                ))}
              </ModelsContainer>
            </CenteredContainer>
          );
        })
      ) : (
        <CenteredContainer>
          <ModelsContainer>
            {models.map((model) => (
              <ModelInfo key={model.id} model={model} />
            ))}
          </ModelsContainer>
        </CenteredContainer>
      )}
      <Spacer.Flex />
      <CenteredAsterixTextContainer>
        <AsteriskText />
      </CenteredAsterixTextContainer>
    </PageContainer>
  );
};

export default Home;
