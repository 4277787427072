/* eslint-disable max-lines */
import * as yup from 'yup';
import { CheckboxField, TextFields } from 'components/Forms';
import { ErrorText, SubTitle, Text } from 'styling/Typography.style';
import { FormContainer, StyledForm, SubmitButton } from 'components/Forms';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { getCustomerModelConfig } from 'redux/CustomerModelConfig';
import { useSelector } from 'redux/useSelector';
import { useSendCustomerModelEmail } from 'redux/CustomerModelConfig/hooks';
import ReactGA from 'react-ga';
import { getSelectedModel } from 'redux/Models';
import { getConstructionOptionTitle } from 'pages/Construction/helpers';
import { createIntl, createIntlCache } from 'react-intl';
import { getCMSFieldById } from 'redux/CMS';

export type FormValues = {
  emailAddress: string;
  optInContact: boolean;
};
const SaveModelSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .email('Please enter a valid email')
    .required('Please enter a valid email'),
  optInContact: yup.bool(),
});
export const initialValues: FormValues = {
  emailAddress: '',
  optInContact: false,
};
interface IProps {
  modelId: string;
  getCustomerLink: () => string;
}
const cache = createIntlCache();
const intl = createIntl(
  {
    locale: 'en-GB',
    messages: {},
  },
  cache,
);

export const SaveModelForm: React.FC<IProps> = (props) => {
  const customerModelConfig = useSelector(getCustomerModelConfig);
  const [sendCustomerModelEmailState, doSendCustomerModelEmail] = useSendCustomerModelEmail();
  const contactOptInText = useSelector(getCMSFieldById('ContactOptInText'));
  const selectedModel = useSelector(getSelectedModel);
  const fields = [
    {
      name: 'emailAddress',
      label: 'Email',
    },
  ];

  return (
    <FormContainer noPadding>
      <SubTitle>Enter your email below to receive a copy of your specifications</SubTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={SaveModelSchema}
        onSubmit={(values) => {
          ReactGA.event({
            category: 'User Action',
            action: 'Saved Model',
            label: props.modelId,
          });
          if (values.optInContact) {
            ReactGA.event({
              category: 'User Action',
              action: 'Save Opt In',
              label: props.modelId,
            });
          }
          const constructionPrice =
            customerModelConfig.construction &&
            selectedModel?.estimatedCost[customerModelConfig.construction];
          doSendCustomerModelEmail({
            email: values.emailAddress,
            customerModelConfig,
            modelId: props.modelId,
            modelLink: props.getCustomerLink(),
            optIn: values.optInContact,
            constructionLabel: customerModelConfig.construction
              ? getConstructionOptionTitle(customerModelConfig.construction)
              : 'N/A',
            constructionPrice: constructionPrice
              ? intl.formatNumber(constructionPrice, {
                  style: 'currency',
                  currency: 'GBP',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : '',
          });
        }}
      >
        {(props: FormikProps<FormValues>) => (
          <StyledForm>
            <TextFields fields={fields} touched={props.touched} errors={props.errors} />
            <CheckboxField name="optInContact" message={contactOptInText} />
            <SubmitButton
              type="submit"
              disabled={props.isSubmitting || !props.isValid || !props.dirty}
            >
              Email Me
            </SubmitButton>
            {sendCustomerModelEmailState.value?.error && (
              <ErrorText>{sendCustomerModelEmailState.value.error}</ErrorText>
            )}
            {sendCustomerModelEmailState.value?.success && (
              <Text>{sendCustomerModelEmailState.value.success}</Text>
            )}
          </StyledForm>
        )}
      </Formik>
    </FormContainer>
  );
};
