/* eslint max-lines: ["error", 150] */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, createContext, useContext, useState } from 'react';
import { IFeatureConfig, IStep, IVariant, ModelView } from 'redux/Models/types';
import { Color } from 'redux/Colors/types';

interface IConfiguratorContext {
  selectedMaterialName: string | null;
  setSelectedMaterialName: (selectedMaterialName: string | null) => void;
  selectedVariants: string[];
  replaceSelectedVariant: (newValue: string | undefined, removedVariants: IVariant[]) => void;
  selectedTextures: Record<string, { textureId: string }>;
  setSelectedTextures: (selectedTextures: Record<string, { textureId: string }>) => void;
  selectedColors: Record<string, Color>;
  setSelectedColors: (selectedTextures: Record<string, Color>) => void;
  selectedFeature: IFeatureConfig | null;
  setSelectedFeature: (feature: IFeatureConfig | null) => void;
  selectedFeatureName: string | null;
  expandedScreen: boolean;
  setExpandedScreen: (expanded: boolean) => void;
  selectedStep: IStep | null;
  setSelectedStep: (step: IStep | null) => void;
  stepOptions: IStep[];
  setStepOptions: (steps: IStep[]) => void;
  viewType: ModelView;
}

export const ConfiguratorContext = createContext<IConfiguratorContext>({
  selectedMaterialName: null,
  setSelectedMaterialName: () => {},
  selectedVariants: [],
  replaceSelectedVariant: (newValue, removedVariants) => {},
  selectedTextures: {},
  setSelectedTextures: () => {},
  selectedColors: {},
  setSelectedColors: () => {},
  selectedFeature: null,
  setSelectedFeature: () => {},
  selectedFeatureName: null,
  expandedScreen: false,
  setExpandedScreen: () => {},
  selectedStep: null,
  setSelectedStep: () => {},
  stepOptions: [],
  setStepOptions: () => {},
  viewType: ModelView.DESIGN,
});

interface IConfiguratorContextProviderProps {
  children: ReactNode;
}

export const ConfiguratorContextProvider: React.FunctionComponent<IConfiguratorContextProviderProps> = (
  props,
) => {
  const [selectedMaterialName, setSelectedMaterialName] = useState<string | null>(null);
  const [selectedVariants, setSelectedVariants] = useState<string[]>([]);
  const [selectedTextures, setSelectedTextures] = useState<Record<string, { textureId: string }>>(
    {},
  );
  const [selectedColors, setSelectedColors] = useState<Record<string, Color>>({});
  const [selectedFeature, setSelectedFeatureState] = React.useState<IFeatureConfig | null>(null);
  const [selectedFeatureName, setSelectedFeatureName] = useState<string | null>(null);
  const [expandedScreen, setExpandedScreen] = useState<boolean>(false);
  const [selectedStep, setSelectedStepInState] = useState<IStep | null>(null);
  const [stepOptions, setStepOptions] = useState<IStep[]>([]);

  const replaceSelectedVariant = (newValue: string | undefined, removedVariants: IVariant[]) => {
    let newVariants = [...selectedVariants];
    removedVariants.forEach((removedValue) => {
      newVariants = newVariants.filter((variant) => variant !== removedValue.value);
    });
    if (newValue && newVariants.indexOf(newValue) === -1) {
      newVariants = [...newVariants, newValue];
    }
    setSelectedVariants(newVariants);
  };

  const viewType = selectedStep ? selectedStep.viewType : ModelView.DESIGN;

  const setSelectedFeature = (feature: IFeatureConfig | null) => {
    setSelectedFeatureState(feature);
    setSelectedFeatureName(feature ? feature.value : null);
  };

  const setSelectedStep = (step: IStep | null): void => {
    setSelectedStepInState(step);
    setSelectedFeature(step?.associatedFeatures[0] ?? null);
  };

  return (
    <ConfiguratorContext.Provider
      value={{
        selectedMaterialName,
        setSelectedMaterialName,
        selectedVariants,
        replaceSelectedVariant,
        selectedTextures,
        setSelectedTextures,
        selectedColors,
        setSelectedColors,
        selectedFeature,
        setSelectedFeature,
        selectedFeatureName,
        expandedScreen,
        setExpandedScreen,
        selectedStep,
        setSelectedStep,
        stepOptions,
        setStepOptions,
        viewType,
      }}
    >
      {props.children}
    </ConfiguratorContext.Provider>
  );
};

export const useConfiguratorContext = (): IConfiguratorContext => useContext(ConfiguratorContext);
