import { Button } from 'components/Button';
import React from 'react';
import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

import { useSteps } from './steps';

export const StepperButton = styled(Button)`
  margin: 0;
  margin-left: ${getSpacing(3)};
`;

export const ModalStepperButtonsBack: React.FC = () => {
  const { setBackStep } = useSteps();
  return (
    <StepperButton onClick={setBackStep} disabled={!setBackStep}>
      Back
    </StepperButton>
  );
};

export const ModalStepperButtonsNext: React.FC = () => {
  const { setNextStep } = useSteps();
  return (
    <StepperButton onClick={setNextStep} disabled={!setNextStep}>
      Next
    </StepperButton>
  );
};

export const ModelStepperButtons: React.FC = () => {
  return (
    <>
      <ModalStepperButtonsBack />
      <ModalStepperButtonsNext />
    </>
  );
};
