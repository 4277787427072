import React from 'react';
import { TextFields } from 'components/Forms';
import { render, screen, fireEvent } from '@testing-library/react';
import { Formik } from 'formik';

const formFields = [
  {
    name: 'fullName',
    label: 'Full Name',
  },
  {
    name: 'emailAddress',
    label: 'Email',
  },
];

describe('Text Fields', () => {
  it('should render 2 text input fields', () => {
    render(
      <Formik initialValues={{ fullName: '', emailAddress: '' }} onSubmit={jest.fn()}>
        <TextFields fields={formFields} touched={{}} errors={{}} />
      </Formik>,
    );
    expect(screen.queryByLabelText('Full Name')).toBeTruthy();
    expect(screen.queryByLabelText('Email')).toBeTruthy();
  });
  it('should be able to edit the values of the 2 fields', () => {
    render(
      <Formik initialValues={{ fullName: '', emailAddress: '' }} onSubmit={jest.fn()}>
        <TextFields fields={formFields} touched={{}} errors={{}} />
      </Formik>,
    );
    const fullNameInput = screen.getByLabelText('Full Name');
    const emailInput = screen.getByLabelText('Email');
    fireEvent.change(fullNameInput, { target: { value: 'Rob Cronin' } });
    // @ts-expect-error: input value
    expect(fullNameInput.value).toBe('Rob Cronin');
    fireEvent.change(emailInput, { target: { value: 'rob@email.com' } });
    // @ts-expect-error: input value
    expect(emailInput.value).toBe('rob@email.com');
  });
});
