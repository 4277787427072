import DOMPurify from 'dompurify';
import React from 'react';
import { colorUsage, fontFamily, fontSize, fontWeight, lineHeight } from 'stylesheet';

type HTMLTextProps = {
  textToSet: string;
  heightUnset: boolean;
};

export const HTMLText: React.FC<HTMLTextProps> = ({ textToSet, heightUnset }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(`<h2 style="font-weight: ${fontWeight.normal};
      font-family: ${fontFamily.main};
      font-size: ${fontSize.small};
      line-height: ${lineHeight.medium};
      ${heightUnset ? 'height: unset;' : null}
      color: ${colorUsage.primaryTextColor};
      text-align: center;">${textToSet}</h2>`),
      }}
    />
  );
};
