import styled, { css } from 'styled-components';
import {
  borderRadius,
  colorUsage,
  fontFamily,
  fontSize,
  fontWeight,
  getSpacing,
  lineHeight,
} from 'stylesheet';

interface IProps {
  smallFont?: boolean;
}

export const Button = styled.button<IProps>`
  padding: ${getSpacing(2)} ${getSpacing(4)};
  margin: ${getSpacing(2)} ${getSpacing(2)};

  min-width: fit-content;

  word-break: keep-all;

  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${(props) => (props.smallFont ? fontSize.small : fontSize.medium)};
  line-height: ${(props) => (props.smallFont ? lineHeight.small : lineHeight.medium)};

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  border: none;
  border-radius: ${borderRadius.medium};

  text-decoration: none;

  color: ${colorUsage.primaryButtonColor};
  background-color: ${(props) =>
    props.disabled
      ? colorUsage.primaryButtonBackgroundDisabled
      : colorUsage.primaryButtonBackground};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? colorUsage.primaryButtonBackgroundDisabled
        : colorUsage.primaryButtonBackgroundHover};
  }
`;

export const SecondaryButton = styled(Button)`
  color: ${colorUsage.secondaryButtonColor};
  background-color: ${(props) =>
    props.disabled
      ? colorUsage.secondaryButtonBackgroundDisabled
      : colorUsage.secondaryButtonBackground};

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? colorUsage.secondaryButtonBackgroundDisabled
        : colorUsage.secondaryButtonBackgroundHover};
  }
`;
