import { ICMSField } from './types';
import { RootState } from 'redux/types';
import { CMSState } from './slice';
import { createSelector } from '@reduxjs/toolkit';
import { denormaliseItems } from 'redux/mappers';

export const getCMSFields = (store: RootState): CMSState => store.CMS;

export const getAllCMSFields = createSelector(getCMSFields, (fields): ICMSField[] =>
  denormaliseItems({ byIds: fields.byIds, allIds: fields.allIds }),
);

export const getCMSFieldById = (id: string) => (store: RootState): string => {
  const fields = denormaliseItems(store.CMS);
  return fields.find((field) => field.label === id)?.value ?? '';
};
export const getConstructionCMSFields = (
  store: RootState,
): {
  oneStarOpen: string;
  twoStarOpen: string;
  twoStarClosed: string;
  threeStarOpen: string;
  threeStarClosed: string;
  fourStarClosed: string;
  fiveStarClosed: string;
} => {
  const fields = denormaliseItems(store.CMS);
  return {
    oneStarOpen: fields.find((field) => field.label === 'oneStarOpen')?.value ?? '',
    twoStarOpen: fields.find((field) => field.label === 'twoStarOpen')?.value ?? '',
    twoStarClosed: fields.find((field) => field.label === 'twoStarClosed')?.value ?? '',
    threeStarOpen: fields.find((field) => field.label === 'threeStarOpen')?.value ?? '',
    threeStarClosed: fields.find((field) => field.label === 'threeStarClosed')?.value ?? '',
    fourStarClosed: fields.find((field) => field.label === 'fourStarClosed')?.value ?? '',
    fiveStarClosed: fields.find((field) => field.label === 'fiveStarClosed')?.value ?? '',
  };
};
