import { fireEvent, render, screen, waitFor } from '@testing-library/react';
import React from 'react';
import { SaveModelButtonAndModal } from 'pages/Models/SaveModelButtonAndModal';
import { useCreateCustomerModelConfig } from 'redux/CustomerModelConfig/hooks';

jest.mock('redux/useSelector', () => ({
  useSelector: jest.fn((fn) => fn()),
}));
jest.mock('redux/CustomerModelConfig', () => ({
  getCustomerModelConfig: () => ({
    modelId: '123',
    features: {},
    materials: {},
    construction: 'threeStarClosed',
  }),
}));
jest.mock('redux/Models', () => ({
  getSelectedModel: () => ({
    id: '123',
    estimatedCost: {
      threeStarClosed: 60000,
    },
  }),
}));
const mockCreateModelConfig = jest.fn();
const mockSendCustomerModelEmail = jest.fn();
jest.mock('redux/CustomerModelConfig/hooks', () => ({
  useCreateCustomerModelConfig: jest.fn(() => [
    { value: { id: '456', shortLink: '/short/def' } },
    mockCreateModelConfig,
  ]),
  useSendCustomerModelEmail: jest.fn(() => [
    {
      email: 'test@test.com',
      customerModelConfig: {
        modelId: '123',
        features: {},
        materials: {},
        construction: 'threeStarClosed',
      },
      modelId: '123',
    },
    mockSendCustomerModelEmail,
  ]),
}));
jest.mock('redux/CMS', () => ({
  getCMSFieldById: () => () => {
    return 'mock';
  },
  getConstructionCMSFields: () => ({
    oneStarOpen: '3* - Closed',
    twoStarOpen: '3* - Closed',
    twoStarClosed: '3* - Closed',
    threeStarOpen: '3* - Closed',
    threeStarClosed: '3* - Closed',
    fourStarClosed: '3* - Closed',
    fiveStarClosed: '3* - Closed',
  }),
}));
describe('Save Model Button Component', () => {
  it('should have a Save Button', () => {
    render(<SaveModelButtonAndModal modelId="123" />);
    expect(screen.queryByText('Save')).toBeTruthy();
  });
  it('should call the Create Customer Model Config hook when I click save', () => {
    render(<SaveModelButtonAndModal modelId="123" />);
    fireEvent.click(screen.getByText('Save'));
    expect(mockCreateModelConfig).toHaveBeenCalledWith({
      features: {},
      materials: {},
      modelId: '123',
      construction: 'threeStarClosed',
    });
  });
  it('should see a success message when I click save', () => {
    render(<SaveModelButtonAndModal modelId="123" />);
    fireEvent.click(screen.getByText('Save'));
    expect(screen.queryByText('Please save the link below for your model')).toBeTruthy();
    expect(screen.queryByText('http://localhost/short/def')).toBeTruthy();
    fireEvent.click(screen.getByText('Close'));
    expect(screen.queryByText('Please save the link below for your model')).toBeFalsy();
  });
  it('should show an error message if the create call fails', () => {
    // @ts-expect-error: mockImplementationOnce
    useCreateCustomerModelConfig.mockImplementationOnce(() => [
      { value: { error: 'test error', id: null } },
      mockCreateModelConfig,
    ]);
    // @ts-expect-error: mockImplementationOnce
    useCreateCustomerModelConfig.mockImplementationOnce(() => [
      { value: { error: 'test error', id: null } },
      mockCreateModelConfig,
    ]);
    render(<SaveModelButtonAndModal modelId="123" />);
    fireEvent.click(screen.getByText('Save'));
    expect(screen.queryByText('test error')).toBeTruthy();
    fireEvent.click(screen.getByText('Close'));
    expect(screen.queryByText('test error')).toBeFalsy();
  });
  it('should have a copy button to copy the link to my clipboard', () => {
    const clipboardMock = jest.fn();
    Object.assign(navigator, {
      clipboard: {
        writeText: clipboardMock,
      },
    });
    render(<SaveModelButtonAndModal modelId="123" />);
    fireEvent.click(screen.getByText('Save'));
    expect(screen.queryByText('Copied to Clipboard')).toBeFalsy();
    fireEvent.click(screen.getByText('Copy'));
    expect(clipboardMock).toHaveBeenCalledWith('http://localhost/short/def');
    expect(screen.queryByText('Copied to Clipboard')).toBeTruthy();
  });
  it('should have a description and a disabled email me button when I open the modal', () => {
    render(<SaveModelButtonAndModal modelId="123" />);
    fireEvent.click(screen.getByText('Save'));
    expect(
      screen.queryByText('Enter your email below to receive a copy of your specifications'),
    ).toBeTruthy();
    expect(screen.queryByText('Email Me')).toBeTruthy();
    fireEvent.click(screen.getByText('Email Me'));
    expect(mockSendCustomerModelEmail).not.toHaveBeenCalled();
    expect(screen.queryByText('Please enter a valid email address')).toBeFalsy();
  });
  it('should send email when a valid email has been entered', async () => {
    render(<SaveModelButtonAndModal modelId="123" />);
    fireEvent.click(screen.getByText('Save'));
    fireEvent.change(screen.getByLabelText('Email'), {
      target: { value: 'sarahh@theodo.co.uk' },
    });
    fireEvent.click(screen.getByText('Email Me'));
    await waitFor(() => {
      expect(mockSendCustomerModelEmail).toHaveBeenCalledWith({
        customerModelConfig: {
          features: {},
          materials: {},
          modelId: '123',
          construction: 'threeStarClosed',
        },
        email: 'sarahh@theodo.co.uk',
        modelId: '123',
        modelLink: 'http://localhost/short/def',
        optIn: false,
        constructionLabel: '3* - Closed',
        constructionPrice: '£60,000',
      });
    });
  });
});
