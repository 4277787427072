import { RootState } from 'redux/types';
import { denormaliseItems } from 'redux/mappers';
import { createSelector } from '@reduxjs/toolkit';
import { ModelsState } from './slice';
import { IModel } from './types';

export const getModels = (store: RootState): ModelsState => store.models;
export const getSelectedModelId = createSelector(
  getModels,
  (models): string | undefined => models.selectedModelId,
);

export const getAllModels = createSelector(getModels, (models): IModel[] =>
  denormaliseItems({ byIds: models.byIds, allIds: models.allIds }),
);
export const getSelectedModel = createSelector(getModels, getSelectedModelId, (models, modelId):
  | IModel
  | undefined => (modelId ? models.byIds[modelId] : undefined));
