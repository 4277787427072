import { fireEvent, render, screen } from '@testing-library/react';

import React from 'react';
import { SquarePicker } from 'components/SquarePicker';

const commonProps = {
  options: [
    { label: 'Door', id: 'door', urlThumbnail: 'door.jpeg' },
    { label: 'Window', id: 'window', urlThumbnail: 'window.jpeg' },
  ],
  isOptionSelected: (option: { id: string }) => option.id === 'window',
  onOptionChange: jest.fn(),
  width: 10,
  height: 15,
};

describe('SquarePicker Component', () => {
  it('should have 2 square images with labels', () => {
    render(<SquarePicker {...commonProps} />);
    expect(screen.queryByAltText('Door')).toBeTruthy();
    expect(screen.queryByAltText('Window')).toBeTruthy();
    expect(screen.queryByText('Door')).toBeTruthy();
    expect(screen.queryByText('Window')).toBeTruthy();
  });
  it('should call the onOptionChange when new one selected', () => {
    commonProps.onOptionChange.mockClear();
    render(<SquarePicker {...commonProps} />);
    fireEvent.click(screen.getByAltText('Door'));
    expect(commonProps.onOptionChange).toHaveBeenCalledWith({
      id: 'door',
      label: 'Door',
      urlThumbnail: 'door.jpeg',
    });
  });
  it('should have a scotframe blue border around the selected square', () => {
    render(<SquarePicker {...commonProps} />);
    const doorStyle = window.getComputedStyle(screen.getByAltText('Door'));
    expect(doorStyle.border).toBe('3px solid transparent');
    const windowStyle = window.getComputedStyle(screen.getByAltText('Window'));
    expect(windowStyle.border).toBe('3px solid #0158a6');
  });
});
