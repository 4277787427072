import { IMaterialConfig, IModel } from 'redux/Models/types';
import { ITextureConfig } from 'redux/Textures/types';
import { RGBColor } from 'react-color';

export const hexToRgb = (hex: string): RGBColor => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result || result[1] === undefined || result[2] === undefined || result[3] === undefined) {
    return {
      r: 0,
      g: 0,
      b: 0,
    };
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

export const rgbToHex = (rgb: RGBColor): string => {
  const componentToHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return '#' + componentToHex(rgb.r) + componentToHex(rgb.g) + componentToHex(rgb.b);
};

export const convertColor = (rgbColor: RGBColor): [number, number, number] => {
  return [rgbColor.r / 256, rgbColor.g / 256, rgbColor.b / 256];
};

export const changeMaterialColor = (
  apiRef: React.MutableRefObject<null | ISketchfabAPI>,
  materialName: string,
  color: RGBColor,
  texturePaletteChannel?: IChannelName,
): void => {
  apiRef.current?.getMaterialList((err, materials: IMaterial[]) => {
    const material = materials.find((material) => material.name === materialName);
    if (material) {
      const updatedMaterial = { ...material };
      const paletteChannel: IChannelName = texturePaletteChannel ?? 'AlbedoPBR';
      switch (paletteChannel) {
        case 'ClearCoat':
          updatedMaterial.channels[paletteChannel].tint = convertColor(color);
          updatedMaterial.channels[paletteChannel].enable = true;
          break;
        case 'AlbedoPBR':
          updatedMaterial.channels[paletteChannel].color = convertColor(color);
          break;
      }
      apiRef.current?.setMaterial(updatedMaterial);
    }
  });
};

export const setAllDefaultColors = (
  selectedModel: IModel | undefined,
  textures: ITextureConfig[],
  api: ISketchfabAPI,
): void => {
  selectedModel?.materials.forEach((material: IMaterialConfig) => {
    const defaultTexture =
      material.availableTextures?.find((texture) => texture?.default) ||
      (material.availableTextures && material.availableTextures[0]);
    if (defaultTexture) {
      const textureConfig = textures.find((texture) => texture.id === defaultTexture.textureId);
      if (textureConfig && textureConfig.defaultColor) {
        changeMaterialColor({ current: api }, material.value, textureConfig.defaultColor);
      }
    }
  });
};
