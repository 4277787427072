import { useSteps } from '../steps';
import { useConfiguratorContext } from 'context/ConfiguratorContext';

const mockSetStepOptions = jest.fn();
const mockSetSelectedStep = jest.fn();
const mockUseConfiguratorContext = useConfiguratorContext as jest.Mock;

jest.mock('context/ConfiguratorContext', () => ({
  useConfiguratorContext: jest.fn(),
}));

beforeEach(() => {
  jest.clearAllMocks();
  mockUseConfiguratorContext.mockImplementation(() => ({
    setStepOptions: mockSetStepOptions,
    setSelectedStep: mockSetSelectedStep,
    stepOptions: [
      { index: 0, label: 'Step 1' },
      { index: 1, label: 'Step 2' },
    ],
  }));
});

const stepOptions = [
  { index: 0, label: 'Step 1' },
  { index: 1, label: 'Step 2' },
];

describe('steps - setting options', () => {
  it('should set the default options if no steps in the model', () => {
    const { setStepsOnLoad } = useSteps();
    // @ts-expect-error: Don't need full model
    setStepsOnLoad({ features: [{ label: 'Feature 1' }] });
    expect(mockSetStepOptions).toHaveBeenCalledWith([
      {
        associatedFeatures: [{ label: 'Feature 1' }],
        index: 0,
        label: 'Design',
        viewType: 'design',
      },
      { associatedFeatures: [], index: 1, label: 'Explore', viewType: 'explore' },
    ]);
    expect(mockSetSelectedStep).toHaveBeenCalledWith({
      associatedFeatures: [{ label: 'Feature 1' }],
      index: 0,
      label: 'Design',
      viewType: 'design',
    });
  });
  it('should set the custom options if steps in the model', () => {
    const { setStepsOnLoad } = useSteps();
    const model = {
      features: [
        { value: 'feature1', label: 'Ironmongery' },
        { value: 'feature2', label: 'Doors' },
        { value: 'feature3', label: 'Windows' },
      ],
      steps: [
        { associatedFeatures: [{ value: 'feature2' }], label: 'Step 1' },
        { associatedFeatures: [{ value: 'feature1' }, { value: 'feature3' }], label: 'Step 2' },
      ],
    };
    // @ts-expect-error: Don't need full model
    setStepsOnLoad(model);
    expect(mockSetStepOptions).toHaveBeenCalledWith([
      {
        associatedFeatures: [{ label: 'Doors', value: 'feature2' }],
        index: 0,
        label: 'Step 1',
        viewType: 'design',
      },
      {
        associatedFeatures: [
          { label: 'Ironmongery', value: 'feature1' },
          { label: 'Windows', value: 'feature3' },
        ],
        index: 1,
        label: 'Step 2',
        viewType: 'design',
      },
      { associatedFeatures: [], index: 2, label: 'Explore', viewType: 'explore' },
    ]);
    expect(mockSetSelectedStep).toHaveBeenCalledWith({
      associatedFeatures: [{ label: 'Doors', value: 'feature2' }],
      index: 0,
      label: 'Step 1',
      viewType: 'design',
    });
  });
});
describe('steps - setting next and back steps', () => {
  it('should set the next step if available', () => {
    mockUseConfiguratorContext.mockImplementation(() => ({
      setSelectedStep: mockSetSelectedStep,
      stepOptions,
      selectedStep: stepOptions[0],
    }));
    const { setNextStep } = useSteps();
    setNextStep && setNextStep();
    expect(mockSetSelectedStep).toHaveBeenCalledWith(stepOptions[1]);
  });
  it('should return undefined for set next step function if not available', () => {
    mockUseConfiguratorContext.mockImplementation(() => ({
      setSelectedStep: mockSetSelectedStep,
      stepOptions,
      selectedStep: stepOptions[1],
    }));
    const { setNextStep } = useSteps();
    expect(setNextStep).toBe(undefined);
  });
  it('should set the back step if available', () => {
    mockUseConfiguratorContext.mockImplementation(() => ({
      setSelectedStep: mockSetSelectedStep,
      stepOptions,
      selectedStep: stepOptions[1],
    }));
    const { setBackStep } = useSteps();
    setBackStep && setBackStep();
    expect(mockSetSelectedStep).toHaveBeenCalledWith(stepOptions[0]);
  });
  it('should return undefined for set back step function if not available', () => {
    mockUseConfiguratorContext.mockImplementation(() => ({
      setSelectedStep: mockSetSelectedStep,
      stepOptions,
      selectedStep: stepOptions[0],
    }));
    const { setBackStep } = useSteps();
    expect(setBackStep).toBe(undefined);
  });
});
