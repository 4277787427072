import { fireEvent, render, screen } from '@testing-library/react';
import { CirclePicker } from 'react-color';

import { ColorConfigurator } from 'pages/Models/ColorConfigurator';
import React from 'react';
import { changeMaterialColor } from 'services/sketchfab/color';
import { useConfiguratorContext } from 'context/ConfiguratorContext';

jest.mock('react-color', () => ({
  CirclePicker: jest.fn((props) => (
    <div
      data-testid={props['data-testid']}
      onClick={() => props.onChangeComplete({ rgb: { r: 10, g: 100, b: 222 }, hex: '#5a3826' })}
    />
  )),
}));
jest.mock('services/sketchfab/color');
jest.mock('context/ConfiguratorContext');
jest.mock('redux/useSelector', () => ({
  useSelector: jest.fn((fn) => fn()),
}));
jest.mock('redux/Textures', () => ({
  getAllTextures: () => [
    {
      id: '3c01323f-2e4e-4c77-ac37-80f9868e3a64',
      label: 'Painted',
      canChangeColor: true,
    },
    {
      id: '78abc-texture-alt',
      label: 'Painted Alt',
      canChangeColor: true,
      colorPaletteName: 'borderlands',
    },
  ],
}));
jest.mock('redux/Colors', () => ({
  getAllColors: () => [
    {
      id: '123',
      label: 'Nordan',
      value: 'nordan',
      colors: [
        { label: 'White', ralName: 'NCS0500N', hexCode: '#f1efeb' },
        { label: 'Brown', ralName: 'RAL8011', hexCode: '#5a3826' },
      ],
    },
    {
      id: '456',
      label: 'Borderlands',
      value: 'borderlands',
      colors: [
        { label: 'Red', ralName: 'I dunno', hexCode: '#ff0000' },
        { label: 'Orange', ralName: 'The other one', hexCode: '#ffff00' },
      ],
    },
  ],
}));
jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
}));
jest.mock('redux/CustomerModelConfig');

beforeEach(() => {
  // @ts-expect-error: mockImplementation
  useConfiguratorContext.mockImplementation(() => ({
    selectedMaterialName: 'Aluminium_Roof',
    selectedTextures: {
      Aluminium_Roof: { textureId: '3c01323f-2e4e-4c77-ac37-80f9868e3a64', channel: 'AlbedoPBR' },
    },
    selectedColors: {},
    setSelectedColors: jest.fn(),
  }));
  // @ts-expect-error: mockClear
  CirclePicker.mockClear();
});

const commonProps = {
  material: {
    value: 'Oak',
    label: 'Oak',
    canChangeColor: true,
  },
  sketchfabApiRef: { current: null },
};

describe('ColorConfigurator Component', () => {
  it('should have a color picker', () => {
    render(<ColorConfigurator {...commonProps} />);
    expect(screen.queryByTestId('color-picker')).toBeTruthy();
    expect(CirclePicker).toHaveBeenCalledWith(
      {
        color: { b: 255, g: 255, r: 255 },
        colors: ['#f1efeb', '#5a3826'],
        'data-testid': 'color-picker',
        onChangeComplete: expect.any(Function),
        onSwatchHover: expect.any(Function),
      },
      {},
    );
  });
  it('should use a different color palette if the texture specifies it', () => {
    // @ts-expect-error: mockImplementation
    useConfiguratorContext.mockImplementation(() => ({
      selectedMaterialName: 'Aluminium_Roof',
      selectedTextures: {
        Aluminium_Roof: { textureId: '78abc-texture-alt', channel: 'AlbedoPBR' },
      },
      selectedColors: {},
      setSelectedColors: jest.fn(),
    }));
    render(<ColorConfigurator {...commonProps} />);
    const button = screen.getByText('Select Colour');
    fireEvent.click(button);
    expect(CirclePicker).toHaveBeenCalledWith(
      {
        color: { b: 255, g: 255, r: 255 },
        colors: ['#ff0000', '#ffff00'],
        'data-testid': 'color-picker',
        onChangeComplete: expect.any(Function),
        onSwatchHover: expect.any(Function),
      },
      {},
    );
  });
  it('should call the sketchfab api to change color when set', () => {
    render(<ColorConfigurator {...commonProps} />);
    fireEvent.click(screen.getByTestId('color-picker'));
    expect(changeMaterialColor).toHaveBeenCalledWith(
      { current: null },
      'Aluminium_Roof',
      {
        r: 10,
        g: 100,
        b: 222,
      },
      undefined,
    );
  });
});
