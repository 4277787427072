import { fireEvent, render, screen } from '@testing-library/react';

import Home from 'pages/Home';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from 'services/i18n/intl';
import enMessages from 'translations/en.json';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

const mockPush = jest.fn();

const locales = {
  en: flattenMessages(enMessages),
};
const HomeIntlWrapped = () => (
  <IntlProvider locale="en" messages={locales.en}>
    <Home />
  </IntlProvider>
);

jest.mock('react-router-dom', () => ({
  useHistory: () => ({
    push: mockPush,
  }),
}));

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest.fn().mockImplementation((fn) => fn()),
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => mockCMSContent[id],
}));
jest.mock('redux/Models', () => ({
  getAllModels: () => [
    { id: '123', sketchfabModelId: 'abc', name: 'House 1', description: 'Big House' },
    {
      id: '456',
      sketchfabModelId: 'def',
      name: 'House 2',
      description: 'Small House',
      estimatedCost: {
        threeStarClosed: 60000,
      },
    },
  ],
}));

jest.mock('redux/ModelGroups', () => ({
  getAllModelGroups: () => [
    {
      id: '123',
      name: 'all',
      index: 1,
      title: 'Group with all the models',
      description: 'description of the model group',
      modelIds: ['123', '456'],
    },
  ],
}));

jest.mock('redux/ModelGroups/hooks', () => ({
  useFetchModelGroups: () => [false, jest.fn()],
}));

jest.mock('redux/Models/hooks', () => ({
  useFetchModels: () => [false, jest.fn()],
}));

jest.mock('redux/Textures/hooks', () => ({
  useFetchTextures: () => [false, jest.fn()],
}));

jest.mock('redux/CMS/hooks', () => ({
  useFetchCMSFields: () => [false, jest.fn()],
}));
jest.mock('Brand/configs', () => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  ...jest.requireActual('Brand/configs'),
  skipConstruction: true,
}));

describe('Home Page', () => {
  it('should go to /models/123 for House 1 if skip construction is true', () => {
    mockPush.mockClear();
    render(<HomeIntlWrapped />);
    fireEvent.click(screen.getByText('House 1'));
    expect(mockPush).toHaveBeenCalledWith('/models/123');
  });
  it('should go to /models/456 for House 2 if skip construction is true', () => {
    mockPush.mockClear();
    render(<HomeIntlWrapped />);
    fireEvent.click(screen.getByText('House 2'));
    expect(mockPush).toHaveBeenCalledWith('/models/456');
  });
});
