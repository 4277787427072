/* Language polyfills needed for IE11, Edge, Safari 12 & 13 support
https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
*/
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';

/* End of language polyfills */
import { PageContent, RootContainer } from 'components/Root/Root.style';
import React, { ReactNode } from 'react';

import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { IntlProvider } from 'react-intl';
import enMessages from 'translations/en.json';
import { flattenMessages } from 'services/i18n/intl';
import frMessages from 'translations/fr.json';
import { useResponsive } from 'pages/Models/useResponsive';

const locales = {
  fr: flattenMessages(frMessages),
  en: flattenMessages(enMessages),
};

interface Props {
  children: ReactNode;
}

export const Root: React.FunctionComponent<Props> = ({ children }) => {
  const { deviceSize } = useResponsive();

  return (
    <IntlProvider locale="en" messages={locales.en}>
      <RootContainer>
        <Header />
        <PageContent deviceSize={deviceSize}>{children}</PageContent>
        <Footer />
      </RootContainer>
    </IntlProvider>
  );
};
