import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IColorConfig } from './types';
import { NormalisedRedux } from 'redux/types';
import { normaliseArray } from 'redux/mappers';

export type ColorsState = NormalisedRedux<IColorConfig>;

export const initialState: ColorsState = {
  loading: false,
  byIds: {},
  allIds: [],
};

const Colors = createSlice({
  name: 'Colors',
  initialState,
  reducers: {
    setColors: (state, action: PayloadAction<IColorConfig[]>) => {
      const { byIds, allIds } = normaliseArray(action.payload);
      state.byIds = byIds;
      state.allIds = allIds;
    },
  },
});

export const { setColors } = Colors.actions;
export const { reducer } = Colors;
