import { colorUsage, getSpacing } from 'stylesheet';

import styled from 'styled-components';

export const HotspotSelectContainer = styled.div`
  width: 100%;
  background-color: ${colorUsage.contentBackground};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: ${getSpacing(1)};
  height: 97%;
`;

export const CameraButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: ${getSpacing(2)};
`;
