/* eslint max-lines: ["error", 165] */
import React from 'react';
import { render, screen } from '@testing-library/react';
import { useSelector } from 'redux/useSelector';

import { FinishesConfigurator } from 'pages/Models/FinishesConfigurator';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

jest.mock('redux/useSelector', () => ({
  useSelector: jest.fn((fn) => fn()),
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => mockCMSContent[id],
}));

jest.mock('redux/Models', () => ({
  getSelectedModel: () => ({
    id: '123',
    sketchfabModelId: 'abc',
    name: 'House 1',
    materials: [
      {
        value: 'Alu ',
        label: 'Alu',
      },
      {
        value: 'Aluminium_Doors',
        label: 'Aluminium Doors',
        associatedFeatures: [{ value: 'Window' }],
        canChangeColor: true,
        availableTextures: [
          {
            textureId: 'texture-123',
            channel: 'AlbedoPBR',
            default: true,
          },
          {
            textureId: 'texture-456',
            channel: 'AlbedoPBR',
          },
        ],
      },
      {
        value: 'Concrete',
        label: 'Concrete',
        canChangeColor: true,
        associatedFeatures: [{ value: 'Window' }],
        availableTextures: [
          {
            textureId: 'texture-123',
            channel: 'AlbedoPBR',
          },
          {
            textureId: 'texture-456',
            channel: 'AlbedoPBR',
            default: true,
          },
        ],
      },
      {
        value: 'Windows',
        label: 'Windows',
        canChangeColor: true,
        associatedFeatures: [{ value: 'Window' }],
        availableTextures: [
          {
            textureId: 'texture-123',
            channel: 'AlbedoPBR',
          },
        ],
      },
    ],
  }),
  setSelectedModelId: jest.fn(),
}));
jest.mock('redux/Textures', () => ({
  getAllTextures: () => [
    {
      id: 'texture-123',
      label: 'Texture 1',
      url: 'texture.jpeg',
      urlThumbnail: 'texturethumbnail.jpeg',
    },
    {
      id: 'texture-456',
      label: 'Texture 2',
      url: 'texture2.jpeg',
      urlThumbnail: 'texturethumbnail2.jpeg',
    },
  ],
}));
jest.mock('redux/Colors', () => ({
  getAllColors: () => [],
}));

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: () => jest.fn(),
}));

const commonProps = {
  sketchfabApiRef: { current: null },
};

describe('Right Side Bar Configurator Component Textures', () => {
  it('should display a placeholder text if there are no features on the model', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    useSelector.mockImplementation((fn) => fn('noFinishesForModelText'));
    render(<FinishesConfigurator {...commonProps} />);
    expect(screen.queryByText('Select Material')).toBeFalsy();
    expect(screen.queryByText('Currently there are no finishes on this model')).toBeTruthy();
  });
});
