import { apiRefMock, mockMaterials } from '__mocks__/sketchfabApi';
import { setTexture } from 'services/sketchfab/texture';
import { ITextureConfig } from 'redux/Textures/types';
import { waitFor } from '@testing-library/react';

describe('setTexture for multiple channels', () => {
  const texture: ITextureConfig = {
    id: 'texture-123',
    label: 'Texture 123',
    urlThumbnail: 'wood.jpeg',
    configs: [
      {
        channel: 'AlbedoPBR',
        url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/wood2.jpeg',
      },
    ],
  };

  it('should update multiple textures if passed in', async () => {
    apiRefMock.current.setMaterial.mockClear();
    setTexture(
      apiRefMock,
      'Cladding',
      {
        ...texture,
        configs: [
          {
            channel: 'NormalMap',
            url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/wood37.jpeg',
          },
          {
            channel: 'SpecularPBR',
            url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/wood89.jpeg',
          },
        ],
      },
      null,
    );
    await waitFor(() => {
      expect(apiRefMock.current.addTexture).toHaveBeenCalledWith(
        'https://publicsketchfabstaging.blob.core.windows.net/textures/wood37.jpeg',
        expect.any(Function),
      );
      expect(apiRefMock.current.addTexture).toHaveBeenCalledWith(
        'https://publicsketchfabstaging.blob.core.windows.net/textures/wood89.jpeg',
        expect.any(Function),
      );
      expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith({
        ...mockMaterials[0],
        channels: {
          ...mockMaterials[0].channels,
          NormalMap: {
            ...mockMaterials[0].channels.NormalMap,
            texture: {
              magFilter: 'LINEAR',
              minFilter: 'LINEAR_MIPMAP_LINEAR',
              wrapS: 'REPEAT',
              wrapT: 'REPEAT',
              textureTarget: 'TEXTURE_2D',
              internalFormat: 'RGB',
              texCoordUnit: 0,
              uid: 'mockTextureUid',
            },
          },
          SpecularPBR: {
            ...mockMaterials[0].channels.NormalMap,
            texture: {
              magFilter: 'LINEAR',
              minFilter: 'LINEAR_MIPMAP_LINEAR',
              wrapS: 'REPEAT',
              wrapT: 'REPEAT',
              textureTarget: 'TEXTURE_2D',
              internalFormat: 'RGB',
              texCoordUnit: 0,
              uid: 'mockTextureUid',
            },
          },
        },
      });
    });
  });
});
