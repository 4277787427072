/* eslint-disable complexity */
/* eslint-disable max-depth */
import * as util from 'util';
import { convertColor, hexToRgb } from 'services/sketchfab/color';
import { ITextureConfig } from 'redux/Textures/types';
import { ICustomerModelConfig, ICustomerMaterialConfig } from 'redux/CustomerModelConfig/types';
import { Color } from 'redux/Colors/types';

export const setTexture = async (
  apiRef: React.MutableRefObject<null | ISketchfabAPI>,
  materialName: string | undefined,
  texture: ITextureConfig,
  color: Color | null | undefined,
): Promise<void> => {
  apiRef.current?.getMaterialList(async (err, materials: IMaterial[]) => {
    const addTexture = apiRef.current?.addTexture && util.promisify(apiRef.current?.addTexture);
    const material = materials.find((material) => material.name === materialName);
    if (material && addTexture) {
      const updatedMaterial = { ...material };
      // Need for loop to allow async/await on addTexture
      for (let i = 0; i < texture.configs.length; i++) {
        const config = texture.configs[i];
        if (config.url) {
          const textureUid = await addTexture(config.url);
          if (material && textureUid) {
            updatedMaterial.channels[config.channel].enable = true;
            const textureSettings = {
              magFilter: 'LINEAR',
              minFilter: 'LINEAR_MIPMAP_LINEAR',
              wrapS: 'REPEAT',
              wrapT: 'REPEAT',
              textureTarget: 'TEXTURE_2D',
              internalFormat: 'RGB',
              texCoordUnit: 0,
              uid: textureUid,
            };
            updatedMaterial.channels[config.channel].factor =
              config.factor !== undefined ? config.factor : 1;
            updatedMaterial.channels[config.channel].texture = textureSettings;
            if (color) {
              updatedMaterial.channels[config.channel].color = convertColor(
                hexToRgb(color.hexCode),
              );
            }
            apiRef.current?.setUVScale(
              updatedMaterial,
              config.channel,
              config.uvScale?.scaleX || 1,
              config.uvScale?.scaleY || 1,
            );
          }
        } else {
          updatedMaterial.channels[config.channel].texture = null;
          if (color) {
            updatedMaterial.channels[config.channel].color = convertColor(hexToRgb(color.hexCode));
          } else {
            updatedMaterial.channels[config.channel].color = [1, 1, 1];
          }
        }
      }
      apiRef.current?.setMaterial(updatedMaterial);
    }
  });
};

export const setAllCustomerTextures = (
  api: ISketchfabAPI,
  customerModelConfig: ICustomerModelConfig,
  textures: ITextureConfig[],
): void => {
  Object.keys(customerModelConfig.materials).forEach((materialKey: string) => {
    const materialConfig: ICustomerMaterialConfig = customerModelConfig.materials[materialKey];
    const texture = textures.find((texture) => texture.id === materialConfig.texture.textureId);
    if (texture) {
      setTexture({ current: api }, materialConfig.value, texture, materialConfig.texture.color);
    }
  });
};
