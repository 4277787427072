import { fireEvent, render, screen } from '@testing-library/react';

import { FeatureConfigurator } from 'pages/Models/FeatureConfigurator';
import React from 'react';
import { apiRefMock } from '__mocks__/sketchfabApi';
import { setSavedCustomerFeatures } from 'redux/CustomerModelConfig';
import { showVariant } from 'services/sketchfab/variant';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

const commonProps = {
  sketchfabApiRef: apiRefMock,
};

jest.mock('redux/Models', () => ({
  getSelectedModel: () => ({
    id: '123',
    sketchfabModelId: 'abc',
    backgroundImageId: 'mockBackgroundImageId',
    name: 'House 1',
    features: [],
  }),
}));
jest.mock('context/ConfiguratorContext', () => ({
  useConfiguratorContext: jest.fn(() => ({
    replaceSelectedVariant: jest.fn(),
    selectedFeature: {
      value: 'door',
      label: 'Door',
      variants: [
        {
          label: 'Door 1',
          value: 'door1',
          urlThumbnail: 'door1.jpeg',
        },
        {
          label: 'Door 2',
          value: 'door2',
          urlThumbnail: 'door2.jpeg',
        },
      ],
    },
    setSelectedFeature: jest.fn(),
    selectedStep: {
      viewType: 'design',
    },
  })),
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => mockCMSContent[id],
}));

jest.mock('services/sketchfab/variant');

jest.mock('redux/CustomerModelConfig');

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest.fn((fn) => fn()),
}));

describe('FeatureConfigurator Component Dispatch', () => {
  it('should call the showVariant function when I select an image', () => {
    render(<FeatureConfigurator {...commonProps} />);
    fireEvent.click(screen.getByAltText('Door 1'));
    expect(showVariant).toHaveBeenCalledWith(
      apiRefMock,
      'door1',
      [
        {
          label: 'Door 1',
          value: 'door1',
          urlThumbnail: 'door1.jpeg',
        },
        {
          label: 'Door 2',
          value: 'door2',
          urlThumbnail: 'door2.jpeg',
        },
      ],
      undefined,
    );
  });
  it('should dispatch the setSelectedCustomerFeatures with the correct values', () => {
    render(<FeatureConfigurator {...commonProps} />);
    fireEvent.click(screen.getByAltText('Door 1'));
    expect(setSavedCustomerFeatures).toHaveBeenCalledWith({ value: 'door', variantValue: 'door1' });
  });
});
