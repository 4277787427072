import { Button } from 'components/Button';
import Modal from 'react-modal';
import React from 'react';
import { getSpacing } from 'stylesheet';
import styled from 'styled-components';

const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: getSpacing(2),
    maxHeight: '90vh',
  },
  overlay: {
    zIndex: 1000,
  },
};

const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`;

const ModalContainer = styled.div`
  flex-direction: column;
  max-width: ${getSpacing(180)};
`;

type IProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  ExtraButtonsComponent?: React.FunctionComponent;
};

export const GenericModal: React.FunctionComponent<IProps> = ({
  showModal,
  setShowModal,
  children,
  ExtraButtonsComponent,
}) => {
  return (
    <Modal style={customStyles} isOpen={showModal} ariaHideApp={false}>
      <ModalContainer>
        <ButtonContainer>
          {ExtraButtonsComponent && <ExtraButtonsComponent />}
          <Button onClick={() => setShowModal(false)}>Close</Button>
        </ButtonContainer>
        {children}
      </ModalContainer>
    </Modal>
  );
};
