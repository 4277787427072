import { fireEvent } from '@testing-library/react';

export const selectDropdown = (input: HTMLElement): void => {
  fireEvent.focus(input);
  fireEvent.keyDown(input, {
    key: 'ArrowDown',
    keyCode: 40,
    code: 40,
  });
};
