/* eslint max-lines: ["error", 120] */
import React, { useEffect, useCallback } from 'react';
import { ColorConfigurator } from './ColorConfigurator';
import { IMaterialConfig } from 'redux/Models/types';
import { Selector } from 'components/Selector';
import { Text } from 'styling/Typography.style';
import { TextureConfigurator } from './TextureConfigurator';
import { getSelectedModel } from 'redux/Models';
import { useConfiguratorContext } from 'context/ConfiguratorContext';
import { useSelector } from 'redux/useSelector';
import CollapsibleConfiguratorContainer from 'components/ModelViewer/CollapsibleConfiguratorContainer';
import { getPlaceholderText } from './FinishesConfigurator.helpers';
import { getCMSFieldById } from 'redux/CMS';
import { HelpLinks } from './HelpLinks';
import { Spacer } from 'components/Spacer';

interface IProps {
  sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI>;
}
export const FinishesConfigurator: React.FunctionComponent<IProps> = (props) => {
  const selectedModel = useSelector(getSelectedModel);
  const {
    setSelectedMaterialName,
    selectedVariants,
    selectedFeatureName,
    expandedScreen,
    setExpandedScreen,
    setSelectedTextures,
  } = useConfiguratorContext();
  const [selectedMaterial, setSelectedMaterial] = React.useState<IMaterialConfig | null>(null);
  const onMaterialChange = useCallback(
    (value: IMaterialConfig | null) => {
      setSelectedMaterial(value);
      setSelectedMaterialName(value?.value ? value.value : null);
    },
    [setSelectedMaterialName],
  );
  const finishesConfiguratorTitle = useSelector(getCMSFieldById('finishesConfiguratorTitle'));
  const noFinishesForFeatureText = useSelector(getCMSFieldById('noFinishesForFeatureText'));
  const noFinishesForModelText = useSelector(getCMSFieldById('noFinishesForModelText'));
  const selectFeatureText = useSelector(getCMSFieldById('selectFeatureText'));
  const helpText = useSelector(getCMSFieldById('HeaderHelpText'));
  const materialOptions: IMaterialConfig[] | undefined = selectedModel?.materials.reduce(
    (acc: IMaterialConfig[], material) => {
      let allowedFeature = false;
      let allowedVariant = true;
      material.associatedFeatures?.forEach((feature) => {
        if (selectedFeatureName === feature.value) {
          allowedFeature = true;
          return;
        }
      });
      if (material.associatedVariants && allowedFeature) {
        allowedVariant = false;
        material.associatedVariants.forEach((variant) => {
          if (selectedVariants.includes(variant.value)) {
            allowedVariant = true;
            return;
          }
        });
      }
      if (allowedFeature && allowedVariant) {
        return [...acc, material];
      }
      return acc;
    },
    [],
  );
  useEffect(() => {
    if (materialOptions?.length === 1) {
      onMaterialChange(materialOptions[0]);
    }
  }, [onMaterialChange, selectedFeatureName, materialOptions]);
  useEffect(() => {
    setSelectedTextures({});
  }, [selectedVariants, selectedFeatureName, setSelectedTextures, selectedMaterial]);
  useEffect(() => {
    setSelectedMaterial(null);
  }, [selectedVariants, selectedFeatureName]);
  return (
    <CollapsibleConfiguratorContainer
      expandedScreen={expandedScreen}
      setExpandedScreen={setExpandedScreen}
      title={finishesConfiguratorTitle}
    >
      {materialOptions && materialOptions.length > 0 && selectedFeatureName ? (
        <>
          {materialOptions.length > 1 && (
            <Selector<IMaterialConfig>
              setSelectedOption={onMaterialChange}
              options={materialOptions}
              placeholder="Select Material"
              selectedOption={selectedMaterial}
              getOptionValue={(option) => option.label}
            />
          )}
          <TextureConfigurator
            material={selectedMaterial}
            sketchfabApiRef={props.sketchfabApiRef}
          />
          <ColorConfigurator sketchfabApiRef={props.sketchfabApiRef} />
        </>
      ) : (
        <Text>
          {getPlaceholderText({
            materialOptions,
            selectedFeatureName,
            selectedModel,
            noFinishesForFeatureText,
            noFinishesForModelText,
            selectFeatureText,
          })}
        </Text>
      )}
      <Spacer.Flex />
      <HelpLinks helpText={helpText} showWhatIsIncluded={true} />
    </CollapsibleConfiguratorContainer>
  );
};
