import { apiRefMock, mockMaterials } from '__mocks__/sketchfabApi';
import { setTexture } from 'services/sketchfab/texture';
import { ITextureConfig } from 'redux/Textures/types';

describe('setRemoveTexture', () => {
  const texture: ITextureConfig = {
    id: 'texture-123',
    label: 'Texture 123',
    urlThumbnail: 'wood.jpeg',
    configs: [
      {
        channel: 'AlbedoPBR',
      },
    ],
  };
  it('should remove the texture and set the color to white if the material channel is AlbedoPBR', () => {
    apiRefMock.current.setMaterial.mockClear();
    setTexture(apiRefMock, 'Cladding', texture, null);
    expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith({
      ...mockMaterials[0],
      channels: {
        ...mockMaterials[0].channels,
        AlbedoPBR: {
          ...mockMaterials[0].channels.AlbedoPBR,
          texture: null,
          color: [1, 1, 1],
        },
      },
    });
  });
  it('should remove the texture and set the color to red if passed through', () => {
    apiRefMock.current.setMaterial.mockClear();
    setTexture(apiRefMock, 'Cladding', texture, { label: '', ralName: '', hexCode: '#ff0000' });
    expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith({
      ...mockMaterials[0],
      channels: {
        ...mockMaterials[0].channels,
        AlbedoPBR: {
          ...mockMaterials[0].channels.AlbedoPBR,
          texture: null,
          color: [0.99609375, 0, 0],
        },
      },
    });
  });
  it('should remove the texture and set the color to white if the material channel is DiffusePBR', () => {
    apiRefMock.current.setMaterial.mockClear();
    setTexture(
      apiRefMock,
      'Cladding',
      {
        ...texture,
        configs: [{ ...texture.configs[0], channel: 'DiffusePBR' }],
      },
      null,
    );
    expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith({
      ...mockMaterials[0],
      channels: {
        ...mockMaterials[0].channels,
        DiffusePBR: {
          ...mockMaterials[0].channels.DiffusePBR,
          texture: null,
          color: [1, 1, 1],
        },
      },
    });
  });
  it('should do nothing if a bad material name is passed in ', () => {
    apiRefMock.current.setMaterial.mockClear();
    setTexture(apiRefMock, 'Made up', texture, null);
    expect(apiRefMock.current.setMaterial).not.toHaveBeenCalled();
  });
});
