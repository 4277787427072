import React from 'react';
import { fireEvent, render, screen } from '@testing-library/react';
import { ModelStepperButtons } from '../ModelStepperButtons';
import { useSteps } from '../steps';

const mockSetNextStep = jest.fn();
const mockSetBackStep = jest.fn();
const mockUseSteps = useSteps as jest.Mock;

jest.mock('../steps', () => ({
  useSteps: jest.fn(() => ({ setNextStep: mockSetNextStep, setBackStep: mockSetBackStep })),
}));

describe('ModelStepperButtons', () => {
  it('should have a next button that sets the next step', () => {
    render(<ModelStepperButtons />);
    expect(screen.queryByText('Next')).toBeTruthy();
    expect(screen.getByText('Next').getAttribute('disabled')).toBe(null);
    fireEvent.click(screen.getByText('Next'));
    expect(mockSetNextStep).toHaveBeenCalled();
  });
  it('should have a back button that sets the back step', () => {
    render(<ModelStepperButtons />);
    expect(screen.queryByText('Back')).toBeTruthy();
    expect(screen.getByText('Back').getAttribute('disabled')).toBe(null);
    fireEvent.click(screen.getByText('Back'));
    expect(mockSetBackStep).toHaveBeenCalled();
  });
  it('should disable the buttons if the functions are not defined', () => {
    mockUseSteps.mockImplementationOnce(() => ({ setNextStep: undefined, setBackStep: undefined }));
    mockUseSteps.mockImplementationOnce(() => ({ setNextStep: undefined, setBackStep: undefined }));
    render(<ModelStepperButtons />);
    expect(screen.getByText('Next').getAttribute('disabled')).not.toBe(null);
    expect(screen.getByText('Back').getAttribute('disabled')).not.toBe(null);
  });
});
