import { colorUsage, fontSize, getSpacing } from 'stylesheet';
import styled from 'styled-components';
import { ActionText, Text } from 'styling/Typography.style';

export const Asterisk = styled(Text)`
  color: ${colorUsage.asteriskText};
`;
export const HereText = styled(ActionText)`
  margin-left: ${getSpacing(0.5)};
  font-size: ${fontSize.XSmall};
`;
