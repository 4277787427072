import React from 'react';
import Switch from 'react-switch';
import { colorUsage, fontSize } from 'stylesheet';
import { SwitchContainer } from './FeatureConfigurator.style';
import { Label } from 'styling/Typography.style';
import styled from 'styled-components';

type FeatureConfiguratorSwitchProps = {
  moveWithSelection: boolean;
  setMoveWithSelection: (moveWithSelection: boolean) => void;
};
const StyledLabel = styled(Label)`
  font-size: ${fontSize.XSmall};
  color: ${colorUsage.primaryTextColor};
  position: relative;
  top: -4px;
  right: 0;
`;

export const FeatureConfiguratorSwitch: React.FC<FeatureConfiguratorSwitchProps> = ({
  moveWithSelection,
  setMoveWithSelection,
}) => {
  return (
    <SwitchContainer>
      <Switch
        checked={moveWithSelection}
        onChange={() => setMoveWithSelection(!moveWithSelection)}
        onColor={colorUsage.toggleSecondary}
        onHandleColor={colorUsage.togglePrimary}
        handleDiameter={18}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={12}
        width={29}
        className="react-switch"
        id="material-switch"
      />
      <StyledLabel>Move with selection</StyledLabel>
    </SwitchContainer>
  );
};
