import { useConfiguratorContext } from 'context/ConfiguratorContext';
import { IFeatureConfig, IModel, IStep, ModelView } from 'redux/Models/types';

interface IUseSteps {
  setStepsOnLoad: (selectedModel: IModel) => void;
  setNextStep: undefined | (() => void);
  setBackStep: undefined | (() => void);
}

export const useSteps = (): IUseSteps => {
  const { setStepOptions, setSelectedStep, selectedStep, stepOptions } = useConfiguratorContext();

  const setStepsOnLoad = (selectedModel: IModel): void => {
    const defaultDesignStep: IStep = {
      label: 'Design',
      index: 0,
      associatedFeatures: selectedModel.features ? selectedModel.features : [],
      viewType: ModelView.DESIGN,
    };
    const designSteps: IStep[] = [];
    if (selectedModel.steps && selectedModel.steps.length > 0) {
      selectedModel.steps.forEach((step, index) => {
        const associatedFeatures: IFeatureConfig[] = [];
        step.associatedFeatures.forEach((associatedFeature) => {
          const feature = selectedModel.features?.find(
            (feature) => feature.value === associatedFeature.value,
          );
          if (feature) {
            associatedFeatures.push(feature);
          }
        });
        designSteps.push({
          label: step.label,
          index,
          associatedFeatures,
          viewType: ModelView.DESIGN,
        });
      });
    } else {
      designSteps.push(defaultDesignStep);
    }
    const exploreStep: IStep = {
      label: 'Explore',
      index: designSteps.length,
      associatedFeatures: [],
      viewType: ModelView.EXPLORE,
    };
    const stepOptions = [...designSteps, exploreStep];
    setStepOptions(stepOptions);
    setSelectedStep(stepOptions[0]);
  };

  const index = selectedStep?.index;
  const setNextStep =
    index === undefined || index === null || index >= stepOptions.length - 1
      ? undefined
      : () => setSelectedStep(stepOptions[index + 1]);
  const setBackStep =
    index === undefined || index === null || index <= 0
      ? undefined
      : () => setSelectedStep(stepOptions[index - 1]);

  return { setStepsOnLoad, setNextStep, setBackStep };
};
