/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.ts, reducers wouldn't be hot reloadable.
 */

import { RootState } from 'redux/types';
import { combineReducers } from 'redux';
import { reducer as colors } from 'redux/Colors';
import { reducer as customerModelConfig } from 'redux/CustomerModelConfig';
import { reducer as models } from 'redux/Models';
import { reducer as textures } from 'redux/Textures';
import { reducer as CMS } from 'redux/CMS';
import { reducer as modelGroups } from 'redux/ModelGroups';

export const rootReducer = combineReducers<RootState>({
  colors,
  CMS,
  customerModelConfig,
  models,
  textures,
  modelGroups,
});
