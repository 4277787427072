import { AsyncFnReturn } from 'react-use/lib/useAsync';
import { ModelGroup } from './types';
import { client } from 'services/networking/client';
import { useDispatch } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { setModelGroups } from './slice';

export const useFetchModelGroups = (): AsyncFnReturn<(...args: unknown[]) => Promise<void>> => {
  const dispatch = useDispatch();
  return useTypedAsyncFn<unknown>(async () => {
    const modelGroups: ModelGroup[] = await client.get('/api/getmodelGroups');
    dispatch(setModelGroups(modelGroups));
  }, []);
};
