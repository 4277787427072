import { getSpacing } from 'stylesheet';
import styled from 'styled-components';
import { SubTitle } from 'styling/Typography.style';

export const SwitchContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
`;

export const CameraButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: ${getSpacing(2)};
`;

export const StyledHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${getSpacing(2)};
`;

export const FeatureSubTitle = styled(SubTitle)`
  margin-top: ${getSpacing(2)};
`;
