import {
  CustomerModelConfigState,
  initialState,
  reducer,
  setSavedCustomerMaterials,
} from 'redux/CustomerModelConfig';
import { ICustomerMaterialConfig } from '../types';

describe('setSavedCustomerMaterials', () => {
  it('Should set materials with value as object key', () => {
    const mockPayload: ICustomerMaterialConfig = {
      value: 'Aluminium_Doors',
      texture: { textureId: '123' },
    };
    const action = setSavedCustomerMaterials(mockPayload);
    const expectedState = {
      ...initialState,
      materials: {
        Aluminium_Doors: {
          value: 'Aluminium_Doors',
          texture: { textureId: '123' },
        },
      },
    };
    expect(reducer(initialState, action)).toEqual(expectedState);
  });
});
it('Should set 2 materials if 2 have been selected with textures', () => {
  const mockPayloadDoor: ICustomerMaterialConfig = {
    value: 'Aluminium_Doors',
    texture: { textureId: '123' },
  };
  const mockPayloadCladding: ICustomerMaterialConfig = {
    value: 'Cladding',
    texture: { textureId: '123' },
  };
  const selectDoor = setSavedCustomerMaterials(mockPayloadDoor);
  const expectedState1: CustomerModelConfigState = {
    ...initialState,
    materials: {
      Aluminium_Doors: {
        value: 'Aluminium_Doors',
        texture: { textureId: '123' },
      },
    },
  };
  expect(reducer(initialState, selectDoor)).toEqual(expectedState1);
  const selectCladding = setSavedCustomerMaterials(mockPayloadCladding);
  const expectedState2 = {
    ...initialState,
    materials: {
      Aluminium_Doors: {
        value: 'Aluminium_Doors',
        texture: { textureId: '123' },
      },
      Cladding: {
        value: 'Cladding',
        texture: { textureId: '123' },
      },
    },
  };
  expect(reducer(expectedState1, selectCladding)).toEqual(expectedState2);
});
it('Should return 1 material if 2 textures of the same feature have been selected', () => {
  const mockPayloadCladding1: ICustomerMaterialConfig = {
    value: 'Cladding',
    texture: { textureId: '123' },
  };
  const mockPayloadCladding2: ICustomerMaterialConfig = {
    value: 'Cladding',
    texture: { textureId: '456' },
  };
  const selectCladding1 = setSavedCustomerMaterials(mockPayloadCladding1);
  const expectedState1: CustomerModelConfigState = {
    ...initialState,
    materials: {
      Cladding: {
        value: 'Cladding',
        texture: { textureId: '123' },
      },
    },
  };
  expect(reducer(initialState, selectCladding1)).toEqual(expectedState1);
  const selectCladding2 = setSavedCustomerMaterials(mockPayloadCladding2);
  const expectedState2 = {
    ...initialState,
    materials: {
      Cladding: {
        value: 'Cladding',
        texture: { textureId: '456' },
      },
    },
  };
  expect(reducer(expectedState1, selectCladding2)).toEqual(expectedState2);
});
