import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router';
import { Loader } from 'components/Loader';

const Home = lazy(() => import('pages/Home'));
const Construction = lazy(() => import('pages/Construction'));
const Models = lazy(() => import('pages/Models'));
const ShortLink = lazy(() => import('pages/ShortLink'));

export const PATHS = {
  HOME: '/',
  CONSTRUCTION: (modelId: string): string => `/construction/${modelId}`,
  MODELS: (modelId: string, customerModelConfigId?: string): string => {
    if (!customerModelConfigId) {
      return `/models/${modelId}`;
    } else {
      return `/models/${modelId}/${customerModelConfigId}`;
    }
  },
  SHORT_LINK: (shortLink: string): string => `/short/${shortLink}`,
};

export const Routes = (): JSX.Element => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={PATHS.HOME} component={Home} />
        <Route exact path={PATHS.CONSTRUCTION(':modelId')} component={Construction} />
        <Route
          exact
          path={PATHS.MODELS(':modelId', ':customerModelConfigId?')}
          component={Models}
        />
        `
        <Route exact path={PATHS.SHORT_LINK(':shortLink')} component={ShortLink} />`
      </Switch>
    </Suspense>
  );
};
