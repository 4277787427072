/* eslint max-lines: ["error", 156] */
import { fireEvent, render, screen } from '@testing-library/react';
import Models from 'pages/Models';
import React from 'react';
import { setDefaultCustomerModelConfig } from 'redux/CustomerModelConfig';
import { setSelectedModelId } from 'redux/Models';

const mockPush = jest.fn();

jest.mock('react-router-dom', () => ({
  useHistory: () => ({
    push: mockPush,
  }),
  useParams: jest.fn(() => ({ modelId: '123', customerModelConfigId: '456' })),
  Prompt: jest.fn(() => <div />),
}));

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest.fn((fn) => fn()),
}));

jest.mock('redux/CMS/selectors', () => ({
  getCMSFieldById: () => () => 'mock',
}));

jest.mock('components/ModelViewer', () => ({ ModelViewer: jest.fn(() => null) }));

const mockFetchModel = jest.fn();
jest.mock('redux/Models/hooks', () => ({
  useFetchModel: () => [false, mockFetchModel],
}));

const mockFetchColors = jest.fn();
jest.mock('redux/Colors/hooks', () => ({
  useFetchColors: () => [false, mockFetchColors],
}));

const mockFetchTextures = jest.fn();
jest.mock('redux/Textures/hooks', () => ({
  useFetchTextures: () => [false, mockFetchTextures],
}));

const mockFetchCMSFields = jest.fn();
jest.mock('redux/CMS/hooks', () => ({
  useFetchCMSFields: () => [false, mockFetchCMSFields],
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => {
    switch (id) {
      case 'featureConfiguratorTitle':
        return 'Features';
      case 'finishesConfiguratorTitle':
        return 'Finishes';
      default:
        return 'blah';
    }
  },
}));

const mockFetchCustomerModelConfig = jest.fn();
jest.mock('redux/CustomerModelConfig/hooks', () => ({
  useFetchCustomerModelConfig: () => [false, mockFetchCustomerModelConfig],
  useCreateCustomerModelConfig: () => [false, {}],
  useSendCustomerModelEmail: () => [false, {}],
  useSendCustomerQuoteEmail: () => [false, {}],
}));

jest.mock('redux/Models', () => ({
  getSelectedModel: () => ({
    id: '123',
    sketchfabModelId: 'abc',
    backgroundImageId: 'mockBackgroundImageId',
    name: 'House 1',
    materials: [
      {
        value: 'Alu ',
        label: 'Alu',
      },
      {
        value: 'Aluminium_Doors',
        label: 'Aluminium Doors',
      },
    ],
    hotspots: [],
  }),
  setSelectedModelId: jest.fn(),
}));
jest.mock('redux/Textures', () => ({
  getAllTextures: () => [],
}));
jest.mock('redux/CustomerModelConfig', () => ({
  getCustomerModelConfig: () => ({
    modelId: '1ac',
    features: {},
    materials: {},
    construction: 'threeStarClosed',
  }),
  getSelectedConstruction: () => 'threeStarClosed',
  setDefaultCustomerModelConfig: jest.fn(),
  setCustomerModelConfig: jest.fn(),
}));

beforeEach(() => {
  mockFetchCustomerModelConfig.mockClear();
  // @ts-expect-error: mockClear
  setDefaultCustomerModelConfig.mockClear();
  // @ts-expect-error: mockClear
  setSelectedModelId.mockClear();
});

describe('Models Page Stepper', () => {
  it('should have a stepper with design and explore', () => {
    render(<Models />);
    expect(screen.queryByText('Design')).toBeTruthy();
    expect(screen.queryByText('Explore')).toBeTruthy();
  });
  it('should have a Model Name Title in design and explore mode', () => {
    render(<Models />);
    expect(screen.queryByText('House 1')).toBeTruthy();
    fireEvent.click(screen.getByText('Explore'));
    expect(screen.queryByText('House 1')).toBeTruthy();
  });
  it('should have some CTAs in both modes, Explore mode only has reset camera on select of a feature', () => {
    render(<Models />);
    expect(screen.queryByText('Save')).toBeTruthy();
    expect(screen.queryByText('Request Quote')).toBeTruthy();
    expect(screen.queryByText('Take Photo')).toBeTruthy();
    expect(screen.queryByText('Reset Camera')).toBeTruthy();
    fireEvent.click(screen.getByText('Explore'));
    expect(screen.queryByText('Save')).toBeTruthy();
    expect(screen.queryByText('Request Quote')).toBeTruthy();
    expect(screen.queryByText('Take Photo')).toBeTruthy();
  });
  it('should have the titles from the 2 sidebars in Design mode only', () => {
    render(<Models />);
    expect(screen.queryByText('Features')).toBeTruthy();
    expect(screen.queryByText('Finishes')).toBeTruthy();
    fireEvent.click(screen.getByText('Explore'));
    expect(screen.queryByText('Features')).toBeFalsy();
    expect(screen.queryByText('Finishes')).toBeFalsy();
  });
  it('should have next and back buttons to swap views', () => {
    render(<Models />);
    expect(screen.queryByText('Features')).toBeTruthy();
    expect(screen.queryByText('Finishes')).toBeTruthy();
    fireEvent.click(screen.getByText('Next'));
    expect(screen.queryByText('Features')).toBeFalsy();
    expect(screen.queryByText('Finishes')).toBeFalsy();
    fireEvent.click(screen.getByText('Back'));
    expect(screen.queryByText('Features')).toBeTruthy();
    expect(screen.queryByText('Finishes')).toBeTruthy();
  });
});
