import { render, screen } from '@testing-library/react';
import React from 'react';
import { HTMLText } from './HTMLText';

describe('unit tests for html text component', () => {
  it('renders good html code', () => {
    render(<HTMLText textToSet={'<p>some text</p>'} heightUnset={true} />);
    expect(screen.getByText('some text')).toBeTruthy();
  });
  it('cleans and renders bad html code', () => {
    render(
      <HTMLText
        textToSet={
          '<p data-testid="sanitized">HELLO<iframe//src=JavScript:alert&lpar;1)></ifrAMe><br>goodbye</p>'
        }
        heightUnset={true}
      />,
    );
    expect(screen.getByTestId('sanitized')).toBeTruthy();
    expect(screen.getByTestId('sanitized').innerHTML).toEqual('HELLO<br>goodbye');
  });
});
