import { Selector } from 'components/Selector';
import React from 'react';
import { InputSubTitle } from 'styling/Typography.style';
import { ConfigurableFieldsType, ConfigurableFieldTypes } from './RequestAQuoteForm';
import { InputRow } from 'components/InputRow';
import { Field, FormikProps } from 'formik';
import { RequestAQuoteFormValues } from 'redux/Models/types';
import { Spacer } from 'components/Spacer';

type ConfigurableFieldInputsProps = {
  configurableFields: ConfigurableFieldsType;
  formikProps: FormikProps<RequestAQuoteFormValues>;
};

export const ConfigurableFieldInputs: React.FC<ConfigurableFieldInputsProps> = ({
  configurableFields,
  formikProps,
}) => {
  if (configurableFields.length === 0) {
    return null;
  } else {
    return (
      <>
        {configurableFields.map((field) => {
          if (field.type === ConfigurableFieldTypes.DROPDOWN) {
            return (
              <div key={field.value}>
                <InputSubTitle>{field.label}</InputSubTitle>
                <Selector<{ value: string; label: string }>
                  setSelectedOption={(value) =>
                    formikProps.setFieldValue(field.value, value?.value)
                  }
                  options={field.options.map((type: string) => ({
                    value: type,
                    label: type,
                  }))}
                  selectedOption={{
                    value: formikProps.values[field.value],
                    label: formikProps.values[field.value],
                  }}
                />
                <Spacer.Column numberOfSpaces={4} />
              </div>
            );
          } else if (field.type === ConfigurableFieldTypes.FREE_TEXT) {
            return (
              <Field
                key={field.value}
                type="text"
                name={field.value}
                label={field.label}
                component={InputRow}
                placeholder={field.label}
                error={formikProps.touched[field.value] && formikProps.errors[field.value]}
              />
            );
          } else {
            return <></>;
          }
        })}
      </>
    );
  }
};
