import {
  CustomerModelConfigState,
  initialState,
  reducer,
  setDefaultCustomerModelConfig,
} from 'redux/CustomerModelConfig';

describe('CustomerModelConfig', () => {
  const stateWithConfig: CustomerModelConfigState = {
    ...initialState,
    modelId: 'abc',
    features: {
      Door: {
        value: 'Door',
        variantValue: 'front_door2',
      },
    },
    materials: {
      Cladding: {
        value: 'Cladding',
        texture: {
          textureId: '48948',
        },
      },
    },
  };
  describe('setDefaultCustomerModelConfig', () => {
    it('should set the config to have an id and the default variants', () => {
      const action = setDefaultCustomerModelConfig({
        modelId: stateWithConfig.modelId,
        features: stateWithConfig.features,
        materials: stateWithConfig.materials,
        construction: stateWithConfig.construction,
      });
      const expectedState = {
        ...initialState,
        modelId: 'abc',
        features: {
          Door: {
            value: 'Door',
            variantValue: 'front_door2',
          },
        },
      };
      expect(reducer(stateWithConfig, action)).toEqual(expectedState);
    });
    it('should set the config to have an id and no features if there are none', () => {
      const action = setDefaultCustomerModelConfig({
        modelId: '123',
        features: {},
        materials: {},
        construction: null,
      });
      const expectedState = {
        ...initialState,
        modelId: '123',
      };
      expect(reducer(stateWithConfig, action)).toEqual(expectedState);
    });
    it('should reset an existing config to initial state', () => {
      const action = setDefaultCustomerModelConfig({
        modelId: null,
        features: {},
        materials: {},
        construction: null,
      });
      expect(reducer(stateWithConfig, action)).toEqual(initialState);
    });
  });
});
