export const mockCMSContent: Record<string, string> = {
  pricesFromText: 'Prices from*:',
  selectFeaturePlaceholder: 'Select Feature',
  featureConfiguratorTitle: 'Features',
  CopyrightNameText: 'Scotframe Timber Frame Homes',
  PrivacyStatementLink: 'https://www.scotframe.co.uk/scotframe/privacy-statement/',
  WhatIsIncludedLabel: 'What is included?',
  selectFeatureText: 'Please select a feature to see the finishes',
  noFinishesForFeatureText: 'Currently there are no finishes for this feature',
  noFinishesForModelText: 'Currently there are no finishes on this model',
  finishesConfiguratorTitle: 'Finishes',
  SubHeaderHomeText: 'Select a Scotframe house style',
  HeaderText: 'Design Your Home',
  HeaderHelpText: 'Help',
  WhatIsIncludedText: 'what is included text',
};
