import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useConfiguratorContext } from 'context/ConfiguratorContext';
import styled from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { muiTheme } from 'stylesheet';

import { DeviceSize, useResponsive } from 'pages/Models/useResponsive';
import { ModelStepperMobile } from './ModelStepperMobile';

const Container = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
  flexWrap: 'wrap',
});
const InnerContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const Connector = styled.div`
  width: 0;
  height: 100%;
`;

export const ModelStepper: React.FC = () => {
  const { stepOptions, selectedStep, setSelectedStep } = useConfiguratorContext();

  const { deviceSize } = useResponsive();

  if (deviceSize === DeviceSize.Phone || deviceSize === DeviceSize.Tablet)
    return <ModelStepperMobile />;

  return (
    <Container>
      <InnerContainer>
        <MuiThemeProvider theme={muiTheme}>
          <Stepper nonLinear activeStep={selectedStep?.index} connector={<Connector />}>
            {stepOptions.map((stepOption) => {
              return (
                <Step key={stepOption.label} onClick={() => setSelectedStep(stepOption)}>
                  <StepLabel icon={null}>{stepOption.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </MuiThemeProvider>
      </InnerContainer>
    </Container>
  );
};
