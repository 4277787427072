import { createIntl, createIntlCache } from 'react-intl';
import { RequestAQuoteFormValues } from 'redux/Models/types';
import * as yup from 'yup';

export interface IProps {
  modelId: string;
  getCustomerLink: () => string;
}

export const initialValues: RequestAQuoteFormValues = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  contactNumber: '',
  location: '',
  typeOfEnquiry: '',
  budgetRange: '',
  plotOwned: '',
  planningStatus: '',
  buildDateStartRange: '',
  additionalInfo: '',
  yourMessage: '',
  postcode: '',
  company: '',
  optInContact: false,
  emailCheckbox: false,
  phoneCheckbox: false,
};

export const requiredFreeTextFields = [
  {
    name: 'firstName',
    label: 'First Name*',
    placeholder: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name*',
    placeholder: 'Last Name',
  },
  {
    name: 'emailAddress',
    label: 'Email*',
    placeholder: 'Email',
  },
  {
    name: 'contactNumber',
    label: 'Contact Number*',
    placeholder: 'Contact Number',
  },
];

export const RequestAQuoteSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  emailAddress: yup
    .string()
    .email('Please enter a valid email')
    .required('Please enter a valid email'),
  contactNumber: yup.string().required('Please enter your contact number'),
  location: yup.string().required('Please input a project location'),
  optInContact: yup.bool().oneOf([true], 'You must opt in to receive a quotation'),
});

const cache = createIntlCache();
export const intl = createIntl(
  {
    locale: 'en-GB',
    messages: {},
  },
  cache,
);
