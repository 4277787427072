/* eslint-disable max-lines */
import { RootState } from 'redux/types';

export const state: RootState = {
  CMS: {
    loading: false,
    byIds: {
      '123': {
        id: '123',
        label: 'HeaderHelpText',
        value: 'Some helpful text',
      },
      '124': {
        id: '124',
        label: 'WhatIsIncludedText',
        value: 'I tell you whats included',
      },
    },
    allIds: ['123', '124'],
  },
  colors: {
    loading: false,
    byIds: {
      '123': {
        id: '123',
        label: 'Nordan',
        value: 'nordan',
        colors: [
          { label: 'White', ralName: 'NCS0500N', hexCode: '#f1efeb' },
          { label: 'Brown', ralName: 'RAL8011', hexCode: '#5a3826' },
        ],
      },
    },
    allIds: ['123'],
  },
  customerModelConfig: {
    modelId: 'model-123-abc',
    materials: {
      cladding: {
        value: 'cladding',
        texture: {
          textureId: 'texture-749',
          color: {
            label: 'Black',
            ralName: 'RAL9005',
            hexCode: '#0e0e10',
          },
        },
      },
    },
    features: {
      frontDoor: {
        value: 'frontDoor',
        variantValue: 'frontDoor_02',
      },
    },
    construction: 'threeStarClosed',
  },
  models: {
    loading: false,
    byIds: {
      '123': {
        id: '123',
        sketchfabModelId: 'abc',
        backgroundImageId: 'mockBackgroundImageId',
        name: 'House 1',
        materials: [],
        imgUrl: 'house.jpeg',
        description: '3 Bedroom | 1½ storey',
        hotspots: [],
        estimatedCost: {
          oneStarOpen: 60000,
          twoStarOpen: 60000,
          twoStarClosed: 60000,
          threeStarOpen: 60000,
          threeStarClosed: 60000,
          fourStarClosed: 60000,
          fiveStarClosed: 60000,
        },
      },
      '456': {
        id: '456',
        sketchfabModelId: 'def',
        name: 'House 2',
        materials: [],
        imgUrl: 'house2.jpeg',
        description: '3 Bedroom | 1½ storey',
        hotspots: [],
        estimatedCost: {
          oneStarOpen: 60000,
          twoStarOpen: 60000,
          twoStarClosed: 60000,
          threeStarOpen: 60000,
          threeStarClosed: 60000,
          fourStarClosed: 60000,
          fiveStarClosed: 60000,
        },
      },
    },
    allIds: ['123', '456'],
    selectedModelId: undefined,
  },
  modelGroups: {
    loading: false,
    allIds: ['123'],
    byIds: {
      '123': { id: '123', name: 'name', title: 'title', index: 1, modelIds: ['id1', 'id2', 'id3'] },
    },
  },
  textures: {
    loading: false,
    byIds: {
      '123': {
        id: '123',
        label: 'Texture 1',
        urlThumbnail: 'texturethumbnail.jpeg',
        configs: [
          {
            url: 'texture.jpeg',
            channel: 'AlbedoPBR',
          },
        ],
      },
      '456': {
        id: '456',
        label: 'Texture 2',
        urlThumbnail: 'texturethumbnail2.jpeg',
        configs: [
          {
            url: 'texture2.jpeg',
            channel: 'AlbedoPBR',
          },
        ],
      },
    },
    allIds: ['123', '456'],
  },
};
