import { colorUsage, fontSize, fontWeight, getSpacing } from 'stylesheet';

import { Title } from 'styling/Typography.style';
import styled from 'styled-components';

export const ConfiguratorContainer = styled.div`
  width: ${getSpacing(58)};
  display: flex;
  flex-direction: column;
  margin-bottom: ${getSpacing(1)};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${getSpacing(2)} ${getSpacing(4)};
  background-color: ${colorUsage.navigationBackground};
`;

// height calculated for reponsive configurators
export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(2)} ${getSpacing(4)};
  background-color: ${colorUsage.contentBackground};
  width: ${getSpacing(50)};
  height: calc(100vh - 420px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    /* stylelint-disable */
    -webkit-appearance: none;
    /* stylelint-enable */
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const ConfiguratorTitle = styled(Title)`
  font-weight: ${fontWeight.bold};
  color: ${colorUsage.navigationText};
  text-decoration: none;
  margin-bottom: 0;
  font-size: ${fontSize.medium};
`;
