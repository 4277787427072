import { render, screen } from '@testing-library/react';
import { Formik, FormikProps } from 'formik';
import { ConfigurableFieldInputs } from 'pages/Models/RequestAQuoteForm/ConfigurableFieldInputs';
import React from 'react';
import { RequestAQuoteFormValues } from 'redux/Models/types';
import { ConfigurableFieldsType } from 'pages/Models/RequestAQuoteForm/RequestAQuoteForm';

const fieldsToRender = [
  {
    value: 'company',
    label: 'Company',
    type: 'freeText',
  },
  {
    value: 'typeOfEnquiry',
    label: 'Type of Enquiry',
    type: 'dropdown',
    options: ['Small Builder', 'Self Builder', 'Other'],
  },
] as ConfigurableFieldsType;

const formikProps = ({
  touched: {},
  errors: {},
  values: {},
  setFieldValue: jest.fn(),
} as unknown) as FormikProps<RequestAQuoteFormValues>;

describe('tests for configurable field input', () => {
  it('renders fields passed into component', () => {
    render(
      <Formik initialValues={{ budgetRange: '', company: '' }} onSubmit={jest.fn()}>
        <ConfigurableFieldInputs formikProps={formikProps} configurableFields={fieldsToRender} />
      </Formik>,
    );
    expect(screen.getByText('Company')).toBeTruthy();
    expect(screen.getByText('Type of Enquiry')).toBeTruthy();
  });
});
