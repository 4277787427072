import { IMinimumReduxObjectKeys, NormalisedIds } from './types';

export const normaliseArray = <Item extends IMinimumReduxObjectKeys>(
  items: Item[],
): NormalisedIds<Item> => {
  const allIds: string[] = [];
  const byIds = items.reduce((acc, item) => {
    if (item.id) {
      allIds.push(item.id);
      return { ...acc, [item.id]: item };
    }
    return acc;
  }, {});
  return { byIds, allIds };
};

export const denormaliseItems = <Item extends IMinimumReduxObjectKeys>(
  normalisedItems: NormalisedIds<Item>,
): Item[] => {
  return normalisedItems.allIds.map((id) => normalisedItems.byIds[id]);
};
