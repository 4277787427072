import { initialState, reducer, setCustomerModelConfig } from 'redux/CustomerModelConfig';

import { ICustomerModelConfig } from '../types';

describe('setSavedCustomerMaterials', () => {
  it('Should set materials with value as object key', () => {
    const mockPayload: ICustomerModelConfig = {
      modelId: 'Model House',
      features: {
        frontDoor: {
          value: 'frontDoor',
          variantValue: 'frontDoor_02',
        },
      },
      materials: {
        cladding: {
          value: 'cladding',
          texture: {
            textureId: 'texture-749',
            color: { label: '', ralName: '', hexCode: '#123123' },
          },
        },
      },
      construction: 'threeStarClosed',
    };
    const action = setCustomerModelConfig(mockPayload);
    const expectedState = {
      ...initialState,
      modelId: 'Model House',
      features: {
        frontDoor: {
          value: 'frontDoor',
          variantValue: 'frontDoor_02',
        },
      },
      materials: {
        cladding: {
          value: 'cladding',
          texture: {
            textureId: 'texture-749',
            color: { label: '', ralName: '', hexCode: '#123123' },
          },
        },
      },
    };
    expect(reducer(initialState, action)).toEqual(expectedState);
  });
});
