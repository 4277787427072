import React from 'react';
import { fireEvent, render, screen } from '@testing-library/react';
import Construction from 'pages/Construction';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from 'services/i18n/intl';
import enMessages from 'translations/en.json';

const mockPush = jest.fn();

const locales = {
  en: flattenMessages(enMessages),
};
const ConstructionIntlWrapped = () => (
  <IntlProvider locale="en" messages={locales.en}>
    <Construction />
  </IntlProvider>
);

jest.mock('react-router-dom', () => ({
  useHistory: () => ({
    push: mockPush,
  }),
  useParams: jest.fn(() => ({ modelId: '456', customerModelConfigId: '123' })),
  Prompt: jest.fn(() => <div />),
}));

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest
    .fn()
    .mockImplementationOnce((fn) => fn('Design Your Home'))
    .mockImplementationOnce((fn) => fn('Select your wall construction'))
    .mockImplementation((fn) => fn('mock')),
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: () => (id: string) => id,
  getConstructionCMSFields: () => ({
    oneStarOpen: 'desc',
    twoStarOpen: 'desc',
    twoStarClosed: 'desc',
    threeStarOpen: 'desc',
    threeStarClosed: 'desc',
    fourStarClosed: 'desc',
    fiveStarClosed: 'desc',
  }),
}));
jest.mock('redux/Models', () => ({
  getSelectedModel: () => ({
    id: '456',
    sketchfabModelId: 'def',
    name: 'House 2',
    description: 'Small House',
    estimatedCost: {
      oneStarOpen: 60000,
      twoStarClosed: 60001,
      twoStarOpen: 60002,
      threeStarClosed: 60003,
      threeStarOpen: 60004,
      fourStarClosed: 60005,
      fiveStarClosed: 60005,
    },
  }),
  setSelectedModelId: () => jest.fn(),
}));

jest.mock('redux/Models/hooks', () => ({
  useFetchModels: () => [false, jest.fn()],
}));

jest.mock('redux/Textures/hooks', () => ({
  useFetchTextures: () => [false, jest.fn()],
}));

jest.mock('redux/CMS/hooks', () => ({
  useFetchCMSFields: () => [false, jest.fn()],
}));

const mockFetchModels = jest.fn();
jest.mock('redux/Models/hooks', () => ({
  useFetchModels: () => [false, mockFetchModels],
}));

const mockFetchColors = jest.fn();
jest.mock('redux/Colors/hooks', () => ({
  useFetchColors: () => [false, mockFetchColors],
}));

const mockFetchTextures = jest.fn();
jest.mock('redux/Textures/hooks', () => ({
  useFetchTextures: () => [false, mockFetchTextures],
}));

describe('Construction Page', () => {
  it('should have a main title and subtitle', () => {
    render(<ConstructionIntlWrapped />);
    expect(screen.queryByText('Design Your Home')).toBeTruthy();
    expect(screen.queryByText('Select your wall construction')).toBeTruthy();
  });
  it('should have a price displayed in the correct format', () => {
    render(<ConstructionIntlWrapped />);
    expect(screen.queryByText('Kit Cost*: £60,000')).toBeTruthy();
  });
  it('should have 7 construction options', () => {
    render(<ConstructionIntlWrapped />);
    expect(screen.queryByText('1* - Open')).toBeTruthy();
    expect(screen.queryByText('2* - Open')).toBeTruthy();
    expect(screen.queryByText('2* - Closed')).toBeTruthy();
    expect(screen.queryByText('3* - Open')).toBeTruthy();
    expect(screen.queryByText('3* - Closed')).toBeTruthy();
    expect(screen.queryByText('4* - Closed')).toBeTruthy();
    expect(screen.queryByText('5* - Closed')).toBeTruthy();
  });
  it('should go back to Home if go back pressed', () => {
    mockPush.mockClear();
    render(<ConstructionIntlWrapped />);
    fireEvent.click(screen.getByText('Go Back'));
    expect(mockPush).toHaveBeenCalledWith('/');
  });
  it('should go to /models/456 if continue pressed', () => {
    mockPush.mockClear();
    render(<ConstructionIntlWrapped />);
    fireEvent.click(screen.getByText('Continue'));
    expect(mockPush).toHaveBeenCalledWith('/models/456');
  });
});
