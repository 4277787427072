import { apiRefMock, mockMaterials } from '__mocks__/sketchfabApi';
import { setTexture } from 'services/sketchfab/texture';
import { ITextureConfig } from 'redux/Textures/types';
import { waitFor } from '@testing-library/react';

describe('setTexture', () => {
  const texture: ITextureConfig = {
    id: 'texture-123',
    label: 'Texture 123',
    urlThumbnail: 'wood.jpeg',
    configs: [
      {
        channel: 'AlbedoPBR',
        url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/wood2.jpeg',
      },
    ],
  };
  const expectedMaterial = {
    ...mockMaterials[0],
    channels: {
      ...mockMaterials[0].channels,
      AlbedoPBR: {
        ...mockMaterials[0].channels.AlbedoPBR,
        texture: {
          magFilter: 'LINEAR',
          minFilter: 'LINEAR_MIPMAP_LINEAR',
          wrapS: 'REPEAT',
          wrapT: 'REPEAT',
          textureTarget: 'TEXTURE_2D',
          internalFormat: 'RGB',
          texCoordUnit: 0,
          uid: 'mockTextureUid',
        },
      },
    },
  };
  it('should update the texture of the passed in material', async () => {
    apiRefMock.current.setMaterial.mockClear();
    setTexture(apiRefMock, 'Cladding', texture, null);
    expect(apiRefMock.current.addTexture).toHaveBeenCalledWith(
      'https://publicsketchfabstaging.blob.core.windows.net/textures/wood2.jpeg',
      expect.any(Function),
    );
    await waitFor(() => {
      expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith(expectedMaterial);
    });
  });
  it('should update the texture and color of the passed in material', async () => {
    apiRefMock.current.setMaterial.mockClear();
    setTexture(
      apiRefMock,
      'Cladding',
      {
        ...texture,
        configs: [{ ...texture.configs[0], channel: 'DiffusePBR' }],
      },
      { label: '', ralName: '', hexCode: '#ff0080' },
    );
    await waitFor(() => {
      expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith({
        ...expectedMaterial,
        channels: {
          ...expectedMaterial.channels,
          DiffusePBR: {
            ...expectedMaterial.channels.DiffusePBR,
            color: [0.99609375, 0, 0.5],
          },
        },
      });
    });
  });
  it('should update the default uvScale of the texture if none specified', () => {
    apiRefMock.current.setMaterial.mockClear();
    setTexture(apiRefMock, 'Cladding', texture, null);
    expect(apiRefMock.current.setUVScale).toHaveBeenCalledWith(expectedMaterial, 'AlbedoPBR', 1, 1);
  });
  it('should update the specified uvScale of the texture if specified', async () => {
    apiRefMock.current.setMaterial.mockClear();
    setTexture(
      apiRefMock,
      'Cladding',
      {
        ...texture,
        configs: [{ ...texture.configs[0], uvScale: { scaleX: 0.5, scaleY: 1.5 } }],
      },
      null,
    );
    await waitFor(() => {
      expect(apiRefMock.current.setUVScale).toHaveBeenCalledWith(
        expectedMaterial,
        'AlbedoPBR',
        0.5,
        1.5,
      );
    });
  });
  it('should do nothing if a bad material name is passed in ', () => {
    apiRefMock.current.setMaterial.mockClear();
    setTexture(apiRefMock, 'Made up', texture, null);
    expect(apiRefMock.current.setMaterial).not.toHaveBeenCalled();
  });
});
