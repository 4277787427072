import { fireEvent, render, screen } from '@testing-library/react';

import React from 'react';
import { flattenMessages } from 'services/i18n/intl';
import enMessages from 'translations/en.json';
import { IntlProvider } from 'react-intl';
import { ConstructionType } from 'redux/Models/types';
import { ConstructionOption } from '../ConstructionOption';

const mockChange = jest.fn();

const locales = {
  en: flattenMessages(enMessages),
};
const ConstructionOptionIntlWrapped = (commonProps: {
  title: string;
  description: string;
  value: ConstructionType;
  price: number;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <IntlProvider locale="en" messages={locales.en}>
    <ConstructionOption {...commonProps} />
  </IntlProvider>
);

const commonProps = {
  title: '1* - Open',
  description: 'Example Description',
  value: 'threeStarClosed' as ConstructionType,
  price: 60000,
  checked: false,
  onChange: mockChange,
};

describe('Construction Option', () => {
  it('should have a description and a price', () => {
    render(<ConstructionOptionIntlWrapped {...commonProps} />);
    expect(screen.queryByText('Example Description')).toBeTruthy();
    expect(screen.queryByText('Kit Cost*: £60,000')).toBeTruthy();
  });
  it('should call onChnage when radio button is pressed', () => {
    mockChange.mockClear();
    render(<ConstructionOptionIntlWrapped {...commonProps} />);
    fireEvent.click(screen.getByTestId('radio'));
    expect(mockChange).toHaveBeenCalled();
  });
});
