import styled from 'styled-components';
import { colorUsage, fontFamily, fontSize, fontWeight, getSpacing, lineHeight } from 'stylesheet';

export const ModelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${getSpacing(220)};
  padding: 0 ${getSpacing(5)};
`;

export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${getSpacing(5)} 0;
`;

export const CenteredAsterixTextContainer = styled.div`
  display: flex;
  align-self: center;
`;

export const ModelGroupTitle = styled.h1`
  align-self: center;
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.XLarge};
  line-height: ${lineHeight.large};
  color: ${colorUsage.primaryTextColor};
`;

export const ModelGroupSubtitle = styled.h2`
  align-self: center;
  font-weight: ${fontWeight.normal};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.large};
  line-height: ${lineHeight.medium};
  color: ${colorUsage.primaryTextColor};
`;
