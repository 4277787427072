export const initMock = jest.fn((modelId, callbacks) => {
  callbacks.success({
    start: jest.fn(),
    addEventListener: jest.fn((event, fn) => fn()),
    setCameraConstraints: jest.fn(),
    getCameraLookAt: jest.fn(),
  });
});
export class MockSketchfab {
  init = initMock;
}

window.Sketchfab = MockSketchfab;
