import { apiRefMock, mockMaterials } from '__mocks__/sketchfabApi';
import { changeMaterialColor, setAllDefaultColors } from 'services/sketchfab/color';

import { IModel } from 'redux/Models/types';
import { ITextureConfig } from 'redux/Textures/types';

describe('changeMaterialColor', () => {
  it('should update the color of the passed in material', () => {
    apiRefMock.current.setMaterial.mockClear();
    changeMaterialColor(apiRefMock, 'Cladding', { r: 4, g: 5, b: 6 });
    expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith({
      ...mockMaterials[0],
      channels: {
        ...mockMaterials[0].channels,
        AlbedoPBR: { ...mockMaterials[0].channels.AlbedoPBR, color: [4 / 256, 5 / 256, 6 / 256] },
      },
    });
  });
  it('should update the color specified channel', () => {
    apiRefMock.current.setMaterial.mockClear();
    changeMaterialColor(apiRefMock, 'Cladding', { r: 4, g: 5, b: 6 }, 'ClearCoat');
    expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith({
      ...mockMaterials[0],
      channels: {
        ...mockMaterials[0].channels,
        ClearCoat: {
          ...mockMaterials[0].channels.ClearCoat,
          tint: [4 / 256, 5 / 256, 6 / 256],
          enable: true,
        },
      },
    });
  });
  it('should do nothing if a bad material name is passed in ', () => {
    apiRefMock.current.setMaterial.mockClear();
    changeMaterialColor(apiRefMock, 'Made up', { r: 4, g: 5, b: 6 });
    expect(apiRefMock.current.setMaterial).not.toHaveBeenCalled();
  });
});

describe('setAllDefaultColors', () => {
  const model: IModel = {
    id: '123',
    sketchfabModelId: 'abc',
    backgroundImageId: 'mockBackgroundImageId',
    name: 'House 1',
    description: 'House',
    imgUrl: 'house.jpeg',
    hotspots: [],
    materials: [
      {
        value: 'Cladding',
        label: 'Cladding',
        availableTextures: [{ textureId: 'texture-123' }],
      },
      {
        value: 'Door Frame',
        label: 'Door Frame',
        availableTextures: [{ textureId: 'texture-456' }],
      },
    ],
    estimatedCost: {
      oneStarOpen: 60000,
      twoStarOpen: 60000,
      twoStarClosed: 60000,
      threeStarOpen: 60000,
      threeStarClosed: 60000,
      fourStarClosed: 60000,
      fiveStarClosed: 60000,
    },
  };
  const textures: ITextureConfig[] = [
    {
      id: 'texture-123',
      label: 'Texture 1',
      configs: [{ channel: 'AlbedoPBR', url: '' }],
      urlThumbnail: '',
      defaultColor: { r: 255, g: 12, b: 0 },
    },
    {
      id: 'texture-456',
      label: 'Texture 2',
      configs: [{ channel: 'AlbedoPBR', url: '' }],
      urlThumbnail: '',
      defaultColor: { r: 0, g: 12, b: 134 },
    },
  ];
  it('should update all the default colors', () => {
    apiRefMock.current.setMaterial.mockClear();
    setAllDefaultColors(model, textures, apiRefMock.current);
    expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith({
      ...mockMaterials[0],
      channels: {
        ...mockMaterials[0].channels,
        AlbedoPBR: {
          ...mockMaterials[0].channels.AlbedoPBR,
          color: [255 / 256, 12 / 256, 0 / 256],
        },
      },
    });
    expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith({
      ...mockMaterials[1],
      channels: {
        ...mockMaterials[1].channels,
        AlbedoPBR: {
          ...mockMaterials[0].channels.AlbedoPBR,
          color: [0 / 256, 12 / 256, 134 / 256],
        },
      },
    });
  });
});
