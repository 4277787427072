import {
  FooterContainer,
  FooterContents,
  FooterLink,
  FooterText,
} from 'components/Footer/Footer.style';

import React from 'react';
import { useSelector } from 'react-redux';
import { getCMSFieldById } from 'redux/CMS';

export const Footer: React.FC = () => {
  const privacyStatementLink = useSelector(getCMSFieldById('PrivacyStatementLink'));
  const copyrightNameText = useSelector(getCMSFieldById('CopyrightNameText'));

  return (
    <FooterContainer>
      <FooterContents>
        <FooterText>
          Copyright &copy; {new Date().getFullYear()} {copyrightNameText}
        </FooterText>
        <FooterLink href={privacyStatementLink} rel="noopener">
          Privacy Statement
        </FooterLink>
      </FooterContents>
    </FooterContainer>
  );
};
