import { GenericModal } from 'components/GenericModal';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCMSFieldById } from 'redux/CMS';
import styled from 'styled-components';
import { fontSize } from 'stylesheet';
import { ActionText } from 'styling/Typography.style';
import ReactGA from 'react-ga';
import { HTMLText } from 'components/HTMLText';

type HelpLinksProps = {
  helpText: string;
  showWhatIsIncluded?: boolean;
};

const HelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
export const HelpText = styled(ActionText)`
  font-size: ${fontSize.small};
`;
export const HelpLinks: React.FC<HelpLinksProps> = ({ helpText, showWhatIsIncluded }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showIncludeModal, setShowIncludeModal] = useState<boolean>(false);
  const whatIsIncludedText = useSelector(getCMSFieldById('WhatIsIncludedText'));
  const whatIsIncludedLabel = useSelector(getCMSFieldById('WhatIsIncludedLabel'));
  const getShowWhatIsIncluded = () =>
    showWhatIsIncluded && !!whatIsIncludedLabel && !!whatIsIncludedText;
  return (
    <>
      <HelpContainer>
        {getShowWhatIsIncluded() && (
          <HelpText
            onClick={() => {
              ReactGA.event({
                category: 'User Action',
                action: `${whatIsIncludedLabel} Pressed`,
              });
              setShowIncludeModal(!showIncludeModal);
            }}
          >
            {whatIsIncludedLabel}
          </HelpText>
        )}
        <HelpText
          onClick={() => {
            ReactGA.event({
              category: 'User Action',
              action: 'Pressed Help Button',
            });
            setShowModal(true);
          }}
        >
          Help
        </HelpText>
      </HelpContainer>
      <GenericModal showModal={showModal} setShowModal={setShowModal}>
        <HTMLText textToSet={helpText} heightUnset={true} />
      </GenericModal>
      {showIncludeModal && (
        <GenericModal showModal={showIncludeModal} setShowModal={setShowIncludeModal}>
          <HTMLText textToSet={whatIsIncludedText} heightUnset={true} />
        </GenericModal>
      )}
    </>
  );
};
