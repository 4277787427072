import React from 'react';
import StepLabel from '@material-ui/core/StepLabel';
import styled from 'styled-components';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { muiTheme } from 'stylesheet';

import MobileStepper from '@material-ui/core/MobileStepper';
import { ModalStepperButtonsBack, ModalStepperButtonsNext } from './ModelStepperButtons';
import { useConfiguratorContext } from 'context/ConfiguratorContext';

const StyledMobileStepLabel = withStyles({
  label: {
    width: '100%',
  },
})(StepLabel);

const MobileStepperContainer = styled.div({
  width: '100%',
  alignContent: 'center',
});

export const ModelStepperMobile: React.FC = () => {
  const { stepOptions, selectedStep } = useConfiguratorContext();

  return (
    <MuiThemeProvider theme={muiTheme}>
      <MobileStepperContainer>
        <StyledMobileStepLabel>{selectedStep?.label}</StyledMobileStepLabel>
        <MobileStepper
          variant="dots"
          steps={stepOptions.length}
          position="static"
          activeStep={selectedStep?.index}
          nextButton={<ModalStepperButtonsNext />}
          backButton={<ModalStepperButtonsBack />}
        />
      </MobileStepperContainer>
    </MuiThemeProvider>
  );
};
