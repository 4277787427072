import chevronRight from 'assets/chevron-right.svg';
import { OptionsContainer } from 'components/VerticalConfiguratorContainer/VerticalConfiguratorContainer.style';
import React from 'react';
import {
  ConfiguratorContainer,
  TitleContainer,
  ConfiguratorTitle,
  Icon,
} from './CollapsibleConfigurator.style';

type IProps = {
  title: string;
  expandedScreen: boolean;
  setExpandedScreen: (expanded: boolean) => void;
};

const CollapsibleConfiguratorContainer: React.FunctionComponent<IProps> = ({
  title,
  children,
  expandedScreen,
  setExpandedScreen,
}) => {
  return (
    <ConfiguratorContainer expanded={!expandedScreen}>
      <TitleContainer expanded={!expandedScreen} onClick={() => setExpandedScreen(!expandedScreen)}>
        <ConfiguratorTitle expanded={!expandedScreen}>{title}</ConfiguratorTitle>
        <Icon src={chevronRight} style={!expandedScreen ? { transform: 'scaleX(-1)' } : {}} />
      </TitleContainer>
      <OptionsContainer>{!expandedScreen ? children : null}</OptionsContainer>
    </ConfiguratorContainer>
  );
};
export default CollapsibleConfiguratorContainer;
