import { normaliseArray, denormaliseItems } from 'redux/mappers';

const mockDatabaseReturn = [
  {
    id: '123',
    sketchfabModelId: 'abc',
  },
  {
    id: '456',
    sketchfabModelId: 'def',
  },
];
const normalisedItems = {
  byIds: {
    '123': {
      id: '123',
      sketchfabModelId: 'abc',
    },
    '456': {
      id: '456',
      sketchfabModelId: 'def',
    },
  },
  allIds: ['123', '456'],
};
describe('normaliseArray', () => {
  it('should normalise an array of objects', () => {
    expect(normaliseArray(mockDatabaseReturn)).toEqual(normalisedItems);
  });
  it('should skip items with no id', () => {
    const mockBadDatabaseReturn = [
      {
        sketchfabModelId: 'abc',
      },
      {
        id: '456',
        sketchfabModelId: 'def',
      },
    ];
    // @ts-expect-error: explictly testing if bad type from backend
    expect(normaliseArray(mockBadDatabaseReturn)).toEqual({
      byIds: {
        '456': {
          id: '456',
          sketchfabModelId: 'def',
        },
      },
      allIds: ['456'],
    });
  });
});

describe('denormaliseItems', () => {
  it('should denormalise an array of objects from redux', () => {
    expect(denormaliseItems(normalisedItems)).toEqual(mockDatabaseReturn);
  });
});
