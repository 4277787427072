import { apiRefMock } from '__mocks__/sketchfabApi';
import { setCameraLookAt } from 'services/sketchfab/camera';

describe('setCameraLookAt', () => {
  it('should update where the camera points', () => {
    apiRefMock.current.setCameraLookAt.mockClear();
    setCameraLookAt(apiRefMock, [1, 2, 3], [4, 5, 6], 3);
    expect(apiRefMock.current.setCameraLookAt).toHaveBeenCalledWith([1, 2, 3], [4, 5, 6], 3);
  });
});
