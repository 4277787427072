import React from 'react';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { render, screen } from '@testing-library/react';

describe('ErrorBoundary', () => {
  it('should normally render the children', () => {
    const AnyComponent = () => <h1>My App</h1>;
    render(
      <ErrorBoundary FallbackComponent={() => <div>Oops</div>}>
        <AnyComponent />
      </ErrorBoundary>,
    );
    expect(screen.queryByText('My App')).toBeTruthy();
    expect(screen.queryByText('Oops')).toBeFalsy();
  });
  it('should normally render the FallbackComponent if error is caught in children', () => {
    const ComponentWithError = () => {
      throw Error('Unknown Error');
    };
    const FallbackComponent = () => <div>Oops</div>;

    jest.spyOn(global.console, 'error').mockImplementation((error) => error);

    render(
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <ComponentWithError />
      </ErrorBoundary>,
    );
    expect(screen.queryByText('My App')).toBeFalsy();
    expect(screen.queryByText('Oops')).toBeTruthy();
  });
});
