import { fireEvent, render, screen } from '@testing-library/react';

import { GenericModal } from '../GenericModal';
import React from 'react';

const commonProps = {
  setShowModal: jest.fn(),
  showModal: true,
};

describe('GenericModal Component', () => {
  it('should have a close button which closes the modal', () => {
    render(<GenericModal {...commonProps} />);
    expect(screen.queryByText('Close')).toBeTruthy();
    fireEvent.click(screen.getByText('Close'));
    expect(commonProps.setShowModal).toHaveBeenCalledWith(false);
  });
  it('should not be a modal if showModal is false', () => {
    render(<GenericModal {...commonProps} showModal={false} />);
    expect(screen.queryByText('Close')).toBeFalsy();
  });
  it('should render an extra button if passed', () => {
    render(<GenericModal {...commonProps} ExtraButtonsComponent={() => <button>Extra</button>} />);
    expect(screen.queryByText('Extra')).toBeTruthy();
  });
});
