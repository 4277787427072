import { act, renderHook } from '@testing-library/react-hooks';
import { useConvertShortLink } from 'redux/hooks/useConvertShortLink';
import { client } from 'services/networking/client';

jest.mock('services/networking/client', () => ({
  client: {
    get: jest.fn(() => ({
      longLink: '/models/123/abc',
    })),
  },
}));

const mockPush = jest.fn();
jest.mock('react-router-dom', () => ({
  useHistory: () => ({
    push: mockPush,
  }),
}));

describe('useConvertShortLink hook', () => {
  it('should call the convertShortLink endpoint', async () => {
    const { result, waitForNextUpdate } = renderHook(() => useConvertShortLink());
    const [, doConvertShortLink] = result.current;
    act(() => {
      doConvertShortLink({
        shortLink: '1234567890',
      });
    });
    await waitForNextUpdate();
    expect(client.get).toHaveBeenCalledWith('/api/convertshortlink/1234567890');
  });
  it('should redirect the user', async () => {
    const { result, waitForNextUpdate } = renderHook(() => useConvertShortLink());
    const [, doConvertShortLink] = result.current;
    act(() => {
      doConvertShortLink({
        shortLink: '1234567890',
      });
    });
    await waitForNextUpdate();
    expect(mockPush).toHaveBeenCalledWith('/models/123/abc');
  });
});
