import React, { useState } from 'react';
import { GenericModal } from 'components/GenericModal';
import { SecondaryButton } from 'components/Button';
import { Description } from 'pages/Construction/Construction.style';

interface IProps {
  cameraPosition:
    | { eyePosition: [number, number, number]; targetPosition: [number, number, number] }
    | undefined;
}

export const CameraPositionButtonAndModal: React.FunctionComponent<IProps> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const cameraPosition = {
    eyePosition: props.cameraPosition?.eyePosition.map((i) => i.toFixed(5)),
    targetPosition: props.cameraPosition?.targetPosition.map((i) => i.toFixed(5)),
  };
  return (
    <>
      <SecondaryButton
        onClick={() => {
          setShowModal(true);
        }}
      >
        Camera Position
      </SecondaryButton>
      {showModal && (
        <GenericModal showModal={showModal} setShowModal={setShowModal}>
          <Description>{`Eye Position: [${cameraPosition?.eyePosition?.[0]}, ${cameraPosition?.eyePosition?.[1]}, ${cameraPosition?.eyePosition?.[2]}]`}</Description>
          <Description>{`Target Position: [${cameraPosition?.targetPosition?.[0]}, ${cameraPosition?.targetPosition?.[1]}, ${cameraPosition?.targetPosition?.[2]}]`}</Description>
        </GenericModal>
      )}
    </>
  );
};
