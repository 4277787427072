import React, { useState } from 'react';

import { ErrorText } from 'styling/Typography.style';
import { GenericModal } from 'components/GenericModal';
import { RequestAQuoteForm } from 'pages/Models/RequestAQuoteForm';
import { getCustomerModelConfig } from 'redux/CustomerModelConfig';
import { useCreateCustomerModelConfig } from 'redux/CustomerModelConfig/hooks';
import { useSelector } from 'redux/useSelector';
import ReactGA from 'react-ga';
import { ActionButton } from './Models.style';
import { useResponsive } from './useResponsive';

interface IProps {
  modelId: string;
}

export const RequestAQuoteButtonAndModal: React.FunctionComponent<IProps> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const customerModelConfig = useSelector(getCustomerModelConfig);
  const [createCustomerModelState, doCreateCustomerModelConfig] = useCreateCustomerModelConfig();

  const getCustomerLink = () =>
    `${window.location.origin}${createCustomerModelState.value?.shortLink}`;

  const { deviceSize } = useResponsive();

  return (
    <>
      <ActionButton
        disabled={createCustomerModelState.loading}
        onClick={() => {
          ReactGA.event({
            category: 'User Action',
            action: 'Request Quote Modal Opened',
            label: props.modelId,
          });
          doCreateCustomerModelConfig(customerModelConfig);
          setShowModal(true);
        }}
        deviceSize={deviceSize}
      >
        Request Quote
      </ActionButton>
      {showModal && (
        <GenericModal showModal={showModal} setShowModal={setShowModal}>
          {createCustomerModelState.value?.error && (
            <ErrorText>{createCustomerModelState.value.error}</ErrorText>
          )}
          <RequestAQuoteForm modelId={props.modelId} getCustomerLink={getCustomerLink} />
        </GenericModal>
      )}
    </>
  );
};
