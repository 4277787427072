import { ConfiguratorContextProvider, useConfiguratorContext } from 'context/ConfiguratorContext';
import { fireEvent, render, screen } from '@testing-library/react';

import React from 'react';

const DummyConsumer = () => {
  const {
    selectedMaterialName,
    setSelectedMaterialName,
    selectedTextures,
    setSelectedTextures,
    selectedColors,
    setSelectedColors,
    selectedFeatureName,
    selectedFeature,
    setSelectedFeature,
  } = useConfiguratorContext();
  return (
    <>
      <div>{selectedMaterialName}</div>
      <div>Feature Name: {selectedFeatureName}</div>
      <div>Feature: {selectedFeature?.label}</div>

      {Object.keys(selectedTextures).map((textureKey) => (
        <div key={textureKey}>
          {textureKey}: {selectedTextures[textureKey].textureId}{' '}
        </div>
      ))}
      {Object.keys(selectedColors).map((colorKey) => (
        <div key={colorKey}>
          {colorKey}: {selectedColors[colorKey].hexCode}
        </div>
      ))}
      <button onClick={() => setSelectedMaterialName('new name')}>Set Material Name</button>
      <button
        onClick={() => {
          setSelectedFeature({ label: 'new feature', value: 'newFeature' });
        }}
      >
        Set Feature
      </button>
      <button
        onClick={() => {
          setSelectedTextures({ texture1: { textureId: 'oak-id' } });
        }}
      >
        Add textures
      </button>
      <button
        onClick={() => {
          setSelectedColors({ color1: { label: '', ralName: '', hexCode: '#ffffff' } });
        }}
      >
        Add color
      </button>
    </>
  );
};

describe('ConfiguratorContext', () => {
  it('should pass the material name through context', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    expect(screen.queryByText('new name')).toBeFalsy();
    fireEvent.click(screen.getByText('Set Material Name'));
    expect(screen.queryByText('new name')).toBeTruthy();
  });
  it('should pass the selected textures through context', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    expect(screen.queryByText('texture1: oak-id')).toBeFalsy();
    fireEvent.click(screen.getByText('Add textures'));
    expect(screen.queryByText('texture1: oak-id')).toBeTruthy();
  });
  it('should pass the selected colors through context', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    expect(screen.queryByText('color1: #ffffff')).toBeFalsy();
    fireEvent.click(screen.getByText('Add color'));
    expect(screen.queryByText('color1: #ffffff')).toBeTruthy();
  });
  it('should pass the selected feature through context', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    expect(screen.queryByText('Feature: new feature')).toBeFalsy();
    fireEvent.click(screen.getByText('Set Feature'));
    expect(screen.queryByText('Feature: new feature')).toBeTruthy();
  });
});
