import { initialState, reducer, setModels, setSelectedModelId } from 'redux/Models';

describe('Models, initialState reducer', () => {
  describe('setModels', () => {
    it('Should set models in a normalised manner', () => {
      const mockDatabaseReturn = [
        {
          id: '123',
          sketchfabModelId: 'abc',
          backgroundImageId: 'mockBackgroundImageId',
          name: 'House 1',
          materials: [],
          hotspots: [],
          imgUrl: 'house.jpeg',
          description: '3 Bedroom | 1½ storey',
          estimatedCost: {
            oneStarOpen: 60000,
            twoStarOpen: 60000,
            twoStarClosed: 60000,
            threeStarOpen: 60000,
            threeStarClosed: 60000,
            fourStarClosed: 60000,
            fiveStarClosed: 60000,
          },
        },
        {
          id: '456',
          sketchfabModelId: 'def',
          name: 'House 2',
          materials: [],
          hotspots: [],
          imgUrl: 'house.jpeg',
          description: '3 Bedroom | 1½ storey',
          estimatedCost: {
            oneStarOpen: 60000,
            twoStarOpen: 60000,
            twoStarClosed: 60000,
            threeStarOpen: 60000,
            threeStarClosed: 60000,
            fourStarClosed: 60000,
            fiveStarClosed: 60000,
          },
        },
      ];
      const action = setModels(mockDatabaseReturn);
      const expectedState = {
        ...initialState,
        byIds: {
          '123': {
            id: '123',
            sketchfabModelId: 'abc',
            backgroundImageId: 'mockBackgroundImageId',
            name: 'House 1',
            materials: [],
            hotspots: [],
            imgUrl: 'house.jpeg',
            description: '3 Bedroom | 1½ storey',
            estimatedCost: {
              oneStarOpen: 60000,
              twoStarOpen: 60000,
              twoStarClosed: 60000,
              threeStarOpen: 60000,
              threeStarClosed: 60000,
              fourStarClosed: 60000,
              fiveStarClosed: 60000,
            },
          },
          '456': {
            id: '456',
            sketchfabModelId: 'def',
            name: 'House 2',
            materials: [],
            hotspots: [],
            imgUrl: 'house.jpeg',
            description: '3 Bedroom | 1½ storey',
            estimatedCost: {
              oneStarOpen: 60000,
              twoStarOpen: 60000,
              twoStarClosed: 60000,
              threeStarOpen: 60000,
              threeStarClosed: 60000,
              fourStarClosed: 60000,
              fiveStarClosed: 60000,
            },
          },
        },
        allIds: ['123', '456'],
      };

      expect(reducer(initialState, action)).toEqual(expectedState);
    });
  });
  describe('setSelectedModelId', () => {
    it('should set the selected Model Id', () => {
      const action = setSelectedModelId('387');
      const expectedState = {
        ...initialState,
        selectedModelId: '387',
      };
      expect(reducer(initialState, action)).toEqual(expectedState);
    });
  });
});
