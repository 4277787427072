/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { setTexture } from 'services/sketchfab/texture';
import { IMaterialConfig } from 'redux/Models/types';
import { ITextureConfig } from 'redux/Textures/types';
import { SquarePicker } from 'components/SquarePicker';
import { SubTitle } from 'styling/Typography.style';
import { TextureConfiguratorContainer } from './TextureConfigurator.style';
import { getAllTextures } from 'redux/Textures';
import { setSavedCustomerMaterials } from 'redux/CustomerModelConfig';
import { useConfiguratorContext } from 'context/ConfiguratorContext';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/useSelector';

interface IProps {
  material: IMaterialConfig | undefined | null;
  sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI>;
}

export const TextureConfigurator: React.FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch();
  const textures = useSelector(getAllTextures);
  const {
    selectedTextures,
    setSelectedTextures,
    selectedColors,
    selectedMaterialName,
  } = useConfiguratorContext();

  const setSelectedTextureId = (textureId: string | undefined) => {
    if (props.material && textureId) {
      setSelectedTextures({
        ...selectedTextures,
        [props.material.value]: { textureId },
      });
    }
  };

  const materialTextures = props.material?.availableTextures?.map((availableTexture) =>
    textures.find((texture) => texture.id === availableTexture.textureId),
  );
  const onTextureChange = (texture: ITextureConfig | undefined) => {
    if (texture) {
      setSelectedTextureId(texture.id);
      const color =
        selectedMaterialName && texture.canChangeColor
          ? selectedColors[selectedMaterialName]
          : null;
      setTexture(props.sketchfabApiRef, props.material?.value, texture, color);
      if (props.material) {
        dispatch(
          setSavedCustomerMaterials({
            value: props.material.value,
            texture: { textureId: texture.id, ...(color && { color }) },
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (materialTextures && materialTextures.length === 1) {
      onTextureChange(materialTextures[0]);
    }
  }, [selectedMaterialName]);

  return materialTextures ? (
    <TextureConfiguratorContainer>
      <SubTitle>{props.material?.finishHeading}</SubTitle>
      <SquarePicker<ITextureConfig | undefined>
        options={materialTextures}
        onOptionChange={onTextureChange}
        isOptionSelected={(texture) =>
          texture
            ? !!(
                props.material?.value &&
                texture.id === selectedTextures[props.material.value]?.textureId
              )
            : false
        }
        width={8}
        height={8}
      />
    </TextureConfiguratorContainer>
  ) : null;
};
