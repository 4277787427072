import { colorUsage, fontFamily, getSpacing } from 'stylesheet';

import React from 'react';
import Select, { Styles } from 'react-select';
import { getOptionValue as GetOptionValueType } from 'react-select/src/builtins';

const getIsFocusedColor = (isFocused: boolean): string | undefined =>
  isFocused ? colorUsage.selectOptionFocused : undefined;

const customStyles: Partial<Styles> = {
  menu: () => ({
    flex: 1,
    border: `1px solid ${colorUsage.selectBorder}`,
    fontFamily: fontFamily.main,
    backgroundColor: `${colorUsage.contentBackground}`,
  }),
  container: () => ({
    maxWidth: getSpacing(60),
    minWidth: getSpacing(45),
    fontFamily: fontFamily.main,
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? colorUsage.selectOptionSelected : getIsFocusedColor(isFocused),
  }),
  control: (provided, { isFocused }) => ({
    ...provided,
    borderColor: isFocused ? colorUsage.selectFocusedBorder : colorUsage.selectBorder,
    boxShadow: isFocused && `0px 0px 0px 1px ${colorUsage.selectFocusedBorder}`,
    '&:hover': {
      borderColor: isFocused && colorUsage.selectFocusedBorder,
    },
  }),
};

export type SelectedOption = {
  value: string;
  label: string;
};

type IProps<T extends SelectedOption> = {
  selectedOption: T | null;
  options: T[];
  setSelectedOption: (option: T | null) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  getOptionValue?: GetOptionValueType<T>;
};

function getFirstSelected<T>(value: T | Readonly<T[]> | undefined | null) {
  if (value) {
    if (Array.isArray(value)) {
      return value[0] as T;
    }
    return value as T;
  }
  return null;
}

export const Selector = <T extends SelectedOption>({
  selectedOption,
  setSelectedOption,
  options,
  placeholder,
  getOptionValue,
}: IProps<T>): JSX.Element => {
  return (
    <Select
      styles={customStyles}
      value={selectedOption}
      onChange={(value) => setSelectedOption(getFirstSelected(value))}
      options={options}
      placeholder={placeholder}
      isSearchable={false}
      maxMenuHeight={200}
      getOptionValue={getOptionValue}
    />
  );
};
