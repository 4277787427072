import { DeviceSize } from 'pages/Models/useResponsive';
import styled from 'styled-components';
import { borderRadius, colorUsage, getSpacing } from 'stylesheet';

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
RootContainer.displayName = 'RootContainer';

export const PageContent = styled.main<{ deviceSize: DeviceSize }>`
  background-color: ${colorUsage.secondaryBackground};
  border-radius: ${borderRadius.large};
  padding: 0 ${(props) => (props.deviceSize === DeviceSize.Phone ? getSpacing(0) : getSpacing(6))}
    ${getSpacing(6)};
  flex-grow: 1;
`;
PageContent.displayName = 'PageContent';
