import { act, renderHook } from '@testing-library/react-hooks';
import { useFetchTextures } from 'redux/Textures/hooks';

import { client } from 'services/networking/client';
import { setTextures } from 'redux/Textures';

jest.mock('services/networking/client', () => ({
  client: {
    get: jest.fn(() => ({
      id: '789',
    })),
  },
}));

jest.mock('redux/Textures');

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
}));

describe('useFetchTextures', () => {
  it('should call the gettextures endpoint', async () => {
    const { result, waitForNextUpdate } = renderHook(() => useFetchTextures());
    const [, doFetchTextures] = result.current;
    act(() => {
      doFetchTextures();
    });
    await waitForNextUpdate();
    expect(client.get).toHaveBeenCalledWith('/api/gettextures');
    expect(setTextures).toHaveBeenCalledWith({ id: '789' });
  });
});
