import styled from 'styled-components';
import { getSpacing } from 'stylesheet';
import { Form } from 'formik';
import { SecondaryButton } from 'components/Button';

interface IFormContainerProps {
  noPadding?: boolean;
}

export const FormContainer = styled.div<IFormContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.noPadding ? '0' : `0 ${getSpacing(8)}`)};
`;

export const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const InputContainer = styled.div`
  margin-bottom: 5px;
  margin-right: 10px;
`;

export const SubmitButton = styled(SecondaryButton)`
  align-self: center;
  margin-top: ${getSpacing(8)};
`;
