import { IVariant, IVariantNode, IModel, IFeatureConfig } from 'redux/Models/types';
import { ICustomerModelConfig } from 'redux/CustomerModelConfig/types';

const showOrHideNodes = (
  show: boolean,
  apiRef: React.MutableRefObject<null | ISketchfabAPI>,
  variantNodes: IVariantNode[] | undefined,
) => {
  if (variantNodes) {
    apiRef.current?.getNodeMap((err, nodes) => {
      variantNodes.forEach((variantNode) => {
        const variants = Object.values(nodes).filter((node) => node.name === variantNode.value);
        variants.forEach((variant) => {
          if (show) {
            apiRef.current?.show(variant.instanceID);
          } else {
            apiRef.current?.hide(variant.instanceID);
          }
        });
      });
    });
  }
};

export const showVariant = (
  apiRef: React.MutableRefObject<null | ISketchfabAPI>,
  variantName: string,
  variants: IVariant[] | undefined,
  excludedNodes?: string[],
): void => {
  if (variants) {
    const variantToShow = variants.find((variant) => variant.value === variantName);
    if (variantToShow) {
      // Toggle off all other nodes in this feature
      variants
        .filter((variant) => variant.value !== variantName)
        .forEach((variant) => {
          showOrHideNodes(false, apiRef, variant.variantNodes);
        });

      // Toggle off additional excluded nodes
      showOrHideNodes(
        false,
        apiRef,
        excludedNodes?.map((node) => ({ value: node })),
      );

      // Turn on selected node
      showOrHideNodes(true, apiRef, variantToShow.variantNodes);
    }
  }
};

export const setAllDefaultVariants = (
  selectedModel: IModel | undefined,
  api: ISketchfabAPI,
): void => {
  selectedModel?.features?.forEach((feature: IFeatureConfig) => {
    if (feature.variants) {
      const defaultVariant: IVariant | undefined =
        feature.variants.find((variant) => variant.default) || feature.variants[0];
      showVariant({ current: api }, defaultVariant.value, feature.variants);
    }
  });
};

export const setAllCustomerVariants = (
  selectedModel: IModel | undefined,
  customerModelConfig: ICustomerModelConfig,
  api: ISketchfabAPI,
): void => {
  selectedModel?.features?.forEach((feature: IFeatureConfig) => {
    if (customerModelConfig.features[feature.value]) {
      showVariant(
        { current: api },
        customerModelConfig.features[feature.value].variantValue,
        feature.variants,
      );
    }
  });
};
