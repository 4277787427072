import React from 'react';
import { download } from './helpers';
import { ActionButton } from './Models.style';
import { useResponsive } from './useResponsive';

interface IProps {
  sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI>;
}

export const TakePhotoButton: React.FunctionComponent<IProps> = (props) => {
  const { deviceSize } = useResponsive();

  return (
    <ActionButton
      onClick={() => {
        props.sketchfabApiRef.current?.getScreenShot(1000, 700, (err, result) => {
          if (!err && result) {
            download(result, 'screenshot.jpeg');
          }
        });
      }}
      deviceSize={deviceSize}
    >
      Take Photo
    </ActionButton>
  );
};
