import * as React from 'react';

import { CameraButtonsContainer, HotspotSelectContainer } from './HotspotConfigurator.style';
import { IHotspotConfig } from 'redux/Models/types';
import { Selector } from 'components/Selector';
import { Text } from 'styling/Typography.style';
import { getSelectedModel } from 'redux/Models';
import { setCameraLookAt } from 'services/sketchfab/camera';
import { useSelector } from 'redux/useSelector';
import { VerticalConfiguratorContainer } from 'components/VerticalConfiguratorContainer';
import { ResetCameraText } from './Models.style';
import { FloorplanLinks } from 'pages/Models/FloorplanLinks';
import { getCMSFieldById } from 'redux/CMS';
import { HelpLinks } from './HelpLinks';
import { Spacer } from 'components/Spacer';
import { onResetCameraClick } from './helpers';

interface IProps {
  sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI>;
}

export const HotspotConfigurator: React.FunctionComponent<IProps> = (props) => {
  const selectedModel = useSelector(getSelectedModel);
  const exterior = selectedModel?.hotspots?.find((hotspot) => hotspot.value === 'exterior');
  const [selectedHotspot, setSelectedHotspot] = React.useState<IHotspotConfig | null>(
    exterior || null,
  );
  const helpText = useSelector(getCMSFieldById('HeaderHelpText'));

  const onHotspotChange = (hotspot: IHotspotConfig | null) => {
    if (hotspot) {
      setSelectedHotspot(hotspot);
      setCameraLookAt(props.sketchfabApiRef, hotspot.eyePosition, hotspot.targetPosition, 3);
    }
  };

  return (
    <VerticalConfiguratorContainer title={'Explore'}>
      <HotspotSelectContainer>
        {selectedModel?.hotspots ? (
          <>
            <CameraButtonsContainer>
              <ResetCameraText
                onClick={() =>
                  onResetCameraClick({ sketchfabApiRef: props.sketchfabApiRef, selectedModel })
                }
              >
                Reset Camera
              </ResetCameraText>
            </CameraButtonsContainer>
            <Selector<IHotspotConfig>
              setSelectedOption={onHotspotChange}
              options={selectedModel.hotspots}
              placeholder="Select area to explore"
              selectedOption={selectedHotspot}
            />
            <FloorplanLinks selectedModel={selectedModel} />
            <Spacer.Flex />
            <HelpLinks helpText={helpText} showWhatIsIncluded={true} />
          </>
        ) : (
          <Text>Currently there are no locations available on this model</Text>
        )}
      </HotspotSelectContainer>
    </VerticalConfiguratorContainer>
  );
};
