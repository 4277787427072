import React from 'react';
import { FloorplanLinks } from 'pages/Models/FloorplanLinks';
import { IModel } from 'redux/Models/types';
import { fireEvent, render, screen } from '@testing-library/react';
import ReactGA from 'react-ga';

const ReactGASpy = jest.spyOn(ReactGA, 'event');

describe('tests for floorplan links component', () => {
  it('renders ground floor plan link when in the selected model', () => {
    const mockModel = { groundFloorPlanImgUrl: 'fakeurl' } as IModel;
    render(<FloorplanLinks selectedModel={mockModel} />);
    expect(screen.getByText('Ground Floor Plan')).toBeTruthy();
    fireEvent.click(screen.getByText('Ground Floor Plan'));
    expect(ReactGASpy).toHaveBeenCalledWith({
      action: 'Ground Floor Plan Pressed',
      category: 'User Action',
      label: undefined,
    });
  });
  it('renders both ground floor plan link  and first floor plan when in the selected model', () => {
    const mockModel = {
      groundFloorPlanImgUrl: 'fakeurl',
      firstFloorPlanImgUrl: 'fakeUrl2',
    } as IModel;
    render(<FloorplanLinks selectedModel={mockModel} />);
    expect(screen.getByText('Ground Floor Plan')).toBeTruthy();
    expect(screen.getByText('First Floor Plan')).toBeTruthy();
    fireEvent.click(screen.getByText('First Floor Plan'));
    expect(ReactGASpy).toHaveBeenCalledWith({
      action: 'First Floor Plan Pressed',
      category: 'User Action',
      label: undefined,
    });
  });
  it('shows a link to the specification when available', () => {
    const mockModel = {
      groundFloorPlanImgUrl: 'fakeurl',
      specificationUrl: 'https://specificationUrl',
    } as IModel;
    render(<FloorplanLinks selectedModel={mockModel} />);
    fireEvent.click(screen.getByText('Ground Floor Plan'));
    // @ts-expect-error href not always defined but is here
    expect(screen.getByText('here').href).toBe('https://specificationurl/');
    expect(screen.queryByText('For further details on this house style, click')).toBeTruthy();
  });
  it('does not show a link to the specification if not available', () => {
    const mockModel = {
      groundFloorPlanImgUrl: 'fakeurl',
    } as IModel;
    render(<FloorplanLinks selectedModel={mockModel} />);
    fireEvent.click(screen.getByText('Ground Floor Plan'));
    expect(screen.queryByText('For further details on this house style, click')).toBeFalsy();
  });
});
