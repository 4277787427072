import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

interface SpacerProps {
  numberOfSpaces: number;
}
const RowSpacer = styled.div<SpacerProps>(({ numberOfSpaces }) => ({
  width: getSpacing(numberOfSpaces),
}));
const ColumnSpacer = styled.div<SpacerProps>(({ numberOfSpaces }) => ({
  height: getSpacing(numberOfSpaces),
}));

interface FlexSpacerProps {
  flex?: number;
}
const FlexSpacer = styled.div<FlexSpacerProps>(({ flex }) => ({
  flex: flex || 1,
}));

export const Spacer = {
  Flex: FlexSpacer,
  Row: RowSpacer,
  Column: ColumnSpacer,
};
