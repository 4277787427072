export {};
type OnOffToggle = 0 | 1;
declare global {
  interface Window {
    Sketchfab: new (ref: null | ISketchfabAPI) => {
      init: (
        sketchfabModelId: string,
        callbacks: {
          success: (api: ISketchfabAPI) => void;
          error: () => void;
          autostart: OnOffToggle;
          ui_help: OnOffToggle;
          ui_settings: OnOffToggle;
          ui_inspector: OnOffToggle;
          ui_ar: OnOffToggle;
          ui_vr: OnOffToggle;
          ui_fullscreen: OnOffToggle;
          ui_infos: OnOffToggle;
          ui_watermark: OnOffToggle;
          ui_stop: OnOffToggle;
        },
      ) => void;
    };
  }
}
