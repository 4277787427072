/* eslint-disable max-lines */
import * as React from 'react';
import { PageContainer } from 'styling/Containers.style';
import { PageHeader } from 'components/PageHeader';
import { useSelector } from 'redux/useSelector';
import { getCMSFieldById, getConstructionCMSFields } from 'redux/CMS';
import { useEffect, useState } from 'react';
import { getSelectedModel, setSelectedModelId } from 'redux/Models';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SecondaryButton } from 'components/Button';
import { PATHS } from 'routes';
import { useFetchColors } from 'redux/Colors/hooks';
import { useFetchModels } from 'redux/Models/hooks';
import { useFetchTextures } from 'redux/Textures/hooks';
import { ConstructionOption } from './ConstructionOption';
import { ConstructionType } from 'redux/Models/types';
import { getConstructionOptionDesc, getConstructionOptionTitle } from './helpers';
import { useFetchCMSFields } from 'redux/CMS/hooks';
import { setCustomerConstructionConfig } from 'redux/CustomerModelConfig';
import AsteriskText from 'components/AsteriskText';
import { OptionsContainer } from './Construction.style';

type ParamTypes = {
  modelId: string;
};

const Construction: React.FunctionComponent = () => {
  const params = useParams<ParamTypes>();
  const headerText = useSelector(getCMSFieldById('HeaderText'));
  const subHeaderText = useSelector(getCMSFieldById('SubHeaderConstructionText'));
  const helpText = useSelector(getCMSFieldById('HeaderHelpText'));
  const constructionDescs = useSelector(getConstructionCMSFields);
  const selectedModel = useSelector(getSelectedModel);
  const [, doFetchModels] = useFetchModels();
  const [, doFetchTextures] = useFetchTextures();
  const [, doFetchColors] = useFetchColors();
  const [, doFetchCMSFields] = useFetchCMSFields();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedConstruction, setSelectedConstruction] = useState<ConstructionType>(
    'threeStarClosed',
  );
  useEffect(() => {
    doFetchModels();
    doFetchTextures();
    doFetchColors();
    doFetchCMSFields();
    dispatch(setSelectedModelId(params.modelId));
  }, [
    dispatch,
    params.modelId,
    params,
    doFetchModels,
    doFetchTextures,
    doFetchColors,
    doFetchCMSFields,
  ]);

  return !selectedModel ? (
    <PageContainer>
      <PageHeader headerText={headerText} subHeaderText={subHeaderText} helpText={helpText} />
    </PageContainer>
  ) : (
    <PageContainer>
      <PageHeader headerText={headerText} subHeaderText={subHeaderText} helpText={helpText} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <OptionsContainer>
          {selectedModel.estimatedCost &&
            Object.keys(selectedModel.estimatedCost).map((key) => (
              <ConstructionOption
                key={key}
                value={key as ConstructionType}
                title={getConstructionOptionTitle(key as ConstructionType)}
                description={getConstructionOptionDesc(key as ConstructionType, constructionDescs)}
                price={selectedModel?.estimatedCost?.[key as ConstructionType] ?? 0}
                checked={key === selectedConstruction}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedConstruction(e.target.value as ConstructionType)
                }
              />
            ))}
        </OptionsContainer>
        <AsteriskText />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SecondaryButton
          onClick={() => {
            history.push(PATHS.HOME);
          }}
        >
          Go Back
        </SecondaryButton>
        <SecondaryButton
          onClick={() => {
            dispatch(
              setCustomerConstructionConfig({
                construction: selectedConstruction,
              }),
            );
            history.push(PATHS.MODELS(params.modelId));
          }}
        >
          Continue
        </SecondaryButton>
      </div>
    </PageContainer>
  );
};
export default Construction;
