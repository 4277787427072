import { initialState, reducer, setTextures } from 'redux/Textures';
import { ITextureConfig } from '../types';

describe('Textures, initialState reducer', () => {
  describe('setTextures', () => {
    it('Should set textures in a normalised manner', () => {
      const mockDatabaseReturn: ITextureConfig[] = [
        {
          id: '123',
          label: 'Texture 1',
          configs: [{ url: 'texture.jpeg', channel: 'AlbedoPBR' }],
          urlThumbnail: 'texturethumbnail.jpeg',
        },
        {
          id: '456',
          label: 'Texture 2',
          configs: [{ url: 'texture2.jpeg', channel: 'AlbedoPBR' }],
          urlThumbnail: 'texturethumbnail2.jpeg',
        },
      ];
      const action = setTextures(mockDatabaseReturn);
      const expectedState = {
        ...initialState,
        byIds: {
          '123': {
            id: '123',
            label: 'Texture 1',
            configs: [{ url: 'texture.jpeg', channel: 'AlbedoPBR' }],
            urlThumbnail: 'texturethumbnail.jpeg',
          },
          '456': {
            id: '456',
            label: 'Texture 2',
            configs: [{ url: 'texture2.jpeg', channel: 'AlbedoPBR' }],
            urlThumbnail: 'texturethumbnail2.jpeg',
          },
        },
        allIds: ['123', '456'],
      };

      expect(reducer(initialState, action)).toEqual(expectedState);
    });
  });
});
