import {
  ConfiguratorContainer,
  ConfiguratorTitle,
  OptionsContainer,
  TitleContainer,
} from './VerticalConfiguratorContainer.style';
import React from 'react';

type IProps = {
  title: string;
};

export const VerticalConfiguratorContainer: React.FunctionComponent<IProps> = ({
  title,
  children,
}) => {
  return (
    <ConfiguratorContainer>
      <TitleContainer>
        <ConfiguratorTitle>{title}</ConfiguratorTitle>
      </TitleContainer>
      <OptionsContainer>{children}</OptionsContainer>
    </ConfiguratorContainer>
  );
};
