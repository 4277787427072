import React, { useEffect, useState } from 'react';
import {
  ConfiguratorContainer,
  ConfiguratorPartitionContainer,
  FeaturesAndFinishesContainer,
  HotspotContainer,
  LeftPartitionContainer,
  ModelContainer,
  ModelContainerButtons,
} from 'pages/Models/Models.style';
import { FeatureConfigurator } from 'pages/Models/FeatureConfigurator';
import { FinishesConfigurator } from 'pages/Models/FinishesConfigurator';
import { HotspotConfigurator } from './HotspotConfigurator';
import { ModelConfigurator } from 'pages/Models/ModelConfigurator';
import { ModelButtons } from './ModelButtons';
import { IModel, ModelView } from 'redux/Models/types';
import { useSteps } from './steps';
import { useConfiguratorContext } from 'context/ConfiguratorContext';
import { ConfiguratorButtons } from './ConfiguratorButtons';
import { useResponsive } from './useResponsive';

interface IProps {
  selectedModel: IModel;
  modelId: string;
}

export const Configurator: React.FC<IProps> = ({ selectedModel, modelId }) => {
  const sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI> = React.useRef(null);
  const [cameraPosition, setCameraPosition] = useState<
    { eyePosition: [number, number, number]; targetPosition: [number, number, number] } | undefined
  >();

  const { setStepsOnLoad } = useSteps();
  const { viewType } = useConfiguratorContext();

  const { deviceSize } = useResponsive();

  useEffect(() => {
    if (selectedModel) {
      setStepsOnLoad(selectedModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModel]);

  return (
    <ConfiguratorContainer>
      <FeaturesAndFinishesContainer>
        <LeftPartitionContainer deviceSize={deviceSize}>
          {viewType === ModelView.EXPLORE && (
            <HotspotContainer>
              <HotspotConfigurator sketchfabApiRef={sketchfabApiRef} />
            </HotspotContainer>
          )}
          {viewType === ModelView.DESIGN && (
            <>
              <ConfiguratorPartitionContainer>
                <FeatureConfigurator sketchfabApiRef={sketchfabApiRef} />
              </ConfiguratorPartitionContainer>
              <FinishesConfigurator sketchfabApiRef={sketchfabApiRef} />
            </>
          )}
        </LeftPartitionContainer>
        <ConfiguratorButtons sketchfabApiRef={sketchfabApiRef} modelId={modelId} />
      </FeaturesAndFinishesContainer>
      <ModelContainer>
        <ModelConfigurator
          sketchfabModelId={selectedModel.sketchfabModelId}
          sketchfabApiRef={sketchfabApiRef}
          initialHotspot={selectedModel.hotspots.find((hotspot) => hotspot.value === 'frontview')}
          cameraConstraints={selectedModel.cameraConstraints}
          setCameraPosition={setCameraPosition}
          backgroundImageId={selectedModel.backgroundImageId}
        />
        <ModelContainerButtons deviceSize={deviceSize}>
          <ModelButtons cameraPosition={cameraPosition} sketchfabApiRef={sketchfabApiRef} />
        </ModelContainerButtons>
      </ModelContainer>
    </ConfiguratorContainer>
  );
};
