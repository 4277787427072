import React, { useState } from 'react';
import { IModel } from 'redux/Models/types';
import styled from 'styled-components';
import { getSpacing } from 'stylesheet';
import { LinkText, Paragraph } from 'styling/Typography.style';
import ReactGA from 'react-ga';
import {
  FloorPlanModalModeTypes,
  getFloorPlanUrl,
} from './FeatureConfigurator/FeatureConfigurator.helpers';
import { GenericModal } from 'components/GenericModal';

export const FloorplanLinksContainer = styled.div`
  margin-top: ${getSpacing(4)};
`;

export const FloorPlanImage = styled.img`
  max-width: ${getSpacing(120)};
  max-height: ${getSpacing(100)};
`;

type FloorplanLinksProps = {
  selectedModel: IModel | undefined;
};

export const FloorplanLinks: React.FC<FloorplanLinksProps> = ({ selectedModel }) => {
  const [showFloorPlanModal, setShowFloorPlanModal] = useState<boolean>(false);
  const [floorPlanModalMode, setFloorPlanModalMode] = useState<FloorPlanModalModeTypes>(
    FloorPlanModalModeTypes.GROUND,
  );
  return (
    <>
      <FloorplanLinksContainer>
        {selectedModel?.groundFloorPlanImgUrl && (
          <LinkText
            onClick={() => {
              ReactGA.event({
                category: 'User Action',
                action: 'Ground Floor Plan Pressed',
                label: selectedModel?.id,
              });
              setShowFloorPlanModal(!showFloorPlanModal);
              setFloorPlanModalMode(FloorPlanModalModeTypes.GROUND);
            }}
          >
            Ground Floor Plan
          </LinkText>
        )}
        {selectedModel?.firstFloorPlanImgUrl && (
          <LinkText
            onClick={() => {
              ReactGA.event({
                category: 'User Action',
                action: 'First Floor Plan Pressed',
                label: selectedModel?.id,
              });
              setShowFloorPlanModal(!showFloorPlanModal);
              setFloorPlanModalMode(FloorPlanModalModeTypes.FIRST);
            }}
          >
            First Floor Plan
          </LinkText>
        )}
      </FloorplanLinksContainer>
      {showFloorPlanModal && (
        <GenericModal showModal={showFloorPlanModal} setShowModal={setShowFloorPlanModal}>
          <FloorPlanImage src={getFloorPlanUrl({ floorPlanModalMode, selectedModel })} />
          {selectedModel?.specificationUrl && (
            <Paragraph>
              For further details on this house style, click{' '}
              <a href={selectedModel.specificationUrl} target="_blank" rel="noopener noreferrer">
                here
              </a>
            </Paragraph>
          )}
        </GenericModal>
      )}
    </>
  );
};
