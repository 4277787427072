import { initialState, reducer, setSavedCustomerFeatures } from 'redux/CustomerModelConfig';

describe('setSavedCustomerFeatures', () => {
  it('Should set features with value as object key', () => {
    const mockPayload = {
      value: 'Door',
      variantValue: 'door_handle',
    };
    const action = setSavedCustomerFeatures(mockPayload);
    const expectedState = {
      ...initialState,
      features: {
        Door: {
          value: 'Door',
          variantValue: 'door_handle',
        },
      },
    };
    expect(reducer(initialState, action)).toEqual(expectedState);
  });
});
it('Should set 2 features if 2 have been selected with variants', () => {
  const mockPayloadDoor = {
    value: 'Door',
    variantValue: 'door_handle',
  };
  const mockPayloadWindow = {
    value: 'Window',
    variantValue: 'window_1',
  };
  const selectDoor = setSavedCustomerFeatures(mockPayloadDoor);
  const expectedState1 = {
    ...initialState,
    features: {
      Door: {
        value: 'Door',
        variantValue: 'door_handle',
      },
    },
  };
  expect(reducer(initialState, selectDoor)).toEqual(expectedState1);
  const selectWindow = setSavedCustomerFeatures(mockPayloadWindow);
  const expectedState2 = {
    ...initialState,
    features: {
      Door: {
        value: 'Door',
        variantValue: 'door_handle',
      },
      Window: {
        value: 'Window',
        variantValue: 'window_1',
      },
    },
  };
  expect(reducer(expectedState1, selectWindow)).toEqual(expectedState2);
});
it('Should return 1 feature if 2 variants of the same feature have been selected', () => {
  const mockPayloadWindow1 = {
    value: 'Window',
    variantValue: 'window_1',
  };
  const mockPayloadWindow2 = {
    value: 'Window',
    variantValue: 'window_2',
  };
  const selectWindow1 = setSavedCustomerFeatures(mockPayloadWindow1);
  const expectedState1 = {
    ...initialState,
    features: {
      Window: {
        value: 'Window',
        variantValue: 'window_1',
      },
    },
  };
  expect(reducer(initialState, selectWindow1)).toEqual(expectedState1);
  const selectWindow2 = setSavedCustomerFeatures(mockPayloadWindow2);
  const expectedState2 = {
    ...initialState,
    features: {
      Window: {
        value: 'Window',
        variantValue: 'window_2',
      },
    },
  };
  expect(reducer(expectedState1, selectWindow2)).toEqual(expectedState2);
});
