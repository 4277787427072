import { Button } from 'components/Button';
import React from 'react';
import { CameraPositionButtonAndModal } from './CameraPositionButtonAndModal';

interface IProps {
  cameraPosition:
    | { eyePosition: [number, number, number]; targetPosition: [number, number, number] }
    | undefined;
  sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI>;
}

export const ModelButtons: React.FunctionComponent<IProps> = (props) => {
  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT !== 'production' ? (
        <CameraPositionButtonAndModal cameraPosition={props.cameraPosition} />
      ) : (
        <Button style={{ opacity: 0 }}>.</Button>
      )}
    </>
  );
};
