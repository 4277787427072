import React from 'react';
import { CheckboxField } from 'components/Forms';
import { render, screen, fireEvent, waitFor } from '@testing-library/react';
import { Formik } from 'formik';
import * as yup from 'yup';

const TestSchema = yup.object().shape({
  optIn: yup.bool().oneOf([true], 'You must opt in'),
});

describe('Text Fields', () => {
  it('should have a checkbox that is tickable with message', () => {
    render(
      <Formik initialValues={{ optIn: false }} onSubmit={jest.fn()}>
        <CheckboxField name="optIn" message="Do you want to tick this" />
      </Formik>,
    );
    expect(screen.queryByTestId('optIn')).toBeTruthy();
    expect(screen.queryByText('Do you want to tick this')).toBeTruthy();
    // @ts-expect-error: input value
    expect(screen.getByTestId('optIn').value).toBe('false');
    fireEvent.click(screen.getByTestId('optIn'));
    // @ts-expect-error: input value
    expect(screen.getByTestId('optIn').value).toBe('true');
  });
  it('should show an error message if required', async () => {
    render(
      <Formik initialValues={{ optIn: false }} onSubmit={jest.fn()} validationSchema={TestSchema}>
        <CheckboxField name="optIn" message="Do you want to tick this" />
      </Formik>,
    );
    expect(screen.queryByText('You must opt in')).toBeFalsy();
    fireEvent.blur(screen.getByTestId('optIn'));
    await waitFor(() => {
      expect(screen.queryByText('You must opt in')).toBeTruthy();
    });
  });
});
