import { client } from 'services/networking/client';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { AsyncFnReturn } from 'react-use/lib/useAsync';
import { useHistory } from 'react-router-dom';

export const useConvertShortLink = (): AsyncFnReturn<
  (params: { shortLink: string }) => Promise<{ error?: string }>
> => {
  const history = useHistory();
  return useTypedAsyncFn<{ shortLink: string }>(async (params) => {
    try {
      const response: { longLink: string } = await client.get(
        `/api/convertshortlink/${params.shortLink}`,
      );
      history.push(response.longLink);
      return response;
    } catch (e) {
      return {
        error: 'Error: Please check the url you entered',
      };
    }
  }, []);
};
