import { fireEvent, render, screen } from '@testing-library/react';

import { HotspotConfigurator } from 'pages/Models/HotspotConfigurator';
import React from 'react';
import { apiRefMock } from '__mocks__/sketchfabApi';
import { selectDropdown } from 'testing/testSelectHelpers';
import { setCameraLookAt } from 'services/sketchfab/camera';
import { useSelector } from 'redux/useSelector';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

const commonProps = {
  sketchfabApiRef: apiRefMock,
};

jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => mockCMSContent[id],
}));

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest.fn((fn) => fn()),
}));

jest.mock('redux/useSelector', () => ({
  useSelector: jest.fn(() => ({
    id: '123',
    sketchfabModelId: 'abc',
    name: 'House 1',
    hotspots: [
      {
        value: 'Living_Room',
        label: 'Living Room',
        eyePosition: [1, 13, 10],
        targetPosition: [1, 10, 0],
      },
      {
        value: 'Bathroom',
        label: 'Bathroom',
        eyePosition: [2, 13, 10],
        targetPosition: [2, 10, 0],
      },
      {
        value: 'exterior',
        label: 'Exterior',
        eyePosition: [3, 13, 10],
        targetPosition: [3, 10, 0],
      },
    ],
    groundFloorPlanImgUrl: 'fakeurl',
  })),
}));
const useSelectorMock = useSelector as jest.Mock<typeof useSelector>;
const setCameraLookAtMock = setCameraLookAt as jest.Mock<typeof setCameraLookAt>;
jest.mock('services/sketchfab/camera');

describe('FeatureConfigurator Component', () => {
  it('should have an Explore title', () => {
    render(<HotspotConfigurator {...commonProps} />);
    expect(screen.queryByText('Explore')).toBeTruthy();
  });
  it('should have a hotspot dropdown with default set to exterior', () => {
    render(<HotspotConfigurator {...commonProps} />);
    expect(screen.queryByText('Exterior')).toBeTruthy();
  });
  it('should have a placeholder text in place of explore dropdown if no hotspots', () => {
    // @ts-expect-error: mocking generic selector
    useSelectorMock.mockImplementationOnce(() => ({}));
    render(<HotspotConfigurator {...commonProps} />);
    expect(screen.queryByText('Exterior')).toBeFalsy();
    expect(
      screen.queryByText('Currently there are no locations available on this model'),
    ).toBeTruthy();
  });
  it('should have a 2 options in the dropdown when opened', () => {
    render(<HotspotConfigurator {...commonProps} />);
    const dropdown = screen.getByText('Exterior');
    expect(screen.queryByText('Living Room')).toBeFalsy();
    selectDropdown(dropdown);
    expect(screen.queryByText('Living Room')).toBeTruthy();
    expect(screen.queryByText('Bathroom')).toBeTruthy();
  });
  it('should call the setCameraLookAt when I select a room', () => {
    setCameraLookAtMock.mockClear();
    commonProps.sketchfabApiRef.current.setUserInteraction.mockClear();
    render(<HotspotConfigurator {...commonProps} />);
    const dropdown = screen.getByText('Exterior');
    selectDropdown(dropdown);
    fireEvent.click(screen.getByText('Living Room'));
    expect(setCameraLookAtMock).toHaveBeenCalledWith(apiRefMock, [1, 13, 10], [1, 10, 0], 3);
  });
  it('should reset the camera to the first hotspot when I click Reset Camera', () => {
    commonProps.sketchfabApiRef.current.setUserInteraction.mockClear();
    setCameraLookAtMock.mockClear();
    render(<HotspotConfigurator {...commonProps} />);
    const dropdown = screen.getByText('Exterior');
    selectDropdown(dropdown);
    fireEvent.click(screen.getByText('Bathroom'));
    expect(setCameraLookAtMock).toHaveBeenCalledWith(apiRefMock, [2, 13, 10], [2, 10, 0], 3);

    setCameraLookAtMock.mockClear();
    commonProps.sketchfabApiRef.current.setUserInteraction.mockClear();
    expect(screen.queryByText('Reset Camera')).toBeTruthy();
    fireEvent.click(screen.getByText('Reset Camera'));
    expect(setCameraLookAtMock).toHaveBeenCalledWith(apiRefMock, [1, 13, 10], [1, 10, 0], 3);
  });
  it('should have a groundFloor plan label', () => {
    render(<HotspotConfigurator {...commonProps} />);
    expect(screen.getByText('Ground Floor Plan')).toBeTruthy();
  });
});
