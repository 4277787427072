import { getAllModelGroups, getModelGroups } from 'redux/ModelGroups';

import { state } from '__fixtures__/state';

describe('ModelGroups selectors', () => {
  describe('getModelGroups', () => {
    it('should return the model groups state', () => {
      expect(getModelGroups(state)).toEqual(state.modelGroups);
    });
  });
  describe('getAllModelGroups', () => {
    it('Should return all the denormalised model groups', () => {
      expect(getAllModelGroups(state)).toEqual([
        {
          id: '123',
          name: 'name',
          title: 'title',
          index: 1,
          modelIds: ['id1', 'id2', 'id3'],
        },
      ]);
    });
  });
});
