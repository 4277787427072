import React from 'react';
import { ConfiguratorContextProvider, useConfiguratorContext } from 'context/ConfiguratorContext';
import { render, screen, fireEvent } from '@testing-library/react';
import { IStep, ModelView } from 'redux/Models/types';

const dummySteps: IStep[] = [
  { label: 'Step1', associatedFeatures: [], viewType: ModelView.DESIGN, index: 0 },
  { label: 'Step2', associatedFeatures: [], viewType: ModelView.EXPLORE, index: 1 },
];

const DummyConsumer = () => {
  const {
    selectedStep,
    setSelectedStep,
    stepOptions,
    setStepOptions,
    selectedFeature,
    setSelectedFeature,
    selectedFeatureName,
  } = useConfiguratorContext();
  return (
    <>
      {stepOptions.map((step) => (
        <div key={step.label}>Option: {step.label}</div>
      ))}
      <div>Selected Step: {selectedStep?.label}</div>
      <div>Selected Feature: {selectedFeature?.label}</div>
      <div>Selected Feature Name: {selectedFeatureName}</div>

      <button onClick={() => setSelectedStep(dummySteps[0])}>Set Selected Step</button>
      <button onClick={() => setStepOptions(dummySteps)}>Set Step Options</button>
      <button
        onClick={() => {
          setSelectedFeature({ label: 'Rob', value: 'Rob' });
        }}
      >
        Set Feature
      </button>
    </>
  );
};

describe('ConfiguratorContext', () => {
  it('should allow you to set the selected Step', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    expect(screen.queryByText('Selected Step:')).toBeTruthy();
    fireEvent.click(screen.getByText('Set Selected Step'));
    expect(screen.queryByText('Selected Step: Step1')).toBeTruthy();
  });
  it('should reset the feature when you change step', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    fireEvent.click(screen.getByText('Set Feature'));
    expect(screen.queryByText('Selected Feature: Rob')).toBeTruthy();
    expect(screen.queryByText('Selected Feature Name: Rob')).toBeTruthy();
    fireEvent.click(screen.getByText('Set Selected Step'));
    expect(screen.queryByText('Selected Feature: Rob')).toBeFalsy();
    expect(screen.queryByText('Selected Feature Name: Rob')).toBeFalsy();
  });
  it('should allow you to set the step options', () => {
    render(
      <ConfiguratorContextProvider>
        <DummyConsumer />
      </ConfiguratorContextProvider>,
    );
    expect(screen.queryAllByText(/Option:/).length).toBe(0);
    fireEvent.click(screen.getByText('Set Step Options'));
    expect(screen.queryAllByText(/Option:/).length).toBe(2);
    expect(screen.queryByText('Option: Step1')).toBeTruthy();
    expect(screen.queryByText('Option: Step2')).toBeTruthy();
  });
});
