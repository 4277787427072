import { getAllTextures, getTextures } from 'redux/Textures';

import { state } from '__fixtures__/state';

describe('Texture selectors', () => {
  describe('getTextures', () => {
    it('should return the texture state', () => {
      expect(getTextures(state)).toEqual(state.textures);
    });
  });
  describe('getAllTextures', () => {
    it('Should return all the denormalised textures', () => {
      expect(getAllTextures(state)).toEqual([
        {
          id: '123',
          label: 'Texture 1',
          configs: [
            {
              url: 'texture.jpeg',
              channel: 'AlbedoPBR',
            },
          ],
          urlThumbnail: 'texturethumbnail.jpeg',
        },
        {
          id: '456',
          label: 'Texture 2',
          configs: [
            {
              url: 'texture2.jpeg',
              channel: 'AlbedoPBR',
            },
          ],
          urlThumbnail: 'texturethumbnail2.jpeg',
        },
      ]);
    });
  });
});
