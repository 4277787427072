import { fireEvent, render, screen } from '@testing-library/react';

import React from 'react';
import { ModelInfo } from 'pages/Home/ModelInfo';
import { flattenMessages } from 'services/i18n/intl';
import enMessages from 'translations/en.json';
import { IntlProvider } from 'react-intl';
import { IModel } from 'redux/Models/types';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

const mockPush = jest.fn();

const locales = {
  en: flattenMessages(enMessages),
};
const ModelInfoIntlWrapped = (props: { model: IModel }) => (
  <IntlProvider locale="en" messages={locales.en}>
    <ModelInfo {...props} />
  </IntlProvider>
);
jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest.fn((fn) => fn()),
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => mockCMSContent[id],
}));
jest.mock('react-router-dom', () => ({
  useHistory: () => ({
    push: mockPush,
  }),
}));
jest.mock('Brand/configs', () => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  ...jest.requireActual('Brand/configs'),
  skipConstruction: false,
}));

const commonProps: { model: IModel } = {
  model: {
    id: '123',
    description: 'Big House',
    description2: 'I mean it',
    imgUrl: 'house.jpeg',
    name: 'My House',
    sketchfabModelId: 'def',
    materials: [],
    hotspots: [],
    estimatedCost: {
      oneStarOpen: 60000,
      twoStarOpen: 60000,
      twoStarClosed: 60000,
      threeStarOpen: 60000,
      threeStarClosed: 60000,
      fourStarClosed: 60000,
      fiveStarClosed: 60000,
    },
  },
};

describe('Model Info', () => {
  it('should have a title and description', () => {
    render(<ModelInfoIntlWrapped {...commonProps} />);
    expect(screen.queryByText('My House')).toBeTruthy();
    expect(screen.queryByText('Big House')).toBeTruthy();
    expect(screen.queryByText('I mean it')).toBeTruthy();
  });
  it('should go to /construction/123 when I click on it if skip construction is false', () => {
    mockPush.mockClear();
    render(<ModelInfoIntlWrapped {...commonProps} />);
    fireEvent.click(screen.getByText('My House'));
    expect(mockPush).toHaveBeenCalledWith('/construction/123');
  });
  it('should not display a cost when none is present', () => {
    render(<ModelInfoIntlWrapped {...commonProps} />);
    expect(screen.queryByText('£')).toBeFalsy();
  });
  it('should display a cost when one is present', () => {
    Object.assign(commonProps.model, {
      estimatedCost: {
        threeStarClosed: 60000,
      },
    });
    render(<ModelInfoIntlWrapped {...commonProps} />);
    expect(screen.queryByText('Prices from*: £60,000')).toBeTruthy();
  });
});
