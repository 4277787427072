import { act, renderHook } from '@testing-library/react-hooks';
import { useFetchModelGroups } from 'redux/ModelGroups/hooks';

import { client } from 'services/networking/client';
import { setModelGroups } from 'redux/ModelGroups/slice';

jest.mock('services/networking/client', () => ({
  client: {
    get: jest.fn(() => ({
      id: '789',
      index: 1,
      name: 'name',
    })),
  },
}));

jest.mock('redux/ModelGroups/slice');

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
}));

describe('useFetchModelGroups', () => {
  it('should call the getAllModelGroups endpoint', async () => {
    const { result, waitForNextUpdate } = renderHook(() => useFetchModelGroups());
    const [, doFetchModelGroups] = result.current;
    act(() => {
      doFetchModelGroups();
    });
    await waitForNextUpdate();
    expect(client.get).toHaveBeenCalledWith('/api/getmodelGroups');
    expect(setModelGroups).toHaveBeenCalledWith({ id: '789', index: 1, name: 'name' });
  });
});
