import React from 'react';
import { PageHeader } from 'components/PageHeader';
import { render, screen, fireEvent } from '@testing-library/react';
import { getCMSFieldById } from 'redux/CMS';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

const getCMSFieldByIdMock = getCMSFieldById as jest.Mock;

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest.fn((fn) => fn()),
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: jest.fn(),
}));

beforeEach(() => {
  getCMSFieldByIdMock.mockImplementation((id: string) => () => mockCMSContent[id]);
});

describe('PageHeader component', () => {
  it('should render with a header text, subheader text and help text', () => {
    render(
      <PageHeader headerText="Main Header" subHeaderText="Sub Header" helpText={'Help Text'} />,
    );
    expect(screen.queryByText('Main Header')).toBeTruthy();
    expect(screen.queryByText('Sub Header')).toBeTruthy();
    expect(screen.queryByText('Help')).toBeTruthy();
  });
  it('should open a help modal when I click help', () => {
    render(
      <PageHeader headerText="Main Header" subHeaderText="Sub Header" helpText={'Help Text'} />,
    );
    fireEvent.click(screen.getByText('Help'));
    expect(screen.queryByText('Help Text')).toBeTruthy();
    fireEvent.click(screen.getByText('Close'));
    expect(screen.queryByText('Help Text')).toBeFalsy();
  });
  it('should have a What is included link which opens a closable modal', () => {
    render(
      <PageHeader
        headerText="Main Header"
        subHeaderText="Sub Header"
        helpText={'Help Text'}
        showWhatIsIncluded={true}
      />,
    );
    expect(screen.queryByText('What is included?')).toBeTruthy();
    fireEvent.click(screen.getByText('What is included?'));
    expect(screen.queryByText('what is included text')).toBeTruthy();
    fireEvent.click(screen.getByText('Close'));
    expect(screen.queryByText('what is included text')).toBeFalsy();
  });

  it('should hide What is include link if label is empty', () => {
    getCMSFieldByIdMock.mockImplementation((id: string) => () => {
      switch (id) {
        case 'WhatIsIncludedLabel':
          return '';
        default:
          return 'blah';
      }
    });

    render(
      <PageHeader
        headerText="Main Header"
        subHeaderText="Sub Header"
        helpText={'Help Text'}
        showWhatIsIncluded={true}
      />,
    );

    expect(screen.queryByText('What is included?')).toBeFalsy();
  });

  it('should hide What is include link if text is empty', () => {
    getCMSFieldByIdMock.mockImplementation((id: string) => () => {
      switch (id) {
        case 'WhatIsIncludedLabel':
          return 'What is included?';
        case 'WhatIsIncludedText':
          return '';
        default:
          return 'blah';
      }
    });

    render(
      <PageHeader
        headerText="Main Header"
        subHeaderText="Sub Header"
        helpText={'Help Text'}
        showWhatIsIncluded={true}
      />,
    );

    expect(screen.queryByText('What is included?')).toBeFalsy();
  });
});
