import { getSpacing } from 'stylesheet';
import styled from 'styled-components';

export const ModalContents = styled.div`
  padding: 0 ${getSpacing(8)};
`;

export const CopyButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${getSpacing(4)};
  width: 100%;
  align-items: flex-start;
`;
