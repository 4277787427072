/* eslint max-lines: ["error", 175] */
import { IFeatureConfig, IVariant } from 'redux/Models/types';
import React, { useEffect, useState } from 'react';
import { Selector } from 'components/Selector';
import { SquarePicker } from 'components/SquarePicker';
import { Text } from 'styling/Typography.style';
import { VerticalConfiguratorContainer } from 'components/VerticalConfiguratorContainer';
import { StyledHorizontalContainer, FeatureSubTitle } from './FeatureConfigurator.style';
import { getSelectedModel } from 'redux/Models';
import { setSavedCustomerFeatures } from 'redux/CustomerModelConfig';
import { showVariant } from 'services/sketchfab/variant';
import { useConfiguratorContext } from 'context/ConfiguratorContext';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/useSelector';
import { setCameraLookAt } from 'services/sketchfab/camera';
import { FeatureConfiguratorSwitch } from './Switch';
import { getCMSFieldById } from 'redux/CMS';
import { onResetCameraClick } from 'pages/Models/helpers';
import { FloorplanLinks } from 'pages/Models/FloorplanLinks';
import { ResetCameraText } from 'pages/Models/Models.style';

interface IProps {
  sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI>;
}

export const FeatureConfigurator: React.FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch();
  const selectedModel = useSelector(getSelectedModel);
  const {
    replaceSelectedVariant,
    selectedStep,
    selectedFeature,
    setSelectedFeature,
  } = useConfiguratorContext();
  const [selectedVariants, setSelectedVariants] = React.useState<Record<string, string>>({});
  const [moveWithSelection, setMoveWithSelection] = useState<boolean>(true);
  const featureConfiguratorTitle = useSelector(getCMSFieldById('featureConfiguratorTitle'));
  const selectFeaturePlaceholder = useSelector(getCMSFieldById('selectFeaturePlaceholder'));
  const setSelectedVariantValue = (variantValue: string, feature: IFeatureConfig | null) => {
    if (feature) {
      setSelectedVariants({ ...selectedVariants, [feature.value]: variantValue });
    }
  };
  const onVariantChange = (variant: IVariant | undefined, feature: IFeatureConfig | null) => {
    if (variant) {
      replaceSelectedVariant(variant.value, feature?.variants || []);
      setSelectedVariantValue(variant.value, feature);
      if (feature) {
        showVariant(props.sketchfabApiRef, variant.value, feature.variants, feature.excludedNodes);
        dispatch(setSavedCustomerFeatures({ value: feature.value, variantValue: variant.value }));
      }
    }
  };
  const selectVariantIfOnlyOne = (feature: IFeatureConfig | null) => {
    if (!feature || !feature.variants || feature.variants.length > 1) {
      return;
    }
    const defaultVariant: IVariant | undefined =
      feature.variants.find((variant) => variant.default) || feature.variants[0];
    onVariantChange(defaultVariant, feature);
  };

  const setSelectedOptionHandler = (value: IFeatureConfig | null) => {
    setSelectedFeature(value);
    selectVariantIfOnlyOne(value);
    if (moveWithSelection && value?.hotspot && value?.hotspot.enabled) {
      setCameraLookAt(
        props.sketchfabApiRef,
        value.hotspot.eyePosition,
        value.hotspot.targetPosition,
        3,
      );
      props.sketchfabApiRef.current?.setUserInteraction(!value.hotspot.lockCamera);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSelectedOptionHandler(selectedFeature), [selectedFeature]);

  return (
    <VerticalConfiguratorContainer title={featureConfiguratorTitle}>
      <FeatureSubTitle>{selectFeaturePlaceholder}</FeatureSubTitle>
      {selectedStep ? (
        <>
          <StyledHorizontalContainer>
            <FeatureConfiguratorSwitch
              moveWithSelection={moveWithSelection}
              setMoveWithSelection={setMoveWithSelection}
            />
            <ResetCameraText
              onClick={() =>
                onResetCameraClick({ sketchfabApiRef: props.sketchfabApiRef, selectedModel })
              }
            >
              Reset Camera
            </ResetCameraText>
          </StyledHorizontalContainer>
          <Selector<IFeatureConfig>
            setSelectedOption={setSelectedFeature}
            options={selectedStep.associatedFeatures}
            placeholder={selectFeaturePlaceholder}
            selectedOption={selectedFeature}
          />
          {selectedFeature && selectedFeature.variants && (
            <SquarePicker<IVariant | undefined>
              options={selectedFeature.variants}
              onOptionChange={(variant) => onVariantChange(variant, selectedFeature)}
              isOptionSelected={(variant) =>
                variant ? variant.value === selectedVariants[selectedFeature.value] : false
              }
              width={selectedFeature.width || 8}
              height={selectedFeature.height || 8}
            />
          )}
        </>
      ) : (
        <Text>Currently there are no features to change on this model</Text>
      )}
      <FloorplanLinks selectedModel={selectedModel} />
    </VerticalConfiguratorContainer>
  );
};
