import { getCustomerModelConfig } from 'redux/CustomerModelConfig';

import { state } from '__fixtures__/state';

describe('CustomerModelConfig selectors', () => {
  describe('getCustomerModelConfig', () => {
    it('should return the getCustomerModelConfig state', () => {
      expect(getCustomerModelConfig(state)).toEqual(state.customerModelConfig);
    });
  });
});
