import {
  CopyButtonContainer,
  EmailContainer,
  ModalContents,
} from 'pages/Models/SaveModelButtonAndModal.style';
import { ErrorText, SubTitle, Text } from 'styling/Typography.style';
import React, { useState } from 'react';
import { GenericModal } from 'components/GenericModal';
import { SaveModelForm } from 'pages/Models/SaveModelForm';
import { SecondaryButton } from 'components/Button';
import { getCustomerModelConfig } from 'redux/CustomerModelConfig';
import { useCreateCustomerModelConfig } from 'redux/CustomerModelConfig/hooks';
import { useSelector } from 'redux/useSelector';
import ReactGA from 'react-ga';
import { ActionButton } from './Models.style';
import { useResponsive } from './useResponsive';

type IProps = {
  modelId: string;
};

export const SaveModelButtonAndModal: React.FunctionComponent<IProps> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hasCopied, setHasCopied] = useState<boolean>(false);
  const customerModelConfig = useSelector(getCustomerModelConfig);
  const [createCustomerModelState, doCreateCustomerModelConfig] = useCreateCustomerModelConfig();

  const { deviceSize } = useResponsive();

  const getCustomerLink = () =>
    `${window.location.origin}${createCustomerModelState.value?.shortLink}`;
  const CopyButton = () => (
    <CopyButtonContainer>
      <SecondaryButton
        onClick={() => {
          navigator.clipboard.writeText(getCustomerLink());
          setHasCopied(true);
        }}
      >
        Copy
      </SecondaryButton>
      {hasCopied ? <Text>Copied to Clipboard</Text> : <Text />}
    </CopyButtonContainer>
  );

  return (
    <>
      <ActionButton
        disabled={createCustomerModelState.loading}
        onClick={() => {
          ReactGA.event({
            category: 'User Action',
            action: 'Opened Save Modal',
            label: props.modelId,
          });
          doCreateCustomerModelConfig(customerModelConfig);
          setShowModal(true);
        }}
        deviceSize={deviceSize}
      >
        Save
      </ActionButton>
      {showModal && (
        <GenericModal
          showModal={showModal}
          setShowModal={setShowModal}
          ExtraButtonsComponent={CopyButton}
        >
          <ModalContents>
            {createCustomerModelState.value?.error && (
              <ErrorText>{createCustomerModelState.value.error}</ErrorText>
            )}
            {!createCustomerModelState.value?.error && createCustomerModelState.value?.id && (
              <>
                <SubTitle>Please save the link below for your model</SubTitle>
                <Text>{getCustomerLink()}</Text>
              </>
            )}
            <EmailContainer>
              <SaveModelForm modelId={props.modelId} getCustomerLink={getCustomerLink} />
            </EmailContainer>
          </ModalContents>
        </GenericModal>
      )}
    </>
  );
};
