import { act, renderHook } from '@testing-library/react-hooks';
import { useFetchColors } from 'redux/Colors/hooks';

import { client } from 'services/networking/client';
import { setColors } from 'redux/Colors';

jest.mock('services/networking/client', () => ({
  client: {
    get: jest.fn(() => ({
      id: '789',
    })),
  },
}));

jest.mock('redux/Colors');

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
}));

describe('useFetchColors', () => {
  it('should call the getColors endpoint', async () => {
    const { result, waitForNextUpdate } = renderHook(() => useFetchColors());
    const [, doFetchColors] = result.current;
    act(() => {
      doFetchColors();
    });
    await waitForNextUpdate();
    expect(client.get).toHaveBeenCalledWith('/api/getcolors');
    expect(setColors).toHaveBeenCalledWith({ id: '789' });
  });
});
