import { initialState, reducer, setModelGroups } from 'redux/ModelGroups';

describe('setModelGroups, initialState reducer', () => {
  describe('setModelGroups', () => {
    it('Should set model groups in a normalised manner', () => {
      const mockDatabaseReturn = [
        {
          id: '123',
          name: 'name',
          title: 'title',
          index: 1,
          modelIds: ['id1', 'id2', 'id3'],
        },
      ];
      const action = setModelGroups(mockDatabaseReturn);
      const expectedState = {
        ...initialState,
        byIds: {
          '123': {
            id: '123',
            name: 'name',
            title: 'title',
            index: 1,
            modelIds: ['id1', 'id2', 'id3'],
          },
        },
        allIds: ['123'],
      };

      expect(reducer(initialState, action)).toEqual(expectedState);
    });
  });
});
