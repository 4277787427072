/* eslint max-lines: ["error", 116] */
import { SelectedOption } from 'components/Selector';

export interface IAvailableTexture {
  textureId: string;
  default?: boolean;
}

export interface IVariantNode {
  value: string;
}

export interface IVariant extends SelectedOption {
  default?: boolean;
  urlThumbnail: string;
  variantNodes?: IVariantNode[];
}

export interface IMaterialConfig extends SelectedOption {
  availableTextures?: IAvailableTexture[];
  associatedVariants?: { value: string }[];
  associatedFeatures?: { value: string }[];
  finishHeading?: string;
}

export interface IFeatureConfig extends SelectedOption {
  excludedNodes?: string[];
  variants?: IVariant[];
  hotspot?: IHotspotConfig;
  width?: number;
  height?: number;
}

export interface IHotspotConfig extends SelectedOption {
  eyePosition: [number, number, number];
  targetPosition: [number, number, number];
  lockCamera?: boolean;
  enabled?: boolean;
}

export interface IStepConfig {
  label: string;
  associatedFeatures: { value: string }[];
}

export interface IModel {
  id: string;
  sketchfabModelId: string;
  backgroundImageId?: string;
  name: string;
  description: string;
  description2?: string;
  estimatedCost: IEstimatedCost;
  cameraConstraints?: ICameraConstraints;
  imgUrl: string;
  groundFloorPlanImgUrl?: string;
  firstFloorPlanImgUrl?: string;
  features?: IFeatureConfig[];
  materials: IMaterialConfig[];
  hotspots: IHotspotConfig[];
  steps?: IStepConfig[];
  specificationUrl?: string;
  showStepper?: boolean;
}

export type ConstructionType =
  | 'oneStarOpen'
  | 'twoStarOpen'
  | 'twoStarClosed'
  | 'threeStarOpen'
  | 'threeStarClosed'
  | 'fourStarClosed'
  | 'fiveStarClosed';

export type IEstimatedCost = {
  [key in ConstructionType]: number;
};

export interface IReturnedModel extends IModel {
  error?: string;
  success?: string;
}

export type ConfigurableRequestAQuoteFormValues = {
  typeOfEnquiry: string;
  budgetRange: string;
  plotOwned: string;
  planningStatus: string;
  buildDateStartRange: string;
  additionalInfo: string;
  yourMessage: string;
  postcode: string;
  company: string;
};
export type RequestAQuoteFormValues = ConfigurableRequestAQuoteFormValues & {
  firstName: string;
  lastName: string;
  emailAddress: string;
  contactNumber: string;
  location: string;
  optInContact: boolean;
  emailCheckbox: boolean;
  phoneCheckbox: boolean;
};

export enum ModelView {
  DESIGN = 'design',
  EXPLORE = 'explore',
}
export interface IStep {
  label: string;
  associatedFeatures: IFeatureConfig[];
  viewType: ModelView;
  index: number;
}
