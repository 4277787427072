/* eslint-disable max-lines */
import brandData from './Brand/configs.json';
import { createMuiTheme } from '@material-ui/core/styles';
/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 */
const SPACING_UNIT = 5;
const MEASUREMENT_UNIT = 'px';

/**
 * Use this dictionnary in your components
 * Define a new key each time you use a colour if it's for a different use
 * Ex: fill, border-color, background-color, color ...
 * Remember to update backend to return new color hex
 */
export const colorUsage = {
  headerBackground: brandData.Colors.headerBackground,
  footerBackground: brandData.Colors.footerBackground,
  primaryTextColor: brandData.Colors.primaryTextColor,
  primary: brandData.Colors.primary,
  primaryDark: brandData.Colors.primaryDark,
  contentBackground: brandData.Colors.contentBackground,
  secondaryBackground: brandData.Colors.secondaryBackground,
  linkColor: brandData.Colors.linkColor,
  linkColorHover: brandData.Colors.linkColorHover,
  linkColorDisabled: brandData.Colors.linkColorDisabled,
  codeColor: brandData.Colors.codeColor,
  primaryButtonColor: brandData.Colors.primaryButtonColor,
  primaryButtonBackground: brandData.Colors.primaryButtonBackground,
  primaryButtonBackgroundHover: brandData.Colors.primaryButtonBackgroundHover,
  primaryButtonBackgroundDisabled: brandData.Colors.primaryButtonBackgroundDisabled,
  secondaryButtonColor: brandData.Colors.secondaryButtonColor,
  secondaryButtonBackground: brandData.Colors.secondaryButtonBackground,
  secondaryButtonBackgroundHover: brandData.Colors.secondaryButtonBackgroundHover,
  secondaryButtonBackgroundDisabled: brandData.Colors.secondaryButtonBackgroundDisabled,
  loaderColorDefault: brandData.Colors.loaderColorDefault,
  error: brandData.Colors.error,
  inputBackground: brandData.Colors.inputBackground,
  inputBorderColor: brandData.Colors.inputBorderColor,
  inputPlaceholderColor: brandData.Colors.inputPlaceholderColor,
  borderColor: brandData.Colors.borderColor,
  selectedBorderColor: brandData.Colors.selectedBorderColor,
  actionTextColor: brandData.Colors.actionTextColor,
  headerLinkTextColor: brandData.Colors.headerLinkTextColor,
  footerLinkTextColor: brandData.Colors.footerLinkTextColor,
  titleBackground: brandData.Colors.titleBackground,
  selectBorder: brandData.Colors.selectBorder,
  asteriskText: brandData.Colors.asteriskText,
  navigationText: brandData.Colors.navigationText,
  navigationBackground: brandData.Colors.navigationBackground,
  togglePrimary: brandData.Colors.togglePrimary,
  toggleSecondary: brandData.Colors.toggleSecondary,
  stepperLabelActive: brandData.Colors.stepperLabelActive,
  stepperLabelInactive: brandData.Colors.stepperLabelInactive,
  selectFocusedBorder: brandData.Colors.selectFocusedBorder,
  selectOptionFocused: brandData.Colors.selectOptionFocused,
  selectOptionSelected: brandData.Colors.selectOptionSelected,
};

export const fontFamily = {
  main: brandData.Fonts.main,
  code: brandData.Fonts.code,
  header: brandData.Fonts.header,
};

export const fontSize = {
  XXLarge: '40px',
  XLarge: '25px',
  large: '20px',
  medium: '16px',
  small: '14px',
  XSmall: '11px',
};

export const fontWeight = {
  bold: '700',
  normal: '400',
  light: '300',
};

export const lineHeight = {
  large: '36px',
  medium: '24px',
  small: '11px',
};

export const borderRadius = {
  medium: '4px',
  large: '10px',
};

export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;

export const muiTheme = createMuiTheme({
  overrides: {
    MuiStepLabel: {
      label: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: fontSize.medium,
        backgroundColor: colorUsage.contentBackground,
        width: '200px',
        height: '70px',
        padding: '0px 5px',
        color: colorUsage.stepperLabelInactive,
        '&$active': {
          backgroundColor: colorUsage.secondaryBackground,
          color: colorUsage.stepperLabelActive,
          textShadow: `0px 0px 1px ${colorUsage.stepperLabelActive}`,
        },
        cursor: 'pointer',
      },
    },
    MuiStep: {
      horizontal: {
        marginRight: '1px',
        marginLeft: '1px',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiStepper: {
      root: {
        paddingTop: '0',
        backgroundColor: colorUsage.secondaryBackground,
      },
    },
  },
});
