import React from 'react';
import { ActionButtonsContainer } from './Models.style';
import { RequestAQuoteButtonAndModal } from './RequestAQuoteButtonAndModal';
import { SaveModelButtonAndModal } from './SaveModelButtonAndModal';
import { TakePhotoButton } from './TakePhotoButton';
import { ModelStepperButtons } from './ModelStepperButtons';
import { Spacer } from 'components/Spacer';
import brandData from 'Brand/configs.json';
import { DeviceSize, useResponsive } from './useResponsive';

type ConfiguratorButtonProps = {
  modelId: string;
  sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI>;
};

export const ConfiguratorButtons: React.FC<ConfiguratorButtonProps> = ({
  modelId,
  sketchfabApiRef,
}) => {
  const { deviceSize } = useResponsive();

  return (
    <ActionButtonsContainer deviceSize={deviceSize}>
      <RequestAQuoteButtonAndModal modelId={modelId} />
      <SaveModelButtonAndModal modelId={modelId} />
      <TakePhotoButton sketchfabApiRef={sketchfabApiRef} />
      <Spacer.Flex />
      {!brandData.hideStepper && deviceSize !== DeviceSize.Phone && <ModelStepperButtons />}
    </ActionButtonsContainer>
  );
};
