/* eslint max-lines: ["error", 189] */
/* Ignored error for mock files */
const mockChannel = {
  enable: true,
  factor: 1,
};

export const mockMaterials: IMaterial[] = [
  {
    cullFace: 'string',
    id: 'string',
    name: 'Cladding',
    reflection: 2,
    shadeless: true,
    stateSetID: 12,
    version: 144,
    channels: {
      AlbedoPBR: { ...mockChannel, color: [0.1, 0.2, 0.3] },
      Anisotropy: mockChannel,
      AOPBR: mockChannel,
      BumpMap: mockChannel,
      CavityPBR: mockChannel,
      ClearCoat: mockChannel,
      ClearCoatNormalMap: mockChannel,
      ClearCoatRoughness: mockChannel,
      DiffuseColor: mockChannel,
      DiffuseIntensity: mockChannel,
      DiffusePBR: mockChannel,
      Displacement: mockChannel,
      EmitColor: mockChannel,
      GlossinessPBR: mockChannel,
      Matcap: mockChannel,
      MetalnessPBR: mockChannel,
      NormalMap: mockChannel,
      Opacity: mockChannel,
      RoughnessPBR: mockChannel,
      SpecularHardness: mockChannel,
      SubsurfaceScattering: mockChannel,
      SubsurfaceTranslucency: mockChannel,
      SpecularColor: mockChannel,
      SpecularPBR: mockChannel,
      SpecularF0: mockChannel,
    },
  },
  {
    cullFace: 'string',
    id: 'string',
    name: 'Door Frame',
    reflection: 3,
    shadeless: true,
    stateSetID: 124,
    version: 399,
    channels: {
      AlbedoPBR: { ...mockChannel, color: [0.1, 0.12, 0.13] },
      Anisotropy: mockChannel,
      AOPBR: mockChannel,
      BumpMap: mockChannel,
      CavityPBR: mockChannel,
      ClearCoat: mockChannel,
      ClearCoatNormalMap: mockChannel,
      ClearCoatRoughness: mockChannel,
      DiffuseColor: mockChannel,
      DiffuseIntensity: mockChannel,
      DiffusePBR: mockChannel,
      Displacement: mockChannel,
      EmitColor: mockChannel,
      GlossinessPBR: mockChannel,
      Matcap: mockChannel,
      MetalnessPBR: mockChannel,
      NormalMap: mockChannel,
      Opacity: mockChannel,
      RoughnessPBR: mockChannel,
      SpecularHardness: mockChannel,
      SubsurfaceScattering: mockChannel,
      SubsurfaceTranslucency: mockChannel,
      SpecularColor: mockChannel,
      SpecularPBR: mockChannel,
      SpecularF0: mockChannel,
    },
  },
];

const mockNodes: Record<number, INode> = {
  '20': {
    name: 'Roof1',
    instanceID: 20,
    nodeMask: -1,
    materialID: '8bc4312e-0d15-4347-8376-f32d9afb7d0c',
    children: [],
    type: 'MatrixTransform',
  },
  '82': {
    name: 'Cladding2',
    instanceID: 82,
    nodeMask: -1,
    materialID: '34',
    children: [],
    type: 'MatrixTransform',
  },
  '96': {
    name: 'TestExclNode',
    instanceID: 96,
    nodeMask: -1,
    materialID: '34',
    children: [],
    type: 'MatrixTransform',
  },
  '95': {
    name: 'Cladding1',
    instanceID: 95,
    nodeMask: -1,
    materialID: '34',
    children: [],
    type: 'MatrixTransform',
  },
  '22': {
    name: 'Roof2',
    instanceID: 22,
    nodeMask: -1,
    materialID: '34',
    children: [],
    type: 'MatrixTransform',
  },
  '23': {
    name: 'Roof2Beams',
    instanceID: 23,
    nodeMask: -1,
    materialID: '349283',
    children: [],
    type: 'MatrixTransform',
  },
  '78': {
    name: 'Window1Frame',
    instanceID: 78,
    nodeMask: -1,
    materialID: '349283',
    children: [],
    type: 'MatrixTransform',
  },
  '79': {
    name: 'Window2Frame',
    instanceID: 79,
    nodeMask: -1,
    materialID: '349283',
    children: [],
    type: 'MatrixTransform',
  },
  '80': {
    name: 'Window3Frame',
    instanceID: 80,
    nodeMask: -1,
    materialID: '349283',
    children: [],
    type: 'MatrixTransform',
  },
};

export const apiRefMock: { current: jest.Mocked<ISketchfabAPI> } = {
  current: {
    getMaterialList: jest.fn((callback: (err: string, materials: IMaterial[]) => void): void => {
      callback('', mockMaterials);
    }),
    setMaterial: jest.fn(),
    addEventListener: jest.fn(),
    addTexture: jest.fn(
      (url: string, callback?: (err: string, textureUid: string) => void): void => {
        if (callback) {
          callback('', 'mockTextureUid');
        }
      },
    ),
    getNodeMap: jest.fn((callback: (err: string, nodes: Record<number, INode>) => void): void => {
      callback('', mockNodes);
    }),
    getTextureList: jest.fn(),
    hide: jest.fn(),
    setBackground: jest.fn(),
    setUVScale: jest.fn(),
    show: jest.fn(),
    start: jest.fn(),
    setCameraLookAt: jest.fn(),
    setUserInteraction: jest.fn(),
    setCameraConstraints: jest.fn(),
    getCameraLookAt: jest.fn(),
    getScreenShot: jest.fn(),
    setEnableCameraConstraints: jest.fn(),
  },
};
