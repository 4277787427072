/* eslint max-lines: ["error", 200] */
/* Consider refactor if another hook is added */
import { ICustomerModelConfig, IReturnedCustomerModelConfig } from './types';

import { AsyncFnReturn } from 'react-use/lib/useAsync';
import { IReturnedModel } from 'redux/Models/types';
import { client } from 'services/networking/client';
import { setCustomerModelConfig } from 'redux/CustomerModelConfig';
import { setSelectedModelId } from 'redux/Models';
import { useDispatch, useSelector } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { getCMSFieldById } from 'redux/CMS';

interface ISaveEmailProps {
  email: string;
  customerModelConfig: ICustomerModelConfig;
  modelId: string;
  modelLink: string;
  optIn: boolean;
  constructionLabel: string;
  constructionPrice: string;
}

interface IRequestEmailProps {
  customerModelConfig: ICustomerModelConfig;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  modelId: string;
  constructionLabel: string;
  constructionPrice: string;
  modelLink: string;
  location: string;
  typeOfEnquiry: string;
  budgetRange: string;
  plotOwned: string;
  planningStatus: string;
  buildDateStartRange: string;
  additionalInfo: string;
  yourMessage: string;
  postcode: string;
  company: string;
  emailCheckbox: boolean;
  phoneCheckbox: boolean;
}

interface IReturnedRequestEmailProps extends IRequestEmailProps {
  error?: string;
  success?: string;
}

export const useCreateCustomerModelConfig = (): AsyncFnReturn<
  (...args: ICustomerModelConfig[]) => Promise<IReturnedCustomerModelConfig>
> => {
  return useTypedAsyncFn<ICustomerModelConfig>(async (customerModelConfig) => {
    try {
      const response = await client.post('/api/createcustomermodelconfig', customerModelConfig);
      return response;
    } catch (e) {
      return {
        error: 'Failed to save your config. Try Again',
      };
    }
  }, []);
};

export const useSendCustomerModelEmail = (): AsyncFnReturn<
  (...args: ISaveEmailProps[]) => Promise<IReturnedModel>
> => {
  return useTypedAsyncFn<{
    email: string;
    customerModelConfig: ICustomerModelConfig;
    modelId: string;
    modelLink: string;
    optIn: boolean;
    constructionLabel: string;
    constructionPrice: string;
  }>(
    async ({
      email,
      customerModelConfig,
      modelId,
      modelLink,
      optIn,
      constructionLabel,
      constructionPrice,
    }: ISaveEmailProps) => {
      try {
        await client.post(`/api/sendcustomermodelemail/${modelId}`, {
          email,
          customerModelConfig,
          modelLink,
          optIn,
          constructionLabel,
          constructionPrice,
        });
        return { success: 'Your email has been sent' };
      } catch (e) {
        return {
          error: 'Something went wrong, please try again later',
        };
      }
    },
    [],
  );
};

export const useSendCustomerQuoteEmail = (): AsyncFnReturn<
  (...args: IRequestEmailProps[]) => Promise<IReturnedRequestEmailProps>
> => {
  const requestAQuoteSuccessMessage = useSelector(getCMSFieldById('requestAQuoteSuccessMessage'));

  return useTypedAsyncFn<IRequestEmailProps>(
    async ({
      customerModelConfig,
      email,
      name,
      firstName,
      lastName,
      phoneNumber,
      modelId,
      constructionLabel,
      constructionPrice,
      modelLink,
      location,
      typeOfEnquiry,
      budgetRange,
      plotOwned,
      planningStatus,
      buildDateStartRange,
      company,
      yourMessage,
      postcode,
      additionalInfo,
      emailCheckbox,
      phoneCheckbox,
    }: IRequestEmailProps) => {
      try {
        await client.post(`/api/sendcustomerquoteemail/${modelId}`, {
          customerModelConfig,
          name,
          firstName,
          lastName,
          phoneNumber,
          email,
          constructionLabel,
          constructionPrice,
          modelLink,
          location,
          typeOfEnquiry,
          budgetRange,
          plotOwned,
          planningStatus,
          buildDateStartRange,
          additionalInfo,
          company,
          yourMessage,
          postcode,
          emailCheckbox,
          phoneCheckbox,
        });
        return {
          success: requestAQuoteSuccessMessage,
        };
      } catch (e) {
        return {
          error: 'Failed to send email. Please try again.',
        };
      }
    },
    [],
  );
};

export const useFetchCustomerModelConfig = (): AsyncFnReturn<
  (customerModelConfigId: {
    customerModelConfigId: string;
    modelId: string;
  }) => Promise<ICustomerModelConfig>
> => {
  const dispatch = useDispatch();
  return useTypedAsyncFn<{ customerModelConfigId: string; modelId: string }>(async (params) => {
    try {
      const response: ICustomerModelConfig = await client.get(
        `/api/getcustomermodelconfig/${params.customerModelConfigId}`,
      );
      dispatch(setCustomerModelConfig(response));
      dispatch(setSelectedModelId(params.modelId));
      return response;
    } catch (e) {
      return {
        error: e,
      };
    }
  }, []);
};
