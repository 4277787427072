/* eslint max-lines: ["error", 165] */
import { act, renderHook } from '@testing-library/react-hooks';
import {
  useCreateCustomerModelConfig,
  useFetchCustomerModelConfig,
  useSendCustomerModelEmail,
  useSendCustomerQuoteEmail,
} from 'redux/CustomerModelConfig/hooks';

import { client } from 'services/networking/client';
import { setCustomerModelConfig } from 'redux/CustomerModelConfig';
import { setSelectedModelId } from 'redux/Models';

jest.mock('services/networking/client', () => ({
  client: {
    post: jest.fn(),
    get: jest.fn(() => ({
      id: '789',
    })),
  },
}));

jest.mock('redux/Models');
jest.mock('redux/CustomerModelConfig');

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: () => jest.fn(() => 'SuccessMessage'),
}));

test('should call the createcustomermodelconfig endpoint', async () => {
  const { result, waitForNextUpdate } = renderHook(() => useCreateCustomerModelConfig());
  const [, doCreateCustomerModelConfig] = result.current;
  act(() => {
    doCreateCustomerModelConfig({
      modelId: '123',
      features: {},
      materials: {},
      construction: 'threeStarClosed',
    });
  });
  await waitForNextUpdate();
  expect(client.post).toHaveBeenCalledWith('/api/createcustomermodelconfig', {
    modelId: '123',
    features: {},
    materials: {},
    construction: 'threeStarClosed',
  });
});

test('should call the getcustomermodelconfig endpoint', async () => {
  const { result, waitForNextUpdate } = renderHook(() => useFetchCustomerModelConfig());
  const [, doFetchCustomerModelConfig] = result.current;
  act(() => {
    doFetchCustomerModelConfig({ modelId: 'abc', customerModelConfigId: '123' });
  });
  await waitForNextUpdate();
  expect(client.get).toHaveBeenCalledWith('/api/getcustomermodelconfig/123');
  expect(setSelectedModelId).toHaveBeenCalledWith('abc');
  expect(setCustomerModelConfig).toHaveBeenCalledWith({ id: '789' });
});

test('should call the sendCustomerModelEmail endpoint', async () => {
  const { result, waitForNextUpdate } = renderHook(() => useSendCustomerModelEmail());
  const [, doSendCustomerModelEmail] = result.current;
  act(() => {
    doSendCustomerModelEmail({
      email: 'test@test.co.uk',
      customerModelConfig: {
        modelId: 'house-123',
        features: {},
        materials: {},
        construction: 'threeStarClosed',
      },
      modelId: '123',
      modelLink: 'https://coolhouse.com',
      optIn: false,
      constructionLabel: '3* - Closed',
      constructionPrice: '£60,000',
    });
  });
  await waitForNextUpdate();
  expect(client.post).toHaveBeenCalledWith('/api/sendcustomermodelemail/123', {
    email: 'test@test.co.uk',
    customerModelConfig: {
      modelId: 'house-123',
      features: {},
      materials: {},
      construction: 'threeStarClosed',
    },
    modelLink: 'https://coolhouse.com',
    optIn: false,
    constructionLabel: '3* - Closed',
    constructionPrice: '£60,000',
  });
});

test('should call the sendCustomerQuoteEmail endpoint', async () => {
  const { result, waitForNextUpdate } = renderHook(() => useSendCustomerQuoteEmail());
  const [, doSendCustomerQuoteEmail] = result.current;
  act(() => {
    doSendCustomerQuoteEmail({
      customerModelConfig: {
        modelId: 'house-123',
        features: {},
        materials: {},
        construction: 'threeStarClosed',
      },
      name: 'Sarah',
      firstName: 'Sarah',
      lastName: 'Hamilton',
      phoneNumber: '07913331230',
      email: 'test@test.co.uk',
      modelId: '123',
      constructionLabel: '3* - Closed',
      constructionPrice: '£60,000',
      modelLink: 'mockLink',
      location: 'mockLocation',
      typeOfEnquiry: 'mockType',
      budgetRange: 'mockBudget',
      plotOwned: 'Yes',
      planningStatus: 'mockStatus',
      buildDateStartRange: 'mockDate',
      additionalInfo: 'mockInfo',
      yourMessage: 'hello',
      postcode: 'swdsf',
      company: 'theodo',
      emailCheckbox: true,
      phoneCheckbox: true,
    });
  });
  await waitForNextUpdate();
  expect(client.post).toHaveBeenCalledWith('/api/sendcustomerquoteemail/123', {
    customerModelConfig: {
      modelId: 'house-123',
      features: {},
      materials: {},
      construction: 'threeStarClosed',
    },
    name: 'Sarah',
    firstName: 'Sarah',
    lastName: 'Hamilton',
    phoneNumber: '07913331230',
    email: 'test@test.co.uk',
    constructionLabel: '3* - Closed',
    constructionPrice: '£60,000',
    modelLink: 'mockLink',
    location: 'mockLocation',
    typeOfEnquiry: 'mockType',
    budgetRange: 'mockBudget',
    plotOwned: 'Yes',
    planningStatus: 'mockStatus',
    buildDateStartRange: 'mockDate',
    additionalInfo: 'mockInfo',
    yourMessage: 'hello',
    postcode: 'swdsf',
    company: 'theodo',
    emailCheckbox: true,
    phoneCheckbox: true,
  });
});
