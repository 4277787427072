import React from 'react';
import { ModelViewer } from 'components/ModelViewer';
import { render } from '@testing-library/react';
import { ICustomerModelConfig } from 'redux/CustomerModelConfig/types';
import { IModel } from 'redux/Models/types';
import { ITextureConfig } from 'redux/Textures/types';
import { initMock } from 'testing/windowMock';
import { setAllDefaultColors } from 'services/sketchfab/color';
import { setAllDefaultVariants, setAllCustomerVariants } from 'services/sketchfab/variant';
import { setAllCustomerTextures } from 'services/sketchfab/texture';

jest.mock('redux/useSelector', () => ({
  useSelector: jest.fn((fn) => fn()),
}));
jest.mock('redux/Models', () => ({
  getSelectedModel: (): IModel => ({
    id: '123',
    description: 'Housey House',
    imgUrl: 'house.jpeg',
    sketchfabModelId: 'abc',
    name: 'House 1',
    materials: [
      {
        value: 'Alu ',
        label: 'Alu',
      },
    ],
    hotspots: [],
    estimatedCost: {
      oneStarOpen: 60000,
      twoStarOpen: 60000,
      twoStarClosed: 60000,
      threeStarOpen: 60000,
      threeStarClosed: 60000,
      fourStarClosed: 60000,
      fiveStarClosed: 60000,
    },
  }),
}));
jest.mock('redux/CustomerModelConfig', () => ({
  getCustomerModelConfig: jest.fn(
    (): ICustomerModelConfig => ({
      modelId: '123',
      features: {},
      materials: {},
      construction: 'threeStarClosed',
    }),
  ),
}));
jest.mock('redux/Textures', () => ({
  getAllTextures: (): ITextureConfig[] => [
    {
      id: 'texture-23',
      label: 'Texture 23',
      configs: [{ url: 'text.jpg', channel: 'AlbedoPBR' }],
      urlThumbnail: 'thumb.jpeg',
    },
  ],
}));
jest.mock('react', () => ({
  ...require.requireActual('react'),
  useState: jest.fn(() => ['api', jest.fn()]),
}));
jest.mock('services/sketchfab/color');
jest.mock('services/sketchfab/variant');
jest.mock('services/sketchfab/texture');

describe('Model Viewer', () => {
  const commonProps = {
    setModelLoaded: jest.fn(),
    sketchfabModelId: 'sketchfab-123',
    apiRef: { current: null },
    initialHotspot: undefined,
    cameraConstraints: undefined,
    setCameraPosition: jest.fn(),
    backgroundImageId: undefined,
  };
  const selectedModel = {
    id: '123',
    description: 'Housey House',
    imgUrl: 'house.jpeg',
    sketchfabModelId: 'abc',
    name: 'House 1',
    materials: [
      {
        value: 'Alu ',
        label: 'Alu',
      },
    ],
    hotspots: [],
    estimatedCost: {
      oneStarOpen: 60000,
      twoStarOpen: 60000,
      twoStarClosed: 60000,
      threeStarOpen: 60000,
      threeStarClosed: 60000,
      fourStarClosed: 60000,
      fiveStarClosed: 60000,
    },
  };
  const textures: ITextureConfig[] = [
    {
      id: 'texture-23',
      label: 'Texture 23',
      configs: [{ url: 'text.jpg', channel: 'AlbedoPBR' }],
      urlThumbnail: 'thumb.jpeg',
    },
  ];
  const customerModelConfig = {
    modelId: '123',
    features: {},
    materials: {},
    construction: 'threeStarClosed',
  };
  it('should render the correct sketch fab model', () => {
    render(<ModelViewer {...commonProps} />);
    expect(initMock).toHaveBeenCalledWith('sketchfab-123', {
      success: expect.any(Function),
      error: expect.any(Function),
      autostart: 1,
      ui_help: 1,
      ui_settings: 0,
      ui_inspector: 0,
      ui_ar: 0,
      ui_vr: 0,
      ui_fullscreen: 1,
      ui_infos: 0,
      ui_watermark: 0,
      ui_stop: 0,
    });
  });
  it('should call all the set functions when the viewer is ready', () => {
    render(<ModelViewer {...commonProps} />);
    expect(commonProps.setModelLoaded).toHaveBeenCalledWith('sketchfab-123');
    expect(setAllDefaultColors).toHaveBeenCalledWith(selectedModel, textures, expect.any(Object));
    expect(setAllDefaultVariants).toHaveBeenCalledWith(selectedModel, expect.any(Object));
    expect(setAllCustomerVariants).toHaveBeenCalledWith(
      selectedModel,
      customerModelConfig,
      expect.any(Object),
    );
    expect(setAllCustomerTextures).toHaveBeenCalledWith(
      expect.any(Object),
      customerModelConfig,
      textures,
    );
  });
});
