import { apiRefMock, mockMaterials } from '__mocks__/sketchfabApi';
import { setAllCustomerTextures } from 'services/sketchfab/texture';
import { ITextureConfig } from 'redux/Textures/types';
import { ICustomerModelConfig } from 'redux/CustomerModelConfig/types';
import { waitFor } from '@testing-library/react';

const customerModelConfig: ICustomerModelConfig = {
  modelId: "Will's House",
  features: {
    frontDoor: {
      value: 'frontDoor',
      variantValue: 'frontDoor_02',
    },
  },
  materials: {
    Cladding: {
      value: 'Cladding',
      texture: {
        textureId: 'texture-123',
        color: { label: '', ralName: '', hexCode: '#ffffff' },
      },
    },
    'Door Frame': {
      value: 'Door Frame',
      texture: {
        textureId: 'texture-789',
      },
    },
  },
  construction: 'threeStarClosed',
};

const textures: ITextureConfig[] = [
  {
    id: 'texture-123',
    label: 'Texture 123',
    urlThumbnail: 'wood.jpeg',
    configs: [
      {
        channel: 'AlbedoPBR',
      },
    ],
  },
  {
    id: 'texture-789',
    label: 'Texture 789',
    urlThumbnail: 'wood.jpeg',
    configs: [
      {
        channel: 'DiffusePBR',
        url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/frame.jpeg',
      },
    ],
  },
];

describe('setAllCustomerTextures', () => {
  const expectedCladdingMaterial = {
    ...mockMaterials[0],
    channels: {
      ...mockMaterials[0].channels,
      AlbedoPBR: {
        ...mockMaterials[0].channels.AlbedoPBR,
        texture: null,
        color: [0.99609375, 0.99609375, 0.99609375],
      },
    },
  };
  const expectedDoorFrameMaterial = {
    ...mockMaterials[1],
    channels: {
      ...mockMaterials[1].channels,
      DiffusePBR: {
        ...mockMaterials[1].channels.DiffusePBR,
        texture: {
          magFilter: 'LINEAR',
          minFilter: 'LINEAR_MIPMAP_LINEAR',
          wrapS: 'REPEAT',
          wrapT: 'REPEAT',
          textureTarget: 'TEXTURE_2D',
          internalFormat: 'RGB',
          texCoordUnit: 0,
          uid: 'mockTextureUid',
        },
      },
    },
  };
  it('should update the customers previous textures', async () => {
    apiRefMock.current.setMaterial.mockClear();
    setAllCustomerTextures(apiRefMock.current, customerModelConfig, textures);
    expect(apiRefMock.current.addTexture).toHaveBeenCalledWith(
      'https://publicsketchfabstaging.blob.core.windows.net/textures/frame.jpeg',
      expect.any(Function),
    );
    expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith(expectedCladdingMaterial);
    await waitFor(() => {
      expect(apiRefMock.current.setMaterial).toHaveBeenCalledWith(expectedDoorFrameMaterial);
    });
  });
});
