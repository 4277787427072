import { getAllColors, getColors } from 'redux/Colors';

import { state } from '__fixtures__/state';

describe('Color selectors', () => {
  describe('getColors', () => {
    it('should return the color state', () => {
      expect(getColors(state)).toEqual(state.colors);
    });
  });
  describe('getAllColors', () => {
    it('Should return all the denormalised colors', () => {
      expect(getAllColors(state)).toEqual([
        {
          id: '123',
          label: 'Nordan',
          value: 'nordan',
          colors: [
            { label: 'White', ralName: 'NCS0500N', hexCode: '#f1efeb' },
            { label: 'Brown', ralName: 'RAL8011', hexCode: '#5a3826' },
          ],
        },
      ]);
    });
  });
});
