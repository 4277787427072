import React from 'react';

import { ErrorText, SubTitle, Title } from 'styling/Typography.style';
import { FormContainer } from 'components/Forms';

interface IProps {
  success?: string;
  error?: string;
}

export const QuoteResponse: React.FC<IProps> = (props) => {
  return (
    <FormContainer>
      <Title>{props.success ? 'Successfully Requested Quote' : 'Error Requesting Quote'}</Title>
      {props.success ? <SubTitle>{props.success}</SubTitle> : <ErrorText>{props.error}</ErrorText>}
    </FormContainer>
  );
};
