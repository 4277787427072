import { getSpacing, colorUsage } from 'stylesheet';
import styled from 'styled-components';

export const ImageContainer = styled.div<{ width: number; lastElement: boolean }>`
  padding: ${getSpacing(1)};
  margin-right: ${(props) => (props.lastElement ? 'auto' : '0')};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const SquarePickerContainer = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

interface IImageProps {
  selected: boolean;
  width: number;
  height: number;
}

export const Image = styled.img<IImageProps>`
  width: ${(props) => getSpacing(props.width)};
  height: ${(props) => getSpacing(props.height)};
  margin-bottom: ${getSpacing(1)};
  border: ${(props) =>
    props.selected ? `3px solid ${colorUsage.selectedBorderColor}` : '3px solid transparent'};
`;

const textPadding = 2; // Make the text container slightly wider than the image, to match original styling

export const TextContainer = styled.div<{ width: number }>`
  min-height: ${getSpacing(5)};
  max-width: ${(props) => getSpacing(props.width + textPadding)};
`;
