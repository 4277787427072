import {
  HeaderContainer,
  HeaderContents,
  HeaderLink,
  InternalHeaderLink,
  LinkContainer,
  Logo,
} from 'components/Header/Header.style';

import { PATHS } from 'routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import brandData from '../../Brand/configs.json';
import { useSelector } from 'react-redux';
import { getCMSFieldById } from 'redux/CMS';
import { useResponsive } from 'pages/Models/useResponsive';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const headerLogo = require('../../' + brandData.Logo.LogoPath);

export const Header: React.FC = () => {
  const { deviceSize } = useResponsive();

  const backToLink = useSelector(getCMSFieldById('BackToLink'));
  const backToText = useSelector(getCMSFieldById('BackToText'));

  return (
    <HeaderContainer>
      <HeaderContents>
        <RouterLink to={PATHS.HOME}>
          <Logo alt={brandData.Logo.alt} src={headerLogo} deviceSize={deviceSize} />
        </RouterLink>
        <LinkContainer>
          <InternalHeaderLink to={PATHS.HOME}>START AGAIN</InternalHeaderLink>
          <HeaderLink href={backToLink} rel="noopener">
            {backToText}
          </HeaderLink>
        </LinkContainer>
      </HeaderContents>
    </HeaderContainer>
  );
};
