/* eslint max-lines: ["error", 130] */
/* form with schemas - consider refactor if added to */
import { CheckboxField } from 'components/Forms';
import { SubTitle, Title } from 'styling/Typography.style';
import { FormContainer, StyledForm, SubmitButton } from 'components/Forms';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { getCustomerModelConfig } from 'redux/CustomerModelConfig';
import { useSelector } from 'redux/useSelector';
import { useSendCustomerQuoteEmail } from 'redux/CustomerModelConfig/hooks';
import ReactGA from 'react-ga';
import { getConstructionOptionTitle } from 'pages/Construction/helpers';
import { getSelectedModel } from 'redux/Models';
import { getCMSFieldById } from 'redux/CMS';
import { ConfigurableRequestAQuoteFormValues, RequestAQuoteFormValues } from 'redux/Models/types';
import { IProps, initialValues, RequestAQuoteSchema, intl } from './RequestAQuoteForm.helper';
import { BasicUserDetailsFields } from './BasicUserDetailsFields';
import { ConfigurableFieldInputs } from './ConfigurableFieldInputs';
import brandData from 'Brand/configs.json';
import { QuoteResponse } from './QuoteResponse';

export enum ConfigurableFieldTypes {
  DROPDOWN = 'dropdown',
  FREE_TEXT = 'freeText',
}

type ConfigurableTextField = {
  value: keyof ConfigurableRequestAQuoteFormValues;
  label: string;
  type: ConfigurableFieldTypes.FREE_TEXT;
};
export type ConfigurableDropdownField = {
  value: keyof ConfigurableRequestAQuoteFormValues;
  label: string;
  type: ConfigurableFieldTypes.DROPDOWN;
  options: string[];
};
export type ConfigurableFieldsType = Array<ConfigurableTextField | ConfigurableDropdownField>;

export const RequestAQuoteForm: React.FC<IProps> = (props) => {
  const [sendCustomerQuoteEmailState, doSendCustomerQuoteEmail] = useSendCustomerQuoteEmail();
  const customerModelConfig = useSelector(getCustomerModelConfig);
  const contactOptInText = useSelector(getCMSFieldById('ContactOptInText'));
  const contactDetailsRequestText = useSelector(getCMSFieldById('ContactDetailsRequestText'));
  const selectedModel = useSelector(getSelectedModel);

  if (sendCustomerQuoteEmailState.value) {
    return (
      <QuoteResponse
        success={sendCustomerQuoteEmailState.value.success}
        error={sendCustomerQuoteEmailState.value.error}
      />
    );
  }

  return (
    <FormContainer>
      <Title>Request a Quote</Title>
      <SubTitle>{contactDetailsRequestText}</SubTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={RequestAQuoteSchema}
        onSubmit={(values) => {
          ReactGA.event({
            category: 'User Action',
            action: 'Requested Quote',
            label: props.modelId,
          });
          const constructionPrice =
            customerModelConfig.construction &&
            selectedModel?.estimatedCost[customerModelConfig.construction];
          doSendCustomerQuoteEmail({
            customerModelConfig,
            email: values.emailAddress,
            name: `${values.firstName} ${values.lastName}`,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.contactNumber,
            modelId: props.modelId,
            constructionLabel: customerModelConfig.construction
              ? getConstructionOptionTitle(customerModelConfig.construction)
              : 'N/A',
            constructionPrice: constructionPrice
              ? intl.formatNumber(constructionPrice, {
                  style: 'currency',
                  currency: 'GBP',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : '',
            modelLink: props.getCustomerLink(),
            location: values.location,
            typeOfEnquiry: values.typeOfEnquiry,
            budgetRange: values.budgetRange,
            plotOwned: values.plotOwned,
            planningStatus: values.planningStatus,
            buildDateStartRange: values.buildDateStartRange,
            additionalInfo: values.additionalInfo,
            yourMessage: values.yourMessage,
            postcode: values.postcode,
            company: values.company,
            emailCheckbox: values.emailCheckbox,
            phoneCheckbox: values.phoneCheckbox,
          });
        }}
      >
        {(props: FormikProps<RequestAQuoteFormValues>) => (
          <StyledForm>
            <BasicUserDetailsFields formikProps={props} />
            <ConfigurableFieldInputs
              formikProps={props}
              configurableFields={brandData.requestAQuoteFields as ConfigurableFieldsType}
            />
            <CheckboxField name="optInContact" message={contactOptInText} />
            <CheckboxField name="emailCheckbox" message="Email" />
            <CheckboxField name="phoneCheckbox" message="Phone" />
            <SubmitButton
              type="submit"
              style={{ marginLeft: 0 }}
              disabled={props.isSubmitting || !props.isValid || !props.dirty}
            >
              Request a Quote
            </SubmitButton>
          </StyledForm>
        )}
      </Formik>
    </FormContainer>
  );
};
