import { ITextureConfig } from './types';
import { RootState } from 'redux/types';
import { TexturesState } from './slice';
import { createSelector } from '@reduxjs/toolkit';
import { denormaliseItems } from 'redux/mappers';

export const getTextures = (store: RootState): TexturesState => store.textures;

export const getAllTextures = createSelector(getTextures, (textures): ITextureConfig[] =>
  denormaliseItems({ byIds: textures.byIds, allIds: textures.allIds }),
);
