import { IModel } from 'redux/Models/types';
import { setCameraLookAt } from 'services/sketchfab/camera';

export const download = (data: string, filename: string): void => {
  const downloadLink = document.createElement('a');
  downloadLink.href = data;
  downloadLink.download = filename;
  downloadLink.click();
};

type onResetCameraClickProps = {
  sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI>;
  selectedModel: IModel | undefined;
};
export const onResetCameraClick = ({
  sketchfabApiRef,
  selectedModel,
}: onResetCameraClickProps): void => {
  if (!selectedModel) return;
  setCameraLookAt(
    sketchfabApiRef,
    selectedModel.hotspots[0].eyePosition,
    selectedModel.hotspots[0].targetPosition,
    3,
  );
};
