import { client } from 'services/networking/client';
import { useDispatch } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import { AsyncFnReturn } from 'react-use/lib/useAsync';
import { IModel } from './types';
import { setModels, setModel } from './slice';

export const useFetchModels = (): AsyncFnReturn<(...args: unknown[]) => Promise<void>> => {
  const dispatch = useDispatch();
  return useTypedAsyncFn<unknown>(async () => {
    const models: IModel[] = await client.get('/api/getmodels');
    dispatch(setModels(models));
  }, []);
};

export const useFetchModel = (id: string): AsyncFnReturn<(...args: unknown[]) => Promise<void>> => {
  const dispatch = useDispatch();
  return useTypedAsyncFn<unknown>(async () => {
    const model: IModel = await client.get(`/api/getmodel/${id}`);
    dispatch(setModel(model));
  }, []);
};
