import React, { ReactNode } from 'react';
import { fireEvent, render, screen } from '@testing-library/react';
import { setTexture } from 'services/sketchfab/texture';

import { ConfiguratorContext } from 'context/ConfiguratorContext';
import { IMaterialConfig, ModelView } from 'redux/Models/types';
import { TextureConfigurator } from 'pages/Models/TextureConfigurator';
import { setSavedCustomerMaterials } from 'redux/CustomerModelConfig';

interface ICommonProps {
  material: IMaterialConfig;
  sketchfabApiRef: { current: null };
}
const commonProps: ICommonProps = {
  material: {
    value: 'Aluminium_Doors',
    label: 'Aluminium Doors',
    availableTextures: [
      {
        textureId: '0bbb74fa-32e2-4aaf-9ef3-dc17386391c3',
        default: true,
      },
      {
        textureId: 'e6292656-7931-4e6f-87ba-3a93e7ae3945',
      },
      {
        textureId: '3c01323f-2e4e-4c77-ac37-80f9868e3a64',
      },
    ],
  },
  sketchfabApiRef: { current: null },
};
jest.mock('services/sketchfab/texture');
jest.mock('redux/CustomerModelConfig');
jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
}));

jest.mock('redux/useSelector', () => ({
  useSelector: () => [
    {
      id: '0bbb74fa-32e2-4aaf-9ef3-dc17386391c3',
      label: 'Oak',
      url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/wood.jpeg',
    },
    {
      id: 'e6292656-7931-4e6f-87ba-3a93e7ae3945',
      label: 'Beech',
      url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/wood2.jpeg',
      canChangeColor: true,
    },
    {
      id: '3c01323f-2e4e-4c77-ac37-80f9868e3a64',
      label: 'Painted',
      canChangeColor: true,
    },
  ],
}));

const MockContextProvider = (props: { children: ReactNode }) => (
  <ConfiguratorContext.Provider
    value={{
      selectedColors: { Aluminium_Doors: { label: '', ralName: '', hexCode: '#ffffff' } },
      setSelectedColors: jest.fn(),
      selectedTextures: {},
      setSelectedTextures: jest.fn(),
      selectedMaterialName: 'Aluminium_Doors',
      setSelectedMaterialName: jest.fn(),
      selectedVariants: [],
      replaceSelectedVariant: jest.fn(),
      selectedFeature: null,
      setSelectedFeature: jest.fn(),
      selectedFeatureName: '',
      expandedScreen: false,
      setExpandedScreen: jest.fn(),
      selectedStep: null,
      setSelectedStep: jest.fn(),
      stepOptions: [],
      setStepOptions: jest.fn(),
      viewType: ModelView.DESIGN,
    }}
  >
    {props.children}
  </ConfiguratorContext.Provider>
);

describe('TextureConfigurator Component Dispatch', () => {
  const beechTexture = {
    id: 'e6292656-7931-4e6f-87ba-3a93e7ae3945',
    label: 'Beech',
    url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/wood2.jpeg',
    canChangeColor: true,
  };
  it('should call the sketchfab api to change texture when set', () => {
    render(<TextureConfigurator {...commonProps} />);
    fireEvent.click(screen.getByAltText('Beech'));
    expect(setTexture).toHaveBeenCalledWith(
      { current: null },
      'Aluminium_Doors',
      beechTexture,
      null,
    );
  });

  it('should dispatch the setSelectedCustomerMaterials with the correct values', () => {
    render(<TextureConfigurator {...commonProps} />);
    fireEvent.click(screen.getByAltText('Beech'));
    expect(setSavedCustomerMaterials).toHaveBeenCalledWith({
      value: 'Aluminium_Doors',
      texture: { textureId: 'e6292656-7931-4e6f-87ba-3a93e7ae3945' },
    });
  });

  it('should call setTexture with a color if specified', () => {
    render(
      <MockContextProvider>
        <TextureConfigurator {...commonProps} />
      </MockContextProvider>,
    );
    fireEvent.click(screen.getByAltText('Beech'));
    expect(setTexture).toHaveBeenCalledWith({ current: null }, 'Aluminium_Doors', beechTexture, {
      label: '',
      ralName: '',
      hexCode: '#ffffff',
    });
  });
});
