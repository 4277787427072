/* eslint max-lines: ["error", 178] */
import {
  showVariant,
  setAllDefaultVariants,
  setAllCustomerVariants,
} from 'services/sketchfab/variant';
import { apiRefMock } from '__mocks__/sketchfabApi';
import { IModel } from 'redux/Models/types';
import { ICustomerModelConfig } from 'redux/CustomerModelConfig/types';

const model: IModel = {
  id: '123',
  sketchfabModelId: 'abc',
  backgroundImageId: 'mockBackgroundImageId',
  name: 'House 1',
  description: 'House',
  imgUrl: 'house.jpeg',
  hotspots: [],
  materials: [],
  features: [
    {
      label: 'Roof',
      value: 'Roof',
      variants: [
        {
          value: 'roof1',
          label: 'Roof 1',
          urlThumbnail: 'roof1.jpeg',
          variantNodes: [{ value: 'Roof1' }],
        },
        {
          value: 'roof2',
          label: 'Roof 2',
          urlThumbnail: 'roof2.jpeg',
          variantNodes: [{ value: 'Roof2' }, { value: 'Roof2Beams' }],
        },
      ],
    },
    {
      label: 'Cladding',
      value: 'Cladding',
      excludedNodes: ['TestExclNode'],
      variants: [
        {
          value: 'cladding1',
          label: 'Cladding 1',
          urlThumbnail: 'cladding1.jpeg',
          variantNodes: [{ value: 'Cladding1' }],
        },
        {
          value: 'cladding2',
          label: 'Cladding 2',
          urlThumbnail: 'cladding2.jpeg',
          variantNodes: [{ value: 'Cladding2' }, { value: 'Cladding2Beams' }],
        },
      ],
    },
    {
      label: 'Window',
      value: 'window',
      variants: [
        {
          label: 'Window 1',
          value: 'window1',
          urlThumbnail: 'window1.jpeg',
          variantNodes: [{ value: 'Window1Frame' }],
        },
        {
          label: 'Window 2',
          value: 'window2',
          default: true,
          urlThumbnail: 'window2.jpeg',
          variantNodes: [{ value: 'Window2Frame' }],
        },
        {
          label: 'Window 3',
          value: 'window3',
          default: true,
          urlThumbnail: 'window2.jpeg',
          variantNodes: [{ value: 'Window3Frame' }],
        },
      ],
    },
  ],
  estimatedCost: {
    oneStarOpen: 60000,
    twoStarOpen: 60000,
    twoStarClosed: 60000,
    threeStarOpen: 60000,
    threeStarClosed: 60000,
    fourStarClosed: 60000,
    fiveStarClosed: 60000,
  },
};

const customerModelConfig: ICustomerModelConfig = {
  modelId: '123',
  features: {
    window: {
      value: 'window',
      variantValue: 'window3',
    },
    Roof: {
      value: 'Roof',
      variantValue: 'roof2',
    },
  },
  materials: {},
  construction: 'threeStarClosed',
};

beforeEach(() => {
  apiRefMock.current.show.mockClear();
  apiRefMock.current.hide.mockClear();
  apiRefMock.current.setMaterial.mockClear();
});

describe('showVariant', () => {
  const variants = model.features?.find((feature) => feature.value === 'Roof')?.variants;
  const claddingVariants = model.features?.find((feature) => feature.value === 'Cladding')
    ?.variants;
  it('should show Roof 2 and hide Roof 1', () => {
    showVariant(apiRefMock, 'roof2', variants);
    expect(apiRefMock.current.show).toHaveBeenCalledWith(22);
    expect(apiRefMock.current.show).toHaveBeenCalledWith(23);
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(20);
  });
  it('should show Roof 1 and hide Roof 2', () => {
    showVariant(apiRefMock, 'roof1', variants);
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(22);
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(23);
    expect(apiRefMock.current.show).toHaveBeenCalledWith(20);
  });
  it('should do nothing if a bad variant name is passed in ', () => {
    showVariant(apiRefMock, 'Made up', variants);
    expect(apiRefMock.current.show).not.toHaveBeenCalled();
    expect(apiRefMock.current.hide).not.toHaveBeenCalled();
  });
  it('should show Cladding 1 and hide excluded nodes', () => {
    showVariant(apiRefMock, 'cladding1', claddingVariants, ['TestExclNode']);
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(82);
    expect(apiRefMock.current.show).toHaveBeenCalledWith(95);
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(96);
  });
});

describe('setAllDefaultVariants', () => {
  it('should update all the default variants', () => {
    setAllDefaultVariants(model, apiRefMock.current);

    // Roof1
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(22);
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(23);
    expect(apiRefMock.current.show).toHaveBeenCalledWith(20);

    // Window2
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(78);
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(80);
    expect(apiRefMock.current.show).toHaveBeenCalledWith(79);
  });
});

describe('setAllCustomerVariants', () => {
  it('should apply all the customer variants', () => {
    setAllCustomerVariants(model, customerModelConfig, apiRefMock.current);

    // Roof2
    expect(apiRefMock.current.show).toHaveBeenCalledWith(22);
    expect(apiRefMock.current.show).toHaveBeenCalledWith(23);
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(20);

    // Window3
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(78);
    expect(apiRefMock.current.hide).toHaveBeenCalledWith(79);
    expect(apiRefMock.current.show).toHaveBeenCalledWith(80);
  });
});
