import styled from 'styled-components';
import { colorUsage, fontWeight, getSpacing } from 'stylesheet';
import { SubTitle, Title } from 'styling/Typography.style';

export const InfoContainer = styled.div`
  width: ${getSpacing(40)};
  cursor: pointer;
  margin: ${getSpacing(2)} auto;
  padding: ${getSpacing(2)};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const HouseImage = styled.img`
  width: ${getSpacing(40)};
  height: ${getSpacing(26)};
`;

export const HouseName = styled(Title)`
  color: ${colorUsage.primaryDark};
  margin-bottom: ${getSpacing(1)};
`;

export const Description = styled(SubTitle)`
  margin-bottom: ${getSpacing(0)};
  font-weight: ${fontWeight.normal};
`;
