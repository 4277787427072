/* eslint max-lines: ["error", 129] */
/* File on the limit but covers one flow - consider refactoring if increased */
import { CirclePicker, ColorResult } from 'react-color';
import {
  ColorConfiguratorContainer,
  ColorPickerContainer,
  ColorPickerHeadingContainer,
  TypographyContainer,
} from './ColorConfigurator.style';
import React, { useState } from 'react';
import { ITextureConfig } from 'redux/Textures/types';
import { SubTitle } from 'styling/Typography.style';
import { changeMaterialColor, hexToRgb, rgbToHex } from 'services/sketchfab/color';
import { getAllColors } from 'redux/Colors';
import { getAllTextures } from 'redux/Textures';
import { setSavedCustomerMaterials } from 'redux/CustomerModelConfig';
import { useConfiguratorContext } from 'context/ConfiguratorContext';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/useSelector';
import { Color } from 'redux/Colors/types';
import { Typography } from '@material-ui/core';

interface IProps {
  sketchfabApiRef: React.MutableRefObject<null | ISketchfabAPI>;
}

export const ColorConfigurator: React.FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch();
  const {
    selectedMaterialName,
    selectedTextures,
    selectedColors,
    setSelectedColors,
  } = useConfiguratorContext();
  const textures = useSelector(getAllTextures);
  const colors = useSelector(getAllColors);
  const [selectedColour, setSelectedColour] = useState<string | undefined>();

  const selectedTextureId: string | null =
    selectedMaterialName && selectedTextures[selectedMaterialName]?.textureId;
  const selectedTexture: ITextureConfig | undefined = textures.find(
    (texture) => texture.id === selectedTextureId,
  );

  const colorPaletteName = selectedTexture?.colorPaletteName || 'nordan';
  const palette = colors.find((color) => color.value === colorPaletteName)?.colors;
  const mappedColors = palette?.map((color) => color.hexCode);

  const setColor = (color: Color) => {
    if (selectedMaterialName) {
      setSelectedColors({ ...selectedColors, [selectedMaterialName]: color });
    }
  };

  const onColorChange = (newColor: Partial<ColorResult>) => {
    if (newColor.rgb) {
      const color = palette?.find((color) => color.hexCode === newColor.hex);
      if (!color) {
        return;
      }
      setColor(color);
      if (selectedMaterialName) {
        changeMaterialColor(
          props.sketchfabApiRef,
          selectedMaterialName,
          newColor.rgb,
          selectedTexture?.paletteChannel,
        );
        if (selectedTextures[selectedMaterialName]) {
          dispatch(
            setSavedCustomerMaterials({
              value: selectedMaterialName,
              texture: { ...selectedTextures[selectedMaterialName], color },
            }),
          );
        }
      }
    }
  };

  const shouldRenderColorPicker = (): boolean => {
    return !!(selectedTexture && selectedTexture.canChangeColor && mappedColors);
  };

  React.useEffect(() => {
    if (
      selectedMaterialName &&
      !selectedColors[selectedMaterialName] &&
      selectedTexture?.defaultColor?.r
    ) {
      onColorChange({
        rgb: selectedTexture.defaultColor,
        hex: rgbToHex(selectedTexture.defaultColor),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMaterialName, selectedTextureId]);

  const color = (selectedMaterialName &&
    selectedColors[selectedMaterialName] &&
    hexToRgb(selectedColors[selectedMaterialName].hexCode)) || {
    r: 255,
    g: 255,
    b: 255,
  };

  return selectedMaterialName && shouldRenderColorPicker() ? (
    <ColorConfiguratorContainer>
      <ColorPickerHeadingContainer>
        <SubTitle>Select Colour</SubTitle>
        <TypographyContainer>
          <Typography noWrap={true}>{selectedColour}</Typography>
        </TypographyContainer>
      </ColorPickerHeadingContainer>
      <ColorPickerContainer>
        <CirclePicker
          color={color}
          colors={mappedColors}
          onChangeComplete={onColorChange}
          data-testid="color-picker"
          onSwatchHover={(colorArg) => {
            setSelectedColour(palette?.find((colour) => colour.hexCode === colorArg.hex)?.label);
          }}
        />
      </ColorPickerContainer>
    </ColorConfiguratorContainer>
  ) : null;
};
