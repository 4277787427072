import { InputContainer, TextFields } from 'components/Forms';
import { Selector } from 'components/Selector';
import { Spacer } from 'components/Spacer';
import { FormikProps } from 'formik';
import React from 'react';
import { RequestAQuoteFormValues } from 'redux/Models/types';
import { InputSubTitle } from 'styling/Typography.style';
import { COUNTIES } from './CountiesList';
import { requiredFreeTextFields } from './RequestAQuoteForm.helper';

type BasicUserDetailsFieldsProps = {
  formikProps: FormikProps<RequestAQuoteFormValues>;
};

export const BasicUserDetailsFields: React.FC<BasicUserDetailsFieldsProps> = ({ formikProps }) => {
  return (
    <>
      <TextFields
        fields={requiredFreeTextFields}
        touched={formikProps.touched}
        errors={formikProps.errors}
      />
      <InputContainer>
        <InputSubTitle>Project Location*</InputSubTitle>

        <Selector<{ value: string; label: string }>
          setSelectedOption={(value) => formikProps.setFieldValue('location', value?.value)}
          options={COUNTIES.map((county) => ({ value: county, label: county }))}
          selectedOption={{
            value: formikProps.values.location,
            label: formikProps.values.location,
          }}
        />
      </InputContainer>
      <Spacer.Column numberOfSpaces={4} />
    </>
  );
};
