/* eslint max-lines: ["error", 154] */
import { Prompt } from 'react-router-dom';
import { render } from '@testing-library/react';
import { ModelViewer } from 'components/ModelViewer';
import Models from 'pages/Models';
import React from 'react';
import { setDefaultCustomerModelConfig } from 'redux/CustomerModelConfig';
import { setSelectedModelId } from 'redux/Models';
import { mockCMSContent } from '__fixtures__/mockCMSFields';

const mockPush = jest.fn();

jest.mock('react-router-dom', () => ({
  useHistory: () => ({
    push: mockPush,
  }),
  useParams: jest.fn(() => ({ modelId: '123', customerModelConfigId: '456' })),
  Prompt: jest.fn(() => <div />),
}));

jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
  useSelector: jest.fn((fn) => fn()),
}));

jest.mock('components/ModelViewer', () => ({ ModelViewer: jest.fn(() => null) }));

const mockFetchModel = jest.fn();
jest.mock('redux/Models/hooks', () => ({
  useFetchModel: () => [false, mockFetchModel],
}));

const mockFetchColors = jest.fn();
jest.mock('redux/Colors/hooks', () => ({
  useFetchColors: () => [false, mockFetchColors],
}));

const mockFetchTextures = jest.fn();
jest.mock('redux/Textures/hooks', () => ({
  useFetchTextures: () => [false, mockFetchTextures],
}));

const mockFetchCMSFields = jest.fn();
jest.mock('redux/CMS/hooks', () => ({
  useFetchCMSFields: () => [false, mockFetchCMSFields],
}));

jest.mock('redux/CMS', () => ({
  getCMSFieldById: (id: string) => () => mockCMSContent[id],
}));

const mockFetchCustomerModelConfig = jest.fn();
jest.mock('redux/CustomerModelConfig/hooks', () => ({
  useFetchCustomerModelConfig: () => [false, mockFetchCustomerModelConfig],
  useCreateCustomerModelConfig: () => [false, {}],
  useSendCustomerModelEmail: () => [false, {}],
  useSendCustomerQuoteEmail: () => [false, {}],
}));

jest.mock('redux/Models', () => ({
  getSelectedModel: () => ({
    id: '123',
    sketchfabModelId: 'abc',
    backgroundImageId: 'mockBackgroundImageId',
    name: 'House 1',
    materials: [
      {
        value: 'Alu ',
        label: 'Alu',
      },
      {
        value: 'Aluminium_Doors',
        label: 'Aluminium Doors',
      },
    ],
    hotspots: [],
  }),
  setSelectedModelId: jest.fn(),
}));
jest.mock('redux/Textures', () => ({
  getAllTextures: () => [],
}));
jest.mock('redux/CustomerModelConfig', () => ({
  getCustomerModelConfig: () => ({
    modelId: '1ac',
    features: {},
    materials: {},
    construction: 'threeStarClosed',
  }),
  getSelectedConstruction: () => 'threeStarClosed',
  setDefaultCustomerModelConfig: jest.fn(),
  setCustomerModelConfig: jest.fn(),
}));

beforeEach(() => {
  mockFetchCustomerModelConfig.mockClear();
  // @ts-expect-error: mockClear
  setDefaultCustomerModelConfig.mockClear();
  // @ts-expect-error: mockClear
  setSelectedModelId.mockClear();
});

describe('Models Page', () => {
  it('should call the fetch calls on load and not reset the config or set id in this component', () => {
    render(<Models />);
    expect(mockFetchTextures).toHaveBeenCalledWith();
    expect(mockFetchModel).toHaveBeenCalledWith();
    expect(mockFetchColors).toHaveBeenCalledWith();
    expect(mockFetchCustomerModelConfig).toHaveBeenCalledWith({
      modelId: '123',
      customerModelConfigId: '456',
    });
    expect(setDefaultCustomerModelConfig).not.toHaveBeenCalled();
    expect(setSelectedModelId).not.toHaveBeenCalled();
  });
  it('should have unbeforeunload and prompt on load', () => {
    render(<Models />);
    // @ts-expect-error: onbeforeunload
    expect(window.onbeforeunload()).toBe(true);
    expect(Prompt).toHaveBeenCalledWith(
      { message: 'You may have unsaved changes. Are you sure you want to leave?' },
      {},
    );
  });

  it('should have ModelViewer with correct id', () => {
    render(<Models />);
    expect(ModelViewer).toHaveBeenCalledWith(
      {
        apiRef: { current: null },
        sketchfabModelId: 'abc',
        setModelLoaded: expect.any(Function),
        cameraConstraints: undefined,
        initialHotspot: undefined,
        setCameraPosition: expect.any(Function),
        backgroundImageId: 'mockBackgroundImageId',
      },
      {},
    );
  });
});
