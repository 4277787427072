import { useEffect } from 'react';
import { useState } from 'react';

const getWidth = () =>
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export enum DeviceSize {
  Phone = 600,
  Tablet = 900,
  Desktop = 15000,
}

type UseResponsiveReturn = {
  deviceSize: DeviceSize;
  setDeviceSize: React.Dispatch<React.SetStateAction<DeviceSize>>;
};

export const useResponsive = (): UseResponsiveReturn => {
  const [deviceSize, setDeviceSize] = useState(DeviceSize.Desktop);
  const [width, setWidth] = useState(getWidth());

  // Debounce from https://dev.to/vitaliemaldur/resize-event-listener-using-react-hooks-1k0c
  useEffect(() => {
    let timeoutId: number | null = null;
    const resizeListener = () => {
      timeoutId && clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth()), 100);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    if (width < DeviceSize.Phone) setDeviceSize(DeviceSize.Phone);
    else if (width < DeviceSize.Tablet) setDeviceSize(DeviceSize.Tablet);
    else setDeviceSize(DeviceSize.Desktop);
  }, [width]);

  return { deviceSize, setDeviceSize };
};
