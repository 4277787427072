import { initialState, reducer, setColors } from 'redux/Colors';

describe('Colors, initialState reducer', () => {
  describe('setColors', () => {
    it('Should set colors in a normalised manner', () => {
      const mockDatabaseReturn = [
        {
          id: '123',
          label: 'Nordan',
          value: 'nordan',
          colors: [
            { label: 'White', ralName: 'NCS0500N', hexCode: '#f1efeb' },
            { label: 'Brown', ralName: 'RAL8011', hexCode: '#5a3826' },
          ],
        },
      ];
      const action = setColors(mockDatabaseReturn);
      const expectedState = {
        ...initialState,
        byIds: {
          '123': {
            id: '123',
            label: 'Nordan',
            value: 'nordan',
            colors: [
              { label: 'White', ralName: 'NCS0500N', hexCode: '#f1efeb' },
              { label: 'Brown', ralName: 'RAL8011', hexCode: '#5a3826' },
            ],
          },
        },
        allIds: ['123'],
      };

      expect(reducer(initialState, action)).toEqual(expectedState);
    });
  });
});
