import { AppCrashFallback } from 'components/AppCrashFallback';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { PersistGate } from 'redux-persist/integration/react';
import { Persistor } from 'redux-persist';
import { Provider } from 'react-redux';
import React from 'react';
import { Root } from 'components/Root';
import { Routes } from 'routes';
import { Store } from 'redux';

interface Props {
  persistor: Persistor;
  store: Store;
}

const App: React.FunctionComponent<Props> = ({ persistor, store }) => (
  <ErrorBoundary FallbackComponent={AppCrashFallback}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Root>
            <Routes />
          </Root>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

export default App;
