import { PATHS } from 'routes';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IModel } from 'redux/Models/types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import ReactGA from 'react-ga';
import {
  setCustomerConstructionConfig,
  setDefaultCustomerModelConfig,
} from 'redux/CustomerModelConfig';
import { getDefaultVariants } from 'services/GetDefaultVariants/getDefaultVariants';
import brandData from 'Brand/configs.json';
import { Description, HouseImage, HouseName, InfoContainer } from './ModelInfo.style';
import { getCMSFieldById } from 'redux/CMS';
interface IProps {
  model: IModel;
}

export const ModelInfo: React.FunctionComponent<IProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultVariants = props.model && getDefaultVariants(props.model);
  const pricesFromText = useSelector(getCMSFieldById('pricesFromText'));

  return (
    <InfoContainer
      onClick={() => {
        ReactGA.event({
          category: 'User Action',
          action: 'Selected Model',
          label: props.model.id,
        });

        dispatch(
          setDefaultCustomerModelConfig({
            modelId: props.model.id,
            features: defaultVariants ? defaultVariants : {},
            materials: {},
          }),
        );

        if (brandData.skipConstruction) {
          dispatch(
            setCustomerConstructionConfig({
              construction: null,
            }),
          );

          history.push(PATHS.MODELS(props.model.id));
        } else {
          history.push(PATHS.CONSTRUCTION(props.model.id));
        }
      }}
    >
      <HouseImage src={props.model.imgUrl} />
      <HouseName>{props.model.name}</HouseName>
      <Description>{props.model.description}</Description>
      <Description>{props.model?.description2}</Description>
      {props.model?.estimatedCost && (
        <>
          {props.model.estimatedCost.threeStarClosed && (
            <Description>
              {pricesFromText}{' '}
              <FormattedNumber
                value={props.model.estimatedCost.threeStarClosed}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency={'GBP'}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </Description>
          )}
        </>
      )}
    </InfoContainer>
  );
};
