import { fireEvent, render, screen } from '@testing-library/react';

import { ColorConfigurator } from 'pages/Models/ColorConfigurator';
import React from 'react';
import { setSavedCustomerMaterials } from 'redux/CustomerModelConfig';
import { useConfiguratorContext } from 'context/ConfiguratorContext';

jest.mock('react-color', () => ({
  CirclePicker: jest.fn((props) => (
    <div
      data-testid={props['data-testid']}
      onClick={() => props.onChangeComplete({ rgb: { r: 10, g: 100, b: 222 }, hex: '#5a3826' })}
    />
  )),
}));
jest.mock('services/sketchfab/color');
jest.mock('context/ConfiguratorContext');
jest.mock('redux/useSelector', () => ({
  useSelector: jest.fn((fn) => fn()),
}));
jest.mock('redux/Textures', () => ({
  getAllTextures: () => [
    {
      id: '0bbb74fa-32e2-4aaf-9ef3-dc17386391c3',
      label: 'Oak',
      url: 'https://publicsketchfabstaging.blob.core.windows.net/textures/wood.jpeg',
    },
    {
      id: '3c01323f-2e4e-4c77-ac37-80f9868e3a64',
      label: 'Painted',
      canChangeColor: true,
    },
  ],
}));
jest.mock('redux/Colors', () => ({
  getAllColors: () => [
    {
      id: '123',
      label: 'Nordan',
      value: 'nordan',
      colors: [
        { label: 'White', ralName: 'NCS0500N', hexCode: '#f1efeb' },
        { label: 'Brown', ralName: 'RAL8011', hexCode: '#5a3826' },
      ],
    },
  ],
}));
jest.mock('react-redux', () => ({
  useDispatch: () => jest.fn(),
}));
jest.mock('redux/CustomerModelConfig');

beforeEach(() => {
  // @ts-expect-error: mockImplementation
  useConfiguratorContext.mockImplementation(() => ({
    selectedMaterialName: 'Aluminium_Roof',
    selectedTextures: {
      Aluminium_Roof: { textureId: '3c01323f-2e4e-4c77-ac37-80f9868e3a64', channel: 'AlbedoPBR' },
    },
    selectedColors: {},
    setSelectedColors: jest.fn(),
  }));
});

const commonProps = {
  material: {
    value: 'Oak',
    label: 'Oak',
    canChangeColor: true,
  },
  sketchfabApiRef: { current: null },
};

describe('ColorConfigurator Component', () => {
  it('should have a title', () => {
    render(<ColorConfigurator {...commonProps} />);
    expect(screen.queryByText('Select Colour')).toBeTruthy();
  });
  it('should not have a title if the material/texture does not allow it', () => {
    // @ts-expect-error: mockImplementation
    useConfiguratorContext.mockImplementation(() => ({
      selectedMaterialName: 'Aluminium_Roof',
      selectedTextures: { Aluminium_Roof: '0bbb74fa-32e2-4aaf-9ef3-dc17386391c3' },
      selectedColors: {},
      setSelectedColors: jest.fn(),
    }));
    render(<ColorConfigurator {...commonProps} />);
    expect(screen.queryByText('Select Colour')).toBeFalsy();
  });
  it("should save the customer's choice when set", () => {
    // @ts-expect-error: mockClear
    setSavedCustomerMaterials.mockClear();
    render(<ColorConfigurator {...commonProps} />);
    fireEvent.click(screen.getByTestId('color-picker'));
    expect(setSavedCustomerMaterials).toHaveBeenCalledWith({
      texture: {
        channel: 'AlbedoPBR',
        color: { label: 'Brown', ralName: 'RAL8011', hexCode: '#5a3826' },
        textureId: '3c01323f-2e4e-4c77-ac37-80f9868e3a64',
      },
      value: 'Aluminium_Roof',
    });
  });
});
