import { PageHeaderText, PageSubHeaderText } from 'styling/Typography.style';
import React, { useState } from 'react';
import { getSpacing } from 'stylesheet';
import { GenericModal } from 'components/GenericModal';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { getCMSFieldById } from 'redux/CMS';
import {
  PageHeaderContainer,
  HelpContainer,
  HelpText,
  SubHeaderTextContainer,
} from 'components/PageHeader/PageHeader.style';
import { HTMLText } from 'components/HTMLText';
import { useResponsive } from 'pages/Models/useResponsive';

interface IProps {
  headerText?: string;
  subHeaderText?: string;
  helpText: string;
  showWhatIsIncluded?: boolean;
}

export const PageHeader: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showIncludeModal, setShowIncludeModal] = useState<boolean>(false);
  const whatIsIncludedText = useSelector(getCMSFieldById('WhatIsIncludedText'));
  const whatIsIncludedLabel = useSelector(getCMSFieldById('WhatIsIncludedLabel'));
  const getShowWhatIsIncluded = () =>
    props.showWhatIsIncluded && !!whatIsIncludedLabel && !!whatIsIncludedText;

  const { deviceSize } = useResponsive();

  return (
    <PageHeaderContainer deviceSize={deviceSize}>
      {props.headerText && (
        <PageHeaderText style={{ marginBottom: props.subHeaderText ? getSpacing(4) : 0 }}>
          {props.headerText}
        </PageHeaderText>
      )}
      {props.subHeaderText && (
        <SubHeaderTextContainer>
          <PageSubHeaderText>{props.subHeaderText}</PageSubHeaderText>
        </SubHeaderTextContainer>
      )}
      <HelpContainer>
        {getShowWhatIsIncluded() && (
          <HelpText
            style={{ marginRight: getSpacing(2) }}
            onClick={() => {
              ReactGA.event({
                category: 'User Action',
                action: `${whatIsIncludedLabel} Pressed`,
              });
              setShowIncludeModal(!showIncludeModal);
            }}
          >
            {whatIsIncludedLabel}
          </HelpText>
        )}
        <HelpText
          onClick={() => {
            ReactGA.event({
              category: 'User Action',
              action: 'Pressed Help Button',
            });
            setShowModal(true);
          }}
        >
          Help
        </HelpText>
      </HelpContainer>
      <GenericModal showModal={showModal} setShowModal={setShowModal}>
        <HTMLText textToSet={props.helpText} heightUnset={true} />
      </GenericModal>
      {showIncludeModal && (
        <GenericModal showModal={showIncludeModal} setShowModal={setShowIncludeModal}>
          <HTMLText textToSet={whatIsIncludedText} heightUnset={true} />
        </GenericModal>
      )}
    </PageHeaderContainer>
  );
};
